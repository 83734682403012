import React from 'react';

import { components as coreComponents } from '@au/core';

import styles from '../css/components/filedrop.module.scss';

const { elements: { AutoIntl } } = coreComponents;

const FileDrop = () => (
  <div className={styles.container}>
    <AutoIntl
      className={styles.msg}
      displayId="au.filedrop.msg"
      tag="div"
    />
    <AutoIntl
      className={styles.caveat}
      displayId="au.filedrop.caveat"
      tag="div"
    />
  </div>
);

export default FileDrop;
