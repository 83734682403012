import React from "react";
import * as T from 'prop-types';

import FeedbackButton from './StickyFeedbackButton';
import styles from "../css/components/lookup_page.module.scss";

export default class LookupPageLayout extends React.Component {
  static propTypes = {
    bannerSrc: T.string.isRequired,
    bannerAlt: T.string.isRequired,
    blurbChild: T.object,
    feedbackLink: T.string.isRequired,
  }

  render() {
    const { bannerSrc, bannerAlt, blurbChild, feedbackLink } = this.props;
    return (
      <React.Fragment>
        <img className={styles.banner} src={bannerSrc} alt={bannerAlt}/>
        {blurbChild}
        <div className={styles.container}>
          {Boolean(feedbackLink) && <FeedbackButton link={feedbackLink} />}
          {this.props.children}
        </div>
      </React.Fragment>
    );
  }
}