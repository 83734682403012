import React from 'react';
import { connect } from 'react-redux';
import { Map as imMap } from 'immutable';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import debounce from 'lodash/debounce';
import clamp from 'lodash/clamp';

import cloudSearch from '../network/cloudSearch';
import { tocToAppPath } from '../utils/toc';
import { getIn } from '@au/dev-docs/toc/common';
import logger from '../utils/logger';
import { HASH_DOC_KEY } from '../constants';
import browserHistory from '../history';

import AuAnalytics from '@au/core/lib/utils/AuAnalytics';
import AuComponent from '@au/core/lib/components/elements/AuComponent';
import SearchBox from '@au/core/lib/components/elements/SearchBox';
import AutoIntl from "@au/core/lib/components/elements/AutoIntl";
import { DOWN_KEY, UP_KEY, FORWARD_SLASH_KEY, ENTER_KEY  } from '@au/core/lib/constants';

import styles from '../css/components/search.module.scss';


function warn(description) {
  logger.warn(description);
  AuAnalytics.trackException(description);
}

class Search extends AuComponent {
  state = { hits: [], expanded: false, selectedIndex: null };
  running = false;
  toSearch = null;
  lastSearched = null;
  isHoveringOver = false;

  componentDidMount() {
    window.addEventListener('keydown', this.onKeyDown, true);
    window.addEventListener('resize', this.onResize, false);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyDown, true);
    window.removeEventListener('resize', this.onResize, false);
  }

  get inputEl() {
    try {
      return this.containerRef.getElementsByTagName('input').item(0);
    } catch(e) {
      // best effort
      console.error('unable to find search bar input!'); // eslint-disable-line no-console
      console.error(e); // eslint-disable-line no-console
      return null;
    }
  }

  onResize = debounce(this.onResize, 200).bind(this);
  onResize() {
    if (this.containerRef) {
      this.containerRect = this.containerRef.getBoundingClientRect();
    }
  }

  onKeyDown = this.onKeyDown.bind(this)
  onKeyDown(e) {
    const { selectedIndex, hits } = this.state;
    const { activeElement } = window.document;
    if (e.key === FORWARD_SLASH_KEY && !['INPUT', 'TEXTAREA'].includes((activeElement || {}).nodeName)) { // "/"
      this.focusInput();
    }
    if (this.state.expanded && hits.length) {
      if (e.key === DOWN_KEY) {
        e.preventDefault();
        if (selectedIndex === null) {
          this.selectHit(0);
        }
        else {
          this.selectHit(selectedIndex + 1);
        }
      }
      if (e.key === UP_KEY) {
        e.preventDefault();
        this.selectHit(selectedIndex - 1);
      }

      if (e.key === ENTER_KEY && selectedIndex !== null) {
        this.setState({ expanded: false });
        browserHistory.push(hits[selectedIndex].appPath);
      }
    }
  }

  selectHit(index) {
    const { hits } = this.state;
    this.setState({ selectedIndex: clamp(index, 0, hits.length - 1) });
    try {
      this.hitsContainerRef.querySelectorAll('a')[index].scrollIntoView();
    } catch(e) { /*best effort */ }
  }

  focusInput = this.focusInput.bind(this);
  focusInput() {
    const { inputEl } = this;
    if (inputEl && document.activeElement !== inputEl) {
      this.setState({ expanded: true});
      // setTimeout to keep from sending "/" to input
      setTimeout(() => inputEl.focus(), 0);
    }
  }

  onFocus = this.onFocus.bind(this);
  onFocus() {
    this.setState({ expanded: true });
  }

  onBlur = this.onBlur.bind(this);
  onBlur() {
    if (!this.isHoveringOver) {
      this.setState({ expanded: false });
    }
  }

  onChange = this.onChange.bind(this);
  onChange(query) {
    // the last query is what is searched after current search is complete
    this.toSearch = query.trim();
    this.search();
  }

  search = this.search.bind(this);
  search() {
    const { filePathMap, toc } = this.props;

    if (this.lastSearched === this.toSearch) return;
    if (this.running || this.toSearch === null) return;
    const toSearch = this.toSearch;
    this.toSearch = null;
    if (toSearch === '') {
      this.setState({ hits: [], selectedIndex: null });
      this.lastSearched = null;
    }
    else {
      cloudSearch.search(toSearch)
        .then(resp => {
          AuAnalytics.trackEvent({
            category: 'Nav',
            action: 'Search',
            label: toSearch,
            value: resp.data.hits.found,
            nonInteraction: false,
            transport: 'xhr'
          });
          return resp;
        })
        .then(resp => resp.data.hits.hit
          .filter(hit => {
            const hasDoc = filePathMap.has(hit.fields.path);
            if (!hasDoc) {
              warn(`missing ${hit.fields.path} search result in toc`);
            }
            return hasDoc;
          })
          .map(hit => {
            const tocPath = filePathMap.get(hit.fields.path);
            return {
              hit,
              doc: getIn(toc, tocPath),
              tocPath
            };
          })
          .filter(({ hit, doc }) => {
            const sameHash = hit.fields.hash === doc.get(HASH_DOC_KEY);
            if (!sameHash) {
              warn(`${hit.fields.path} hash does not match toc`);
            }
            return sameHash;
          })
          .map(({ hit, tocPath }) => ({
            title: hit.highlights.title,
            highlights: hit.highlights.content,
            tocPath,
            appPath: tocToAppPath(tocPath)
          })))
        .then(hits => this.setState({ hits, selectedIndex: null }))
        .then(() => {
          this.running = false;
          this.lastSearched = toSearch;
          this.search();
        });
    }
  }

  renderHit({ title, highlights, tocPath }) {
    // convert first tocPath zone to locale
    const path = tocPath
      .slice(0, -1) // skip last element since it is the title
      .map((p,i) => <span key={i}>{p}</span>);

    return (
      <React.Fragment>
        <div className={styles.header}>
          <span className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />
          <div className={styles.path}>
            {path}
          </div>
        </div>
        <div className={styles.highlights} dangerouslySetInnerHTML={{ __html: highlights }} />
      </React.Fragment>
    );
  }

  renderHits() {
    const { hits, selectedIndex } = this.state;

    return (
      <div
        className={styles.hit_container}
        ref={ref => this.hitsContainerRef = ref}
      >
        {hits.map((hit, index) =>
          <Link
            key={hit.appPath}
            className={cn(styles.hit, {
              [styles.selected]: index === selectedIndex,
              [styles.viewing]: hit.appPath === window.location.pathname
            })}
            to={hit.appPath}
            onClick={() => this.setState({ expanded: false, selectedIndex: null })}
          >
            {this.renderHit(hit)}
          </Link>
        )}
      </div>
    );
  }

  render() {
    const { expanded, hits } = this.state;

    if (!this.containerRect && this.containerRef) {
      this.containerRect = this.containerRef.getBoundingClientRect();
    }
    const searchWidth = ((this.containerRect && this.containerRect.x) || 300) - 44; // 44 pixes for "TMC" logo

    const expandedStyle = expanded && styles.expanded;
    return (
      <div className={cn(styles.container, expandedStyle, {[styles.has_hits]: hits.length})} ref={ref => this.containerRef = ref}>
        <div className={styles.search_title} onClick={this.focusInput}>
          <div className={styles.icon}/>
          <AutoIntl displayId={'au.search'} className={expanded ? styles.hidden : styles.search_text}/>
        </div>
        <div
          onMouseEnter={() => this.isHoveringOver = true}
          onMouseLeave={() => this.isHoveringOver = false}
          style={{ width: !expanded && !hits.length ? 0 : searchWidth }}
          className={styles.search_container}
        >
          <SearchBox
            onChange={this.onChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            debounce={300}
            className={styles.search_box}
            placeholderId="au.search"
          />
          {this.renderHits()}
        </div>
      </div>
    );
  }
}

export const navlink = styles.nav_link;


export default connect(
  ({ app: state }) => {
    const toc = state.get('toc', imMap());
    const filePathMap = state.get('filePathMap', imMap());
    return { toc, filePathMap };
  }
)(Search);
