import React from 'react';
import * as T from 'prop-types';
import IPT from 'react-immutable-proptypes';
import cn from 'classnames';

import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import PrivacyPolicy from '@au/core/lib/components/elements/PrivacyPolicy';

import { DESCRIPTION_KEY, INDICATOR_KEY, COLOR_KEY } from '../constants';
import slug from '../utils/slug';
import { cleanUrl } from '../utils/url';
import SectionTile from './SectionTile';


import styles from '../css/components/zone.module.scss';

export default class Zone extends React.Component {
  static propTypes = {
    sections: IPT.orderedMap,
    urlPrefix: T.string.isRequired,
    style: T.oneOf(['services', 'getting-started']),
    splash: T.bool
  }

  static defaultProps = {
    style: 'services',
    splash: false
  }

  renderTiles() {
    const { sections, urlPrefix, style } = this.props;

    if (!sections) {
      return <div className={styles.skeleton}>
        <div className={styles.skeleton_text}></div>
        <div className={styles.skeleton_tiles}>
          <div className={styles.skeleton_tile}></div>
          <div className={styles.skeleton_tile}></div>
          <div className={styles.skeleton_tile}></div>
          <div className={styles.skeleton_tile}></div>
        </div>
      </div>;
    }

    const tiles = [];
    for (let [key, docMeta] of sections.entrySeq()) {
      const title = key;
      const description = docMeta.get(DESCRIPTION_KEY);
      const indicator = docMeta.get(INDICATOR_KEY);
      const to = cleanUrl(urlPrefix + '/' + slug(key));
      const color = docMeta.get(COLOR_KEY);

      tiles.push(
        <SectionTile
          key={key}
          title={title}
          description={description}
          to={to}
          color={color}
          indicator={indicator}
          style={style} />
      );
    }

    return tiles;
  }

  renderSplash() {
    if (this.props.splash) {
      return (
        <div className={styles.splash}>
          <div className={styles.wrapper}>
            <AutoIntl displayId="au.home.title" tag="h1" />
          </div>
        </div>
      );
    }
    return false;
  }

  render() {
    return (
      <div className={cn(styles.container, styles[this.props.style])}>
        {this.renderSplash()}
        <div className={styles.content}>
          <div className={styles.tilescontainer}>
            <div className={styles.tiles}>
              {this.renderTiles()}
            </div>
          </div>
        </div>
        <PrivacyPolicy className={styles.policy} />
      </div>
    );
  }
}
