import React from "react";
import copy from "@au/core/lib/utils/copyTextToClipboard";
import AuButton from "@au/core/lib/components/elements/AuButton";
import AutoIntl from "@au/core/lib/components/elements/AutoIntl";
import {BUTTON_SIZE_MEDIUM, BUTTON_SIZE_SMALL, BUTTON_TYPE_PLAIN} from "@au/core/lib/components/elements/AuButton";

import styles from "../css/components/ftcp/cell_with_copy.module.scss";
import cn from "classnames";

export const UNAVAILABLE_ID = "au.uriUnavailable";
export const COPY_TYPES = {CHILDREN: 'children', URI: 'uri'};

export default function CellWithCopy({children, data = children, uri, uriCopyDisplayId = "au.copy", ...cellProps}) {
  const cellRef = React.useRef();
  const [showTooltip, setShowTooltip] = React.useState(false);
  const [hasCopied, setHasCopied] = React.useState({[COPY_TYPES.URI]: false, [COPY_TYPES.CHILDREN]: false});

  function onMouseEnter() {
    if (cellRef.current.offsetWidth < cellRef.current.scrollWidth) {
      setHasCopied(false);
      setShowTooltip(true);
    }
  }

  function onMouseLeave() {
    setShowTooltip(false);
  }

  function getTooltipTop() {
    return cellRef.current.getBoundingClientRect().top;
  }

  function getTooltipLeft() {
    return cellRef.current.offsetLeft;
  }

  function handleCopy(copyType) {
    copy(copyType === COPY_TYPES.URI ? uri : data);
    setHasCopied({...hasCopied, [copyType]: true});
    setTimeout(() => setHasCopied({...hasCopied, [copyType]: false}), 1000);
  }

  function addTooltip(children) {
    return <span className={styles.tooltipContainer} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {showTooltip && <div className={styles.tooltip} style={{top: getTooltipTop(), left: getTooltipLeft()}}>
        {children}
        <AuButton
          size={BUTTON_SIZE_MEDIUM}
          type={BUTTON_TYPE_PLAIN}
          className={styles.tooltipCopy}
          displayId={hasCopied[COPY_TYPES.CHILDREN] ? 'au.copied' : 'au.copy'}
          disabled={hasCopied[COPY_TYPES.CHILDREN]}
          onClick={() => handleCopy(COPY_TYPES.CHILDREN)}
        />
      </div>}
      {children}
    </span>;
  }

  const isUriUnavailable = uri === UNAVAILABLE_ID;
  return (
    <div {...cellProps} ref={cellRef}>
      {children && addTooltip(children)}
      {uri && (
        <div className={cn({[styles.uriContainer]: Boolean(children)})}>
          <AutoIntl className={styles.uri} displayId={isUriUnavailable ? uri : undefined} displayString={uri}/>
          {!isUriUnavailable && <AuButton
            size={BUTTON_SIZE_SMALL}
            type={BUTTON_TYPE_PLAIN}
            className={styles.copy}
            displayId={hasCopied[COPY_TYPES.URI] ? 'au.copied' : uriCopyDisplayId}
            disabled={hasCopied[COPY_TYPES.URI]}
            onClick={() => handleCopy(COPY_TYPES.URI)}
          />}
        </div>
      )}
    </div>
  );
}
