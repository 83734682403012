import React from 'react';
import {Route, Switch} from "react-router-dom";
import TmcLookup from "./TmcLookup";
import NotFound from "../NotFound";
import FtcpLookup from "./FtcpLookup";
import FtcpSignalInformation from "./FtcpSignalInformation";
import SignalBundle from "./SignalBundle";
import TmcEvent from "./TmcEvent";
import TmcSignal from "./TmcSignal";
import {FTCP_LOOKUP_PATH, FTCP_SIGNAL_INFORMATION_PATH, SIGNAL_BUNDLE_PATH, TMC_LOOKUP_PATH, TMC_EVENT_PATH, TMC_SIGNAL_PATH, TMC_INDICATOR_PATH, TMC_CONFIGURATION_PATH} from "../../constants";

export default class TMCLookupRouter extends React.Component {
  render() {
    return (
      <Switch>
        <Route path={TMC_LOOKUP_PATH} component={TmcLookup} exact/>
        <Route path={FTCP_LOOKUP_PATH} component={FtcpLookup} exact />
        <Route path={FTCP_SIGNAL_INFORMATION_PATH} component={FtcpSignalInformation} exact/>
        <Route path={SIGNAL_BUNDLE_PATH} component={SignalBundle} exact/>
        <Route path={TMC_EVENT_PATH} component={TmcEvent} exact/>
        <Route path={TMC_SIGNAL_PATH} component={TmcSignal} exact/>
        <Route path={TMC_CONFIGURATION_PATH} component={TmcSignal} exact/>
        <Route path={TMC_INDICATOR_PATH} component={TmcSignal} exact/>
        <Route component={NotFound}/>
      </Switch>
    );
  }
}
