import React, { Component } from 'react';
import * as T from 'prop-types';
import IPT from 'react-immutable-proptypes';
import { Route, Redirect, Switch } from 'react-router-dom';

import AuAnalytics from '@au/core/lib/utils/AuAnalytics';

import {
  SWAGGER_URL_KEY, SWAGGER_TITLE_KEY, SUB_DOCS_TOC_KEY,
  REDIRECT_DOC_KEY, PATH_TOC_KEY, TOC_MAPPINGS_KEY, FILE_LINK_APP
} from '../constants';
import browserHistory from '../history';
import slug from '../utils/slug';
import { matchSegment, cleanUrl } from '../utils/url';

import Doc from './Doc';
import NotFound from './NotFound';

class DocRouter extends Component {
  static propTypes = {
    match: T.object.isRequired,
    entries: IPT.orderedMap,
    swaggerRef: T.string
  }

  renderNextLevel(entries) {
    const { match } = this.props;
    const nextSegment = slug(entries.keySeq().first());
    return (
      <Switch>
        <Route
          path={cleanUrl(`${match.path}/:segment`)}
          render={props =>
            <DocRouter {...props}
              entries={entries}
              swaggerRef={this.props.swaggerRef}
            />
          }
        />;
        <Redirect to={cleanUrl(`${match.url}/${nextSegment}`)} />
      </Switch>
    );
  }

  render() {
    const { match, entries } = this.props;
    const { segment } = match.params;

    // check to see if segment is a path or doc slug
    if (segment) {
      const entry = matchSegment(segment, entries);

      if (!entry) {
        AuAnalytics.trackException(`Missing entry for path=${match.url}`);
        return <NotFound />;
      }

      const swaggerRef = entry.has(SWAGGER_URL_KEY)
                         ? slug(entry.get(SWAGGER_TITLE_KEY, segment))
                         : this.props.swaggerRef;

      if (entry.has(SUB_DOCS_TOC_KEY)) {
        return this.renderNextLevel(entry.get(SUB_DOCS_TOC_KEY));
      }
      else {
        // hit

        if (entry.has(REDIRECT_DOC_KEY)) redirect: {
          // slice(1, -1) to remove the language prefix and filename
          let redirectPath = entry.get(PATH_TOC_KEY).split('/').slice(1, -1).join('/');
          redirectPath += '/' + entry.get(REDIRECT_DOC_KEY);

          // remove relative pathing
          const { pathname } = new URL(redirectPath, window.location.origin);

          let redirectUrl = entry.get(TOC_MAPPINGS_KEY).get(pathname).get(FILE_LINK_APP);
          if (!redirectUrl) {
            console.warn(`unable to find redirect URL for ${pathname}`); // eslint-disable-line no-console
            break redirect;
          }
          redirectUrl += pathname.split('#')[1] || '';

          // NOTE: not tracking language or exact doc redirected to
          AuAnalytics.trackEvent({
            category: 'Document',
            action: 'redirect',
            label: `${window.location.pathname} -> ${redirectUrl}`
          });

          browserHistory.replace(redirectUrl);
          return false;
        }

        return <Doc entry={entry} downloadPrefix={match.params.sectionName} swaggerRef={swaggerRef} />;
      }
    }
    else {
      return this.renderNextLevel(entries);
    }
  }
}


// DocRouter is initially called with `section` but recursively with `entries`
export default props => {
  const map = {...props, entries: props.section };
  delete map.section;
  return <DocRouter {...map} />;
};
