import { Map as imMap } from 'immutable';

import { getIn, eachEntry, eachDoc, eachDir } from '@au/dev-docs/toc/common';
import { buildOverlayToc } from '@au/dev-docs/toc/overlay';

import slug from './slug';
import {
  META_SORT_KEY,
  SUB_DOCS_TOC_KEY,
  PATH_TOC_KEY,
  TOC_MAPPINGS_KEY,
  FALLBACK_LANG,
  FILE_LINK_APP,
  IS_CHOSEN_LANG,
  SWAGGER_URL_KEY,
  SWAGGER_TITLE_KEY,
  PERMALINKS_DOC_KEY,
  HASH_DOC_KEY,
  DOC_CONTENT_KEY,
  DOC_TOC_PATH_KEY,
  DOC_APP_PATH_KEY
} from '../constants';

// assumes toc path is absolute
export function tocToAppPath(path) {
  return '/' + path.map(p => slug(p)).join('/');
}

// NOTE: it is possible that a file path can exist in multiple places in the
// TOC. In that case the last in a DFS will replace the previous.
export function getFilePathToTocPath(start, files=imMap()) {
  eachDoc(start, (entry, _, context) => {
    let path = entry.get(PATH_TOC_KEY);
    files = files.set(path, context.tocPath);
  });
  return files;
}

function injectDocs(entries, docs) {
  return entries.withMutations(m =>
    eachDoc(m, (doc, _, context) => {
      const hash = doc.get(HASH_DOC_KEY);
      if (docs.has(hash)) {
        m.setIn([...context.mapPath, DOC_CONTENT_KEY], docs.get(hash));
      }
    })
  );
}

// Adds a lookup map to each document so that markdown parsing can correctly
// replace *.md links with app links.
export function addTocLocalLinkMap(overlayedToc, imTocMappings) {
  return overlayedToc.withMutations(m =>
    eachDoc(m, (_, __, context) =>
      m.setIn([...context.mapPath, TOC_MAPPINGS_KEY], imTocMappings)
    )
  );
}

// expects immutable Map{`filePath`: Array[`tocPath`]}
export function stripLangSeg(files) {
  const strippedFiles = imMap().asMutable();
  for (let [filePath, mappings] of files.entries()) {
    // remove language prefix for markdown path lookups
    strippedFiles.set('/' + filePath.split('/').slice(1).join('/'), mappings);
  }
  return strippedFiles.asImmutable();
}

// TODO/TECHDEBT: update so we do not have to embed full maps into each document
export function buildOverlayLanguageToc(toc, chosenLang, fallbackLang=FALLBACK_LANG) {
  if (!toc.has(chosenLang)) {
    chosenLang = fallbackLang;
  }

  let overlayedToc = buildOverlayToc(toc, chosenLang, fallbackLang);

  const chosenLangFiles = stripLangSeg(getFilePathToTocPath(toc.get(chosenLang)));

  // Needed to make the local file links
  let overlayedTocFiles = stripLangSeg(getFilePathToTocPath(overlayedToc));
  let filePathMappings = imMap();
  for (let [mdFilePath, tocPath] of overlayedTocFiles.entries()) {
    let appFilePath = tocToAppPath(tocPath);
    let isChosenLang = chosenLangFiles.has(mdFilePath);
    filePathMappings = filePathMappings.set(mdFilePath, imMap({ [FILE_LINK_APP]: appFilePath, [IS_CHOSEN_LANG]: isChosenLang, tocPath }));
  }

  overlayedToc = addTocLocalLinkMap(overlayedToc, filePathMappings);

  return overlayedToc;
}

function sort(a, b) {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
}

// recursively sorts everything starting with entries
export function sortEntries(entries) {
  // sort by meta data first
  const metaSorted = entries
    .filter(e => e.has(META_SORT_KEY))
    .sortBy(
      e => e.get(META_SORT_KEY),
      sort
    );

  const keySorted = entries
    .filter(e => !e.has(META_SORT_KEY))
    .sortBy(
      (_, key) => key,
      sort
    );

  let sortedEntries = metaSorted.merge(keySorted);
  for (let [key, entry] of sortedEntries.entries()) {
    if (entry.has(SUB_DOCS_TOC_KEY)) {
      sortedEntries = sortedEntries.set(key, sortChildren(entry));
    }
  }

  return sortedEntries;
}

// recursively sorts everything starting with SUB_DOCS_TOC_KEY
export function sortChildren(start) {
  for (let [key, value] of start.entries()) {
    if (key === SUB_DOCS_TOC_KEY) {
      for (let [subKey, subValue] of value.entries()) {
        value = value.set(subKey, sortChildren(subValue));
      }
      start = start.set(key, sortEntries(value));
    }
  }
  return start;
}

// recursively sorts the toc from any starting position
export function sortToc(start) {
  if (start.has(SUB_DOCS_TOC_KEY)) {
    return sortChildren(start);
  }
  else {
    return sortEntries(start);
  }
}

// recursively look for swagger URL entries
export function swaggerEntries(start) {
  return imMap().withMutations(m =>
    eachDir(start, (entry, title) => {
      if (entry.has(SWAGGER_URL_KEY)) {
        // fallback to title if SWAGGER_TITLE_KEY is not set
        m.set(entry.get(SWAGGER_TITLE_KEY, title), entry.get(SWAGGER_URL_KEY));
      }
    })
  );
}


// This file is a mess. Put everything new into this class, which is expected
// to be constructed by a memozied function.
// NOTE: if the path is an array it is assumed to be a tocPath and if a string
//       it is assumed to be an appPath (partial URL).
export class Toc {
  static overlayLanguage = buildOverlayLanguageToc;
  static sortToc = sortToc;

  static annotatePaths(toc) {
    let annotatedToc = toc;
    eachEntry(toc, (entry, title, context) => {
      entry = entry.set(DOC_TOC_PATH_KEY, context.tocPath);
      const appPath = tocToAppPath(context.tocPath);
      entry = entry.set(DOC_APP_PATH_KEY, appPath);
      annotatedToc = annotatedToc.setIn(context.mapPath, entry);
    });
    return annotatedToc;
  }

  // expects documents to be annotated with paths first
  static buildLookups(toc) {
    const appPathLookup = {};
    eachDoc(toc, entry =>
      appPathLookup[entry.get(DOC_APP_PATH_KEY)] = entry.get(DOC_TOC_PATH_KEY)
    );

    // add permalink maps to appPathLookup
    const permalinkAppPathLookup = {};
    eachDoc(toc, (entry, _, context) => {
      let permalinks = entry.get(PERMALINKS_DOC_KEY);
      if (!permalinks) {
        return;
      }
      for (let link of permalinks) {
        // FYI: duplicate permalinks are not allowed in the toc.json
        permalinkAppPathLookup[link] = context.tocPath;
      }
    });

    // full paths, AKA leafs
    return {
      appPathLookup,
      permalinkAppPathLookup
    };
  }

  constructor(rawToc, lang, docs) {
    let toc = this.constructor.overlayLanguage(rawToc, lang);
    toc = this.constructor.annotatePaths(toc);
    toc = this.constructor.sortToc(toc);
    this._pathMaps = this.constructor.buildLookups(toc);
    this.toc = injectDocs(toc, docs);
  }

  // true if appPath will resolve to a page, such as a document or zone with
  // section tiles
  hasResolvableAppPath(appPath) {
    if (!appPath.endsWith('/')) {
      appPath += '/'; // to ensure '/abc/d' does not match '/abc/df'
    }
    for (let tocAppPath of Object.keys(this._pathMaps.appPathLookup)) {
      tocAppPath += '/';
      if (tocAppPath.startsWith(appPath)) return true;
    }
    return false;
  }

  getIn(path, ifNotFound) {
    if (Array.isArray(path)) {
      return this._tocPathGetIn(path, ifNotFound);
    }
    else {
      // assuming path is a string and thus an appPath
      return this._appPathGetIn(path, ifNotFound);
    }
  }

  _tocPathGetIn(tocPath, ifNotFound) {
    return getIn(this.toc, tocPath, ifNotFound);
  }

  _appPathGetIn(appPath, ifNotFound) {
    const segments = appPath.split('/');
    let cursor = this.toc;
    for (let seg of segments) {
      // avoid empty segments generally from trailling and leading '/'
      if (!seg) continue;

      if (cursor.has(SUB_DOCS_TOC_KEY)) {
        cursor = cursor.get(SUB_DOCS_TOC_KEY);
      }

      let matched;
      for (let [key, entry] of cursor.entrySeq()) {
        if (slug(key) === seg) {
          matched = entry;
          break;
        }
      }
      if (!matched) return ifNotFound;
      cursor = matched;
    }

    return cursor;
  }

  eachEntry(fn, start=this.toc) {
    return eachEntry(start, fn);
  }

  eachDoc(fn, start=this.toc) {
    return eachDoc(start, fn);
  }

  eachDir(fn, start=this.toc) {
    return eachDir(start, fn);
  }
}
