import React from 'react';
import TMC from "@autonomic/browser-sdk";
import {Link} from "react-router-dom";
import * as T from 'prop-types';
import cn from "classnames";

import AutoIntl from "@au/core/lib/components/elements/AutoIntl";
import LoadingIndicator from "@au/core/lib/components/elements/LoadingIndicator";

import {TMC_LOOKUP_PATH, FTCP_LOOKUP_PATH} from "../../constants";
import Breadcrumbs from "../Breadcrumbs";
import FeedbackButton from "./LookupFeedbackButton";
import {SignalMappingTable, EVENT_SIGNAL_TABLE} from "./SignalMappingTable";
import {CollapsibleConditionsTable} from "./CollapsibleConditionsTable";
import CellWithCopy, {UNAVAILABLE_ID} from "../CellWithCopy";
import SearchResult from "./SearchResult";
import CombineMappings from "./CombineMappings";

import styles from "../../css/components/ftcp/tmc_event.module.scss";
import tmcLookupStyles from "../../css/components/ftcp/tmc_lookup.module.scss";

export class VersionListItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    };
  }

  openAlertList() {
    this.setState(prevState => ({expanded: !prevState.expanded}));
  }

  render() {
    const { ftcpEvent } = this.props;
    const { expanded } = this.state;

    return (
      <div key={ftcpEvent} onClick={() => this.openAlertList()}>
        <span className={expanded ? styles.collapse : styles.expand}/>
        <div className={styles.ftcpEventListItem}>{ftcpEvent.name}</div>
        <div className={expanded ? styles.ftcpVersionList : styles.closedVersionList}>
          {[...new Set(ftcpEvent.items)].map((item) =>
            <Link key={ftcpEvent.name + item} to={`${FTCP_LOOKUP_PATH}?ftcpAlert=${ftcpEvent.name}&version=${item}`} className={styles.link}>
              {item}
            </Link>
          )}
        </div>
      </div>
    );
  }
}

export default class TmcEvent extends React.Component {

  FtcpOracleService = new TMC.services.FtcpOracle({apiVersion: '1-alpha'});

  constructor(props) {
    super(props);

    this.state = {
      event: undefined,
      tmcEvent: "",
      version: "",
      isPopupOpen: false,
      errorMessage: "",
      loading: true,
      expanded: true
    };
  }

  static propTypes = {
    event: T.object,
    location: T.object,
  }

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const tmcEvent = params.get('tmcEvent');

    this.setState({tmcEvent: tmcEvent});

    this.FtcpOracleService.events.getWellKnownEvent({
      name: tmcEvent
    }).then(res => {
      if (res.data) {
        res.data.wellKnownSignals = res.data.wellKnownSignals?.map(signal => ({
          ...signal,
          canSignalMappings: signal.canSignalMappings?.reduce(CombineMappings, [])
        }));
        this.setState({event: res.data, loading: false});
      }
    }).catch(error => {
      if (error?.data?.code === 404) {
        return this.setState({errorMessage: "au.tmcEvent.eventError", loading: false});
      }
      this.setState({errorMessage: "au.tmcEvent.mappingError", loading: false});
    });
  }

  renderBreadcrumbs() {
    const {tmcEvent} = this.state;
    const lookupDestination = `${TMC_LOOKUP_PATH}?tmcEvent=${tmcEvent}`;

    return <Breadcrumbs
      crumbs={[
        {key: "tmc-lookup", displayId: "au.tmcLookup.header", destination: lookupDestination, tag: "tmc-lookup"},
        {key: "ftcp-lookup", displayId: "au.tmcEvent.header", tag: "tmc-event"},
      ]}
    />;
  }

  render() {
    const {loading, errorMessage, tmcEvent, event, expanded} = this.state;

    if (loading) {
      return (
        <div className={tmcLookupStyles.container}>
          {this.renderBreadcrumbs()}
          <FeedbackButton/>
          <LoadingIndicator displayId="au.ftcpLookup.loadingIndicator"/>
        </div>
      );
    }

    if (errorMessage) {
      return (
        <div className={tmcLookupStyles.container}>
          {this.renderBreadcrumbs()}
          <FeedbackButton/>
          <SearchResult>
            <AutoIntl className={tmcLookupStyles.searchResultText} displayId={errorMessage} values={{name: tmcEvent, version: "v1"}} />
          </SearchResult>
        </div>
      );
    }

    return (
      <div className={styles.container}>
        <div className={styles.lookup_container}>
          {this.renderBreadcrumbs()}
          <FeedbackButton />
          {event && <SearchResult>
            <AutoIntl className={tmcLookupStyles.searchResultText} displayId={event.ftcpEvents ? "au.tmcEvent.eventConverted" : "au.tmcEvent.eventNotConverted"} values={{eventName: <b>{event.wellKnownEvent.name}</b>}} />
            <div>
              {event.ftcpEvents &&
                <div className={styles.alert_list}>
                  {event.ftcpEvents.map((ftcpEvent) =>
                    <VersionListItem key={ftcpEvent.name} ftcpEvent={ftcpEvent}/>
                  )}
                </div>
              }
            </div>
            <div className={tmcLookupStyles.uri_container}>
              <div className={tmcLookupStyles.uri_labels}>
                {event?.wellKnownEvent?.description && <AutoIntl className={tmcLookupStyles.uri_result} displayId='au.ftcpSignalInformation.signalDescription' />}
                <AutoIntl className={tmcLookupStyles.uri_result} displayId='au.tmcLookup.uri'/>
              </div>
              <div className={tmcLookupStyles.uri_values}>
                {event?.wellKnownEvent?.description &&
                  <div>{event.wellKnownEvent.description}</div>
                }
                <CellWithCopy
                  uri={event?.wellKnownEvent?.uri ?? UNAVAILABLE_ID}
                />
              </div>
            </div>
            {event.ftcpEvents &&
              <AutoIntl displayId={'au.tmcEvent.containedSignals'}/>
            }
          </SearchResult>}
          <div className={styles.conditions_table_container}>
            <span className={expanded ? styles.collapse : styles.expand} onClick={() => this.setState(prevState => ({expanded: !prevState.expanded}))}/>
            <AutoIntl className={cn(styles.signal_mapping_table_header, styles.conditions_table_header)} displayId={'au.tmcEvent.conditionsTableHeader'}/>
            <div className={expanded ? styles.open : styles.closed}>
              <AutoIntl className={styles.conditions_table_subheader} displayId={'au.tmcEvent.conditionsTableSubheader'}/>
              {event.wellKnownEvent?.triggerConditionsToMetricsMap[0] && <CollapsibleConditionsTable
                conditions={event.wellKnownEvent.triggerConditionsToMetricsMap}
                />
              }
            </div>
          </div>
          <AutoIntl className={styles.signal_mapping_table_header} displayId={'au.tmcEvent.signalMappingHeader'}/>
          {event?.wellKnownSignals?.length ?
            <SignalMappingTable
              messages={event.wellKnownSignals} openPopup={() => this.setState({isPopupOpen: true})}
              entityType={EVENT_SIGNAL_TABLE}
            /> :
            <AutoIntl className={tmcLookupStyles.no_signals} displayId="au.tmcEvent.noSignals" tag="div"/>
          }
        </div>
      </div>
    );
  }
}
