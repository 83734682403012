import AwsS3 from 'aws-sdk/clients/s3';

import shared from '../shared';

export default class S3 {
  constructor(path) {
    this._path = path[0] === '/' ? path.slice(1) : path;
  }

  get path() {
    return this._path;
  }

  _genBucket(Bucket, region = 'us-west-2') {
    return {
      apiVersion: '2006-03-01',
      credentials: shared.awsConfigObj.credentials,
      region,
      params: { Bucket }
    };
  }

  _get() {
    const s3 = new AwsS3(this._genBucket(shared.config.s3Bucket, shared.config.region));

    return new Promise(
      (res, rej) => {
        s3.getObject({ Key: this._path, ResponseCacheControl: 'no-cache' }, (err, data) => {
          if (err) {
            rej(err);
            // so we print to automated tests
            console.error(err); // eslint-disable-line no-console
            return;
          }
          res(data);
        });
      }
    );
  }

  blob() {
    return this._get()
      .then(data => new Blob([data.Body], { type: data.ContentType }));
  }

  blobUrl() {
    return this.blob()
      .then(blob => URL.createObjectURL(blob));
  }

  string() {
    return this._get()
      .then(data => ({...data, payload: data.Body.toString('utf-8')}));
  }

  json() {
    return this._get()
      .then(data => ({...data, payload: JSON.parse(data.Body)}));
  }
}
