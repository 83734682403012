import { Map as imMap } from 'immutable';
import AuRemarkable from './auRemarkable';
import loadExternals from './loadExternals';

// parseMarkdown() ensures a new AuRemarkable object is created for each call
export function parseMarkdown(markdown, path='/', tocLocalLinkMappings=imMap()) {
  const auRemarkable = new AuRemarkable(markdown, path, tocLocalLinkMappings);
  const basePath = path.split('/');
  basePath.pop();
  const ready = loadExternals(auRemarkable, basePath.join('/'));
  const mdMeta = {
    markdown,
    html: auRemarkable.html,
    meta: auRemarkable.remarkable.meta,
    anchorLinks: auRemarkable.remarkable.anchorLinks,
    node: auRemarkable.node,
    ready,
    isReady: false
  };

  mdMeta.ready
    .then(() => mdMeta.isReady = true);
  return mdMeta;
}
