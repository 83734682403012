import React, { PureComponent } from 'react';
import { injectIntl } from "react-intl";
import * as T from 'prop-types';
import cn from 'classnames';

import Modal from '@au/core/lib/components/objects/Modal';
import AuAnalytics from '@au/core/lib/utils/AuAnalytics';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import AuInput from '@au/core/lib/components/elements/AuInput';
import ConfirmationDialog from '@au/core/lib/components/objects/ConfirmationDialog';
import { createAlertMessage, alertCategory } from '@au/core/lib/components/objects/AlertMessage';
import IconButton from '@au/core/lib/components/elements/IconButton';
import { trackException } from '@au/core/lib/utils/ga';

import { EMAIL_REGEX, RELEASE_NOTES_SUBSCRIPTION_LINK } from '../constants';
import shared from '../shared';

import styles from '../css/components/subscription.module.scss';

class SubscriptionButton extends PureComponent {
  static propTypes = {
    email: T.string
  }

  state = {
    hasError: false,
    showDialog: false,
    showSuccess: false,
    email: this.props.email,
    checked: false,
    confirmBtnDisabled: true
  };

  componentDidMount() {
    if (shared.email) {
      this.setState({ email: shared.email });
    }
  }

  componentDidUpdate() {
    const { email, checked, confirmBtnDisabled } = this.state;

    if (this.emailIsValid(email) && checked) {
      if (confirmBtnDisabled) {
        this.setState({ confirmBtnDisabled: false });
      }
    } else if (!confirmBtnDisabled) {
        this.setState({ confirmBtnDisabled: true });
    }
  }

  toggleAcceptCommunication = this.toggleAcceptCommunication.bind(this);
  toggleAcceptCommunication() {
    this.setState(prevState => ({ checked: !prevState.checked }));
  }

  handleOnChange = this.handleOnChange.bind(this);
  handleOnChange(e) {
    this.setState({ email: e.target.value });
  }

  emailIsValid = this.emailIsValid.bind(this);
  emailIsValid (email) {
    return EMAIL_REGEX.test(email);
  }

  onSuccess = this.onSuccess.bind(this);
  onSuccess() {
    this.setState({ showDialog: false, showSuccess: true }, () => {
      setTimeout(() => this.setState({ showSuccess: false }), 5000);
    });
  }

  genericErrorHandler = this.genericErrorHandler.bind(this);
  genericErrorHandler(response) {

    const errorTitle = response?.message;
    const errorMessage = response?.errors?.[0]?.message;

    createAlertMessage({
      category: alertCategory.error,
      titleId: errorTitle ? undefined : 'au.errors.400.title',
      titleString: errorTitle || '',
      messageId: errorMessage ? undefined : 'au.error.generic',
      messageString: errorMessage || ''
    });

    // log error message
    trackException(response.toString());
  }

  subscribeToReleases = this.subscribeToReleases.bind(this);
  subscribeToReleases(email) {

    AuAnalytics.trackEvent({
      category: 'SubscriptionDialog',
      action: 'click',
      label: window.location.pathname
    });

    const data = {
      "fields": [
        {
          "name": "email",
          "value": email
        }
      ],
      "legalConsentOptions": {
        "consent": {
          "consentToProcess": true,
          "text": this.props.intl.formatMessage({ id: "au.subscribe.message" }),
          "communications": [
            {
              "value": true,
              "subscriptionTypeId": 9112740,
              "text": this.props.intl.formatMessage({ id: "au.subscribe.accept.communication"})
            }
          ]
        }
      }
    };

    fetch('https://api.hsforms.com/submissions/v3/integration/submit/6374686/a45c73b8-e028-4440-a364-bbe9f868a8b2', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
    })
    .then(resp => resp.ok ? this.onSuccess() : resp.json().then(this.genericErrorHandler));
  }

  showSuccess() {
    return (
      <Modal>
        <div className={styles.close} onClick={() => this.setState({ showSuccess: false })}></div>
        <div className={styles.success_container}>
          <AutoIntl displayId='au.subscribe.success' tag={'div'} className={styles.success_header} />
          <AutoIntl displayId='au.subscribe.success.message' tag={'div'} className={styles.success_subheader} />
          <AutoIntl displayId='au.subscribe.success.signoff' tag={'div'} className={styles.signoff} />
        </div>
      </Modal>
    );
  }

  showDialog() {
    const { email, checked, confirmBtnDisabled} = this.state;
    const empty = email === '';

    return (
      <ConfirmationDialog confirmBtnDisabled={confirmBtnDisabled} onConfirm={() => this.subscribeToReleases(email)} onCancel={() => this.setState({ showDialog: false })} confirmDisplayId='au.subscribe.confirmation' className={styles.container}>
        <AutoIntl displayId='au.subscribe' tag={'div'} className={styles.header} />
        <AutoIntl displayId='au.subscribe.subheader' tag={'div'} className={styles.subheader} />
        <div>
          <div className={styles.input_container}>
            <AuInput
              name="email"
              type="text"
              className={styles.input}
              value={email}
              onChange={this.handleOnChange}
            />
            {!empty &&
              <button
                className={styles.clear}
                onClick={() => this.setState({ email: '' })}
              />
            }
          </div>
          <AutoIntl displayId='au.subscribe.message' tag={'div'} className={styles.message} />
          <div className={styles.checkbox_container}>
            <AuInput
              type="checkbox"
              displayId={'au.subscribe.accept.communication'}
              className={styles.checkbox}
              checked={checked}
              onChange={this.toggleAcceptCommunication}
            />
            <AutoIntl displayId='au.subscribe.accept.communication' tag={'div'} className={styles.confirmation_message} />
          </div>
        </div>
      </ConfirmationDialog>
    );
  }

  openSubscriptionLink = this.openSubscriptionLink.bind(this);
  openSubscriptionLink() {
    window.open(RELEASE_NOTES_SUBSCRIPTION_LINK, '_blank');
  }

  render() {
    const { showDialog, showSuccess } = this.state;
    const { tablet } = this.props;

    return (
      <div className={styles.subscribe_container}>
        <IconButton className={styles.subscription} labelClassName={cn(styles.label, {[styles.tablet]: tablet})} displayId='au.subscribe' onClick={this.openSubscriptionLink}/>
        { showDialog && this.showDialog() }
        { showSuccess && this.showSuccess() }
      </div>
    );
  }
}

export default injectIntl(SubscriptionButton);
