export const loaded = new Promise(res => {
  window.addEventListener('load', res);
});
// NOTE: "load" fires only once so if we deep link to another page with images
// it is possible to have the same issue of zooming above the link

export function zoomToHash(hash=window.location.hash) {
  // zoom to the named link
  if (hash) {
    loaded
      .then(() => {
        const namedHeaderLink = document.querySelector(`a[name="${hash.substr(1)}"]`);
        if (namedHeaderLink) {
          namedHeaderLink.scrollIntoView();
        }
      });
  }
}

let markdownContainerEl;
export function registerMdContainer(el) {
  markdownContainerEl = el;
}

export function zoomToTop(el=markdownContainerEl) {
  // zoom to the top of the element
  if (el) {
    loaded
      .then(() => {
        el.scrollTop = 0;
      });
  }
}
