import React from "react";
import { useLocation } from "react-router-dom";

import Breadcrumbs from "../Breadcrumbs";
import {DATA_SAMPLER_PATH, DATA_SAMPLER_ENTITY_LIST_PATH, LIST_PATH} from "../../constants";

export default function SamplerBreadCrumbs({deviceType, parentEntityType, entityType, ...breadCrumbProps}) {
  const location = useLocation();
  const { state } = location;
  const returnBreadCrumbs = [{
      key: "data-sampler",
      displayId: "au.dataSampler.header",
      destination: DATA_SAMPLER_PATH,
      tag: "data-sampler"
  }];

  if(state?.from === LIST_PATH) {
    returnBreadCrumbs.push({
      key: "data-sampler-entity-list",
      displayId: `au.dataSampler.entities.header`,
      destination: `${DATA_SAMPLER_ENTITY_LIST_PATH}?deviceType=${deviceType}&entityType=${parentEntityType}`,
      tag: "data-sampler-entities"
    });
  }

  returnBreadCrumbs.push({
    key: "data-sampler-entity-details",
    displayId: `au.dataSampler.entityDetails.entityBreadCrumb.${entityType}`,
    tag: "data-sampler-entity-details"
  });
  return <Breadcrumbs crumbs={returnBreadCrumbs} {...breadCrumbProps}/>;
}
