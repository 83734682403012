import React from "react";
import TMC from "@autonomic/browser-sdk";
import AutoIntl from "@au/core/lib/components/elements/AutoIntl";
import LoadingIndicator from "@au/core/lib/components/elements/LoadingIndicator";
import ResultsDrawer from "@au/core/lib/components/elements/ResultsDrawer";
import { createResponseAlertMessage } from '@au/core/lib/components/objects/AlertMessage';

import SamplerBreadCrumbs from "./SamplerBreadCrumbs";
import {DATA_SAMPLER_ENTITY_TYPES} from "../../constants";
import CollapsibleTable from "./CollapsibleTable";
import {EnumValuesTable, TAGS_TABLE} from "../tmcLookup/TmcSignal";
import EntityResult from "./EntityResult";
import DeviceTypeSelector from "../DeviceTypeSelector";
import {dataSamplerConnect} from "../../reducers";

import styles from "../../css/components/dataSampler/entity_details.module.scss";
import signalStyles from "../../css/components/dataSampler/signal_details.module.scss";

const enumHeaders = [
  {displayId: "au.dataSampler.signals.enums.enum"},
  {displayId: "au.dataSampler.signals.enums.values"}
];

class SignalDetails extends React.Component {

  entityEndpoint = new TMC.services.DataSampler({apiVersion: '1-alpha'}).entity

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      expanded: true,

      isDrawerOpen: false,
      sampleLoading: false,
      viewerData: null,
      drawerTitle: '',
    };
  }

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const deviceType = params.get('deviceType') ?? this.props.deviceTypes[0].val;
    const entityName = params.get('entityName') ?? undefined;

    this.setState({deviceType, entityName}, () => this.getSignal());
  }

  getSignal() {
    const {deviceType, entityName} = this.state;

    this.setState({loading: true});
    this.entityEndpoint.get({deviceType, entityName, entityType: DATA_SAMPLER_ENTITY_TYPES.SIGNAL})
      .then((res) => {
        if (res.data?.entityType === "wellKnownSignal") {
          const tagsInfo = {signalTags: res.data.tags.map(tag => ({...tag, enumValues: tag.enums !== null ? tag.enums : undefined}))}; //To match data type needed for EnumValuesTable
          const enumRows = this.generateTableFromEnumMapping(res.data.enumMappings);
          this.setState({signal: res.data, enumRows, tagsInfo, isCustomSignal: false, error: false});
        } else if (res.data) {
          this.setState({signal: res.data, enumRows: undefined, tagsInfo: undefined, isCustomSignal: true, error: false});
        }
      })
      .catch((res) => {
        if(res?.data?.error === 'not found') {
          this.setState({signal: {entityName}, isCustomSignal: true, error: false});
        } else {
          this.setState({error: true});
          createResponseAlertMessage(res);
        }
      })
      .finally(() => this.setState({loading: false}));
  }

  generateTableFromEnumMapping(enumMappings) {
    return enumMappings.map(mapping => {
      return {
        key: mapping.name,
        elements: [
          {
            key: mapping.name,
            value: mapping.name
          },
          {
            key: mapping.enums.join(),
            lastInRow: true,
            values: mapping.enums.map(e => <div key={e} className={styles.cell}>{e}</div>)
          }
        ]
      }
    });
  }

  closeSampleViewer = this.closeSampleViewer.bind(this);

  closeSampleViewer() {
    this.setState({isDrawerOpen: false})
  }

  handleSampleDataChange = this.handleSampleDataChange.bind(this);

  handleSampleDataChange({isDrawerOpen, drawerTitle, viewerData, sampleLoading, showError}) {
    this.setState({isDrawerOpen, drawerTitle, viewerData, sampleLoading, error: showError});
  }

  DeviceTypeSelector() {
    return <DeviceTypeSelector
      name={this.state.entityName}
      deviceType={this.state.deviceType}
      deviceTypes={this.props.deviceTypes}
      setDeviceType={(value) => this.setState({deviceType: value}, () => this.getSignal())}
      disabled={this.state.isDrawerOpen}
    />;
  }

  EntityResult() {
    const {signal} = this.state;

    return <EntityResult
      entity={signal}
      handleSampleDataChange={this.handleSampleDataChange}
      showDataTypeUnit={true}
    />
  }

  EnumTable() {
    const {signal, enumRows} = this.state;

    if (!enumRows) return null;

    return <div className={styles.basic_table}>
      <AutoIntl className={styles.basic_table_header} displayId="au.dataSampler.signals.enum.header"/>
      {signal?.enumMappings.length > 0 ?
        <CollapsibleTable headers={enumHeaders} rows={enumRows} columnNumber={2} className={signalStyles.ct_margin_top}/> :
        <AutoIntl className={styles.no_table} displayId="au.dataSampler.signals.enums.noSignals" tag="div"/>
      }
    </div>
  }

  TagsTable() {
    const {signal, tagsInfo, entityName} = this.state;

    if (!tagsInfo) return null;

    return <div className={styles.basic_table}>
      <AutoIntl className={styles.basic_table_header} displayId="au.dataSampler.signals.tags.header"/>
      {signal?.tags.length > 0 ?
        <EnumValuesTable info={tagsInfo} type={TAGS_TABLE}/> :
        <AutoIntl
          className={styles.no_table}
          displayId="au.dataSampler.signals.tags.noTags"
          values={{signal: entityName}}
          tag="div"
        />
      }
    </div>
  }

  render() {
    const {loading, error, drawerTitle, isDrawerOpen, viewerData, sampleLoading, isCustomSignal, deviceType} = this.state;

    return <div className={styles.container}>
      <div className={styles.details_container}>
        <SamplerBreadCrumbs deviceType={deviceType} parentEntityType="signal" entityType={isCustomSignal ? "customSignal" : "wellKnownSignal"} />
        {this.DeviceTypeSelector()}
        {loading && <LoadingIndicator displayId="au.loadingIndicator" className={styles.loading_indicator}/>}
        {error && <AutoIntl displayId="au.apiRequest.error" tag="div"/>}
        {!loading && !error &&
          <React.Fragment>
            {this.EntityResult()}
            {this.EnumTable()}
            {this.TagsTable()}
          </React.Fragment>
        }
      </div>
      <ResultsDrawer
        className={styles.results_drawer_container}
        isOpen={isDrawerOpen}
        onClose={this.closeSampleViewer}
        results={viewerData}
        loading={sampleLoading}
        drawerTitle={drawerTitle}
      />
    </div>
  }
}

export default dataSamplerConnect(SignalDetails);
