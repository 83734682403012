import { connect } from 'react-redux';

import Zone from '../components/Zone';

import { SUB_DOCS_TOC_KEY, ZONE_STYLE_KEY, ZONE_SPLASH_KEY, DESCRIPTION_KEY, INDICATOR_KEY, COLOR_KEY } from '../constants';
import { getToc } from '../utils/selectors';
import { keyIn } from '../utils/immutable';


export default connect(
  ({ app: state }, { match }) => {
    const { zoneName } = match.params;
    const toc = getToc(state);

    const zone = toc
      .getIn(`/${zoneName}`);
    const sections = zone
      .get(SUB_DOCS_TOC_KEY);

    return {
      sections: sections.map(s => s.filter(keyIn(DESCRIPTION_KEY, COLOR_KEY, INDICATOR_KEY))),
      style: zone.get(ZONE_STYLE_KEY),
      splash: zone.get(ZONE_SPLASH_KEY),
      urlPrefix: match.url,
    };
  },
  null,
  props => props // avoid merging passed in props from <Route>
)(Zone);
