import { Remarkable } from 'remarkable';
import meta from 'remarkable-meta';
import hljs from 'highlight.js/lib/core';

import bash from 'highlight.js/lib/languages/bash';
import cLike from 'highlight.js/lib/languages/c-like';
import cpp from 'highlight.js/lib/languages/cpp';
import java from 'highlight.js/lib/languages/java';
import javascript from 'highlight.js/lib/languages/javascript';
import json from 'highlight.js/lib/languages/json';
import protobuf from 'highlight.js/lib/languages/protobuf';
import python from 'highlight.js/lib/languages/python';
import http from 'highlight.js/lib/languages/http';
import properties from 'highlight.js/lib/languages/properties';
import yaml from 'highlight.js/lib/languages/yaml';
import shell from 'highlight.js/lib/languages/shell';
import { formatMessage } from '../utils/reactIntl.js';

import customStyles from '../css/components/copy_button.module.scss';
import { styles, BUTTON_TYPE_SECONDARY, BUTTON_SIZE_MEDIUM } from '@au/core/lib/components/elements/AuButton.js';

import logger from '../utils/logger';
import copy from '@au/core/lib/utils/copyTextToClipboard';
import { aside, anchorHeading } from './plugins';
import {
  FILE_LINK_APP,
  IS_CHOSEN_LANG,
} from '../constants';

hljs.registerLanguage('bash', bash);
hljs.registerLanguage('c-like', cLike); // needed to register c++
hljs.registerLanguage('c++', cpp);
hljs.registerLanguage('http', http);
hljs.registerLanguage('java', java);
hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('json', json);
hljs.registerLanguage('properties', properties);
hljs.registerLanguage('protobuf', protobuf);
hljs.registerLanguage('python', python);
hljs.registerLanguage('shell', shell);
hljs.registerLanguage('yaml', yaml);

const defaultConfig = {
   highlight(str, lang) {
     if (lang && hljs.getLanguage(lang)) {
       try {
         return hljs.highlight(str, { language: lang, ignoreIllegals: true }).value;
       }
       catch (err) {
         logger.warn(err);
       }
     }

     try {
       return hljs.highlightAuto(str).value;
     }
     catch (err) {
       logger.warn(err);
     }

     logger.warn('unable to highlight', str, lang);

     return '';
   },
   langPrefix: 'language-',
   html: true
 };

 /**
  * Because of remarkable-meta and anchorHeadering, create a new instance for each markdown string
  */

export default class AuRemarkable {
  constructor(markdown, path, tocLocalLinkMappings, config={}) {
    const joinedConfig = {...defaultConfig, ...config};
    const parser = new DOMParser();

    this._remarkable = new Remarkable(joinedConfig);

    this.remarkable
      .use(meta)
      .use(aside)
      .use(anchorHeading);

    this._markdown = markdown;
    this._html  = this.remarkable.render(this._markdown);
    const doc = parser.parseFromString(this.html, 'text/html');
    this._addSectionWrappers(doc);
    this._styleLegalese(doc);
    this._tableWrapper(doc);
    this._node = this._removeBody(doc);
    this._setChosenLanguageLocalLinks(path, tocLocalLinkMappings);
  }

  get remarkable() {
    return this._remarkable;
  }

  get markdown() {
    return this._markdown;
  }

  get html() {
    return this._html;
  }

  get node() {
    return this._node;
  }


  *getLinks(type) {
    for (let a of Array.from(this.node.querySelectorAll('a'))) {
      const isFile = a.pathname.slice(-5).includes('.') && !a.pathname.endsWith('.md');
      if (isFile && type === 'file') {
        yield a;
      }
      else if (!isFile && type === 'url') {
        yield a;
      }
    }
  }

  _addSectionWrappers(doc) {

    const precedingNodes = new Map();
    const asideTags = new Set(['PRE', 'SPAN']);
    let lastNode;

    for (let node of Array.from(doc.body.childNodes)) {
      if (node.nodeType !== 1) {
        continue; // skip text nodes
      }

      if (asideTags.has(node.nodeName)) {
        // in case this is the first node and there is no last node, create one
        if (!lastNode) {
          lastNode = doc.createElement('p');
          node.parentNode.insertBefore(lastNode, node);
          continue; // insertBefore effectively pushed the iterator back one
        }

        if (!precedingNodes.has(lastNode)) {
          precedingNodes.set(lastNode, []);
        }

        precedingNodes.get(lastNode).push(node);
      }
      else {
        lastNode = node;
      }
    }

    for (let [precedingNode, codeBlockNodes] of Array.from(precedingNodes)) {

      const copyBtn = doc.createElement('button');
      copyBtn.innerHTML = formatMessage({id: 'au.markdown.copy'});
      copyBtn.className = styles[BUTTON_TYPE_SECONDARY] + ' ' + styles[BUTTON_SIZE_MEDIUM] + ' ' + customStyles.copy_btn;
      copyBtn.addEventListener('click', function() {copy(codeBlockWrapper.getElementsByTagName('code')[0].innerText);});

      const codeBlockWrapper = doc.createElement('aside');
      codeBlockWrapper.innerHTML = codeBlockNodes.map(b => b.outerHTML).join('\n');
      codeBlockWrapper.prepend(copyBtn);
      codeBlockWrapper.childNodes[1].className = customStyles.code_block;

      const precedingWrapper = doc.createElement('section');
      precedingWrapper.innerHTML = precedingNode.outerHTML;
      precedingWrapper.appendChild(codeBlockWrapper);
      precedingNode.parentNode.insertBefore(precedingWrapper, precedingNode);
      precedingNode.parentNode.removeChild(precedingNode);
      codeBlockNodes.forEach(b => b.parentNode.removeChild(b));
    }
  }

  _tableWrapper(doc) {
    for (let node of Array.from(doc.body.childNodes)) {
      if (node.nodeType !== 1) {
        continue; // skip text nodes
      }
      if (node.nodeName === 'TABLE') {
        const div = document.createElement('div');
        div.className = 'table-wrapper';
        node.parentNode.insertBefore(div, node);
        div.appendChild(node);
      }
    }
  }

  _styleLegalese(doc) {
    // the last paragraph is assumed legalese
    const lastParagraphs = doc.body.querySelectorAll('p:last-child');
    const lastP = lastParagraphs[lastParagraphs.length-1];
    if (lastP) {
      lastP.className = 'document-legalese';
      lastP.setAttribute('data-content', lastP.innerText);
    }
  }

  _removeBody(doc) {
    const parentDiv = doc.createElement('div');
    while (doc.body.childNodes.length) {
      parentDiv.appendChild(doc.body.childNodes[0]);
    }
    return parentDiv;
  }

  _setChosenLanguageLocalLinks(currPath, tocLocalLinkMappings) {
    for (let a of Array.from(this.getLinks('url'))) {
      const isLocal = a.hostname === window.location.hostname;
      if (!isLocal) continue;
      // take the locale out of the path ('/' at the beginning of the path is removed)
      const currMdFile = '/' + currPath.split('/').slice(1).join('/');
      let linkedMdFile;

      // check if the path is a file or a hash
      const match = /(.+?\.md)?(#.+)?$/.exec(a.getAttribute('href').split('/').pop());

      if (!match || (!match[1] && !match[2])) {
        console.error(`${a.getAttribute('href')} is not a markdown file`); // eslint-disable-line no-console
        continue;
      }

      const mdFile = match[1];
      const anchor = match[2];

      if (mdFile) {
        // md file
        linkedMdFile = currMdFile.split('/');
        linkedMdFile.pop();
        linkedMdFile = linkedMdFile.join('/') + '/' + a.getAttribute('href');
      }
      else {
        // hash
        linkedMdFile = currMdFile + anchor;
      }

      // remove the relative pathing
      a.pathname = new URL(linkedMdFile, window.location.origin).pathname;

      if (tocLocalLinkMappings.has(a.pathname)) {
        const appLink = tocLocalLinkMappings.getIn([a.pathname, FILE_LINK_APP]);
        const isCurrentFileinChosenLang = tocLocalLinkMappings.getIn([currMdFile, IS_CHOSEN_LANG]);
        const isLinkedFileinChosenLang = tocLocalLinkMappings.getIn([a.pathname, IS_CHOSEN_LANG]);
        a.pathname = appLink;
        if (!isCurrentFileinChosenLang && isLinkedFileinChosenLang) {
          // If the current file is in the fallback lang but the linked file is in the
          // chosen lang, then we cannot guarantee that the the hash will be correct.
          a.hash = '';
        }
      } else {
        console.error(`${a.pathname} does not exist.`); // eslint-disable-line no-console
      }
    }
  }
}
