import { slug } from 'github-slugger';

/**
 * This utility is retained to provide a facade on top of the slugging library
 * being used. This is the third slugging library which has been adopted, and
 * there is a possible need to over-ride the behavior of the library.
 *
 * NOTE: This library needs to be kept in sync with a similar utility in the
 * Developer Docs repo: https://github.com/autonomic-ai/developer-docs/blob/master/scripts/utils/slug.js
 *
 * @param  {string} str           string to slugify
 * @return {string}               slugified string
 */
export default str => {
  return slug(str);
};
