import TMC from '@autonomic/browser-sdk';
import Network from './network';
import {
  UPDATE_TOC, RECEIVE_DOC, RECEIVE_OBJECT_URL,
  SET_NETWORK_STATE, LOAD_ACCOUNT, SET_TOC_COLLAPSED,
  LOAD_DATA_SAMPLER, LOAD_DATA_SAMPLER_LOADING
} from './actionTypes';
import { NETWORK_STATES } from './constants';
import logger from './utils/logger';

// TODO: write common decorator to make sure only one network request is running


export function updateToc() {
  return (dispatch, getState) => {
    const state = getState()['app'];
    if (state.getIn(['network', 'toc']) === NETWORK_STATES.fetching) {
      return;
    }
    dispatch({
      type: SET_NETWORK_STATE,
      id: 'toc',
      state: NETWORK_STATES.fetching
    });
    new Network('toc.json')
      .json()
      .then(
        data => {
          dispatch({ type: UPDATE_TOC, toc: data.payload });
        },
        err => { throw `Failed to retrieve TOC: "${err.message}"`; }//eslint-disable-line no-throw-literal
      )
      .catch(logger.error.bind(logger))
      .then(() => dispatch({
        type: SET_NETWORK_STATE,
        id: 'toc',
        state: NETWORK_STATES.done
      }));
  };
}

const fetching = new Set();

export function downloadDoc(entry) {
  const path = entry.get('_path');
  const hash = entry.get('_hash');
  return (dispatch, getState) => {
    if (getState().app.hasIn(['docs', hash])) {
      logger.error('Skipping already downloaded doc', path, hash);
      return;
    }
    if (fetching.has(path)) {
      return;
    }
    fetching.add(path);
    new Network(path)
      .string()
      .then(
        data => dispatch({ type: RECEIVE_DOC, doc: data.payload, hash }),
        err => logger.error(`Failed to download doc at ${path}: "${err}"`)
      )
      .then(() => fetching.delete(path));
  };
}

export function injectPreviewDoc(docText) {
  return { type: RECEIVE_DOC, doc: docText, hash: Date.now() };
}

export function setTocCollapsed(collapsed) {
  return { type: SET_TOC_COLLAPSED, collapsed };
}

export function downloadImg(url) {
  return (dispatch, getState) => {
    if (getState().app.hasIn(['objectUrls', url])) {
      logger.error('Skipping already downloaded image', url);
      return;
    }
    if (fetching.has(url)) {
      return;
    }
    new Network(url)
      .blobUrl()
      .then(objectUrl => dispatch({ type: RECEIVE_OBJECT_URL, url, objectUrl }))
      .then(() => fetching.delete(url));
  };
}

export function loadAccount(accountId, transporter) {
  const accounts = new TMC.services.Accounts({ apiVersion: 1, transporter });
  return (dispatch) => {
    return accounts.accounts.get(accountId).then(result => {
      dispatch({
        type: LOAD_ACCOUNT,
        name: result.data.name,
        id: result.data.id
      });
    }).catch(() => {
      dispatch({
        type: LOAD_ACCOUNT,
        id: accountId
      });
    });
  };
}

export function loadDataSamplerMetadata() {
  const dataSampler = new TMC.services.DataSampler({apiVersion: '1-alpha'});
  return (dispatch) => {
    dispatch({
      type: LOAD_DATA_SAMPLER_LOADING,
      dataSamplerLoading: true
    });
    return dataSampler.metadata.get().then(resp => {
      dispatch({
        type: LOAD_DATA_SAMPLER,
        dataSamplerPermission: true,
        collectionStartTime: resp.data.collectionStartTime,
        deviceTypes: resp.data.deviceTypes,
        entityTypes: resp.data.entityTypes
      });
    }).catch(() => {
      dispatch({
        type: LOAD_DATA_SAMPLER,
        dataSamplerPermission: false,
      });
    }).finally(() => {
      dispatch({
        type: LOAD_DATA_SAMPLER_LOADING,
        dataSamplerLoading: false
      });
    }) ;
  };
}


export function setAccountId(accountId) {
  return { type: 'SET_ACCOUNT_ID', accountId: accountId };
}