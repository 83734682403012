import slug from '../utils/slug';


const ASIDE_CHAR = 38; // &
const NEWLINE_CHAR = 10; // \n
function codeBlockHeader(state, startLine, endLine, silent) {
  const max = state.eMarks[startLine];
  let pos = state.bMarks[startLine] + state.tShift[startLine];

  if (pos > max) { return false; }

  if (state.src.charCodeAt(pos) !== ASIDE_CHAR) { return false; }

  // move position to the last &
  while (pos < max && state.src.charCodeAt(pos) === ASIDE_CHAR) { pos++; }

  const matchStart = pos;

  while (++pos < max && state.src.charCodeAt(pos) !== NEWLINE_CHAR) {} // eslint-disable-line no-empty

  const matchEnd = pos;

  if (matchEnd - matchStart === 0) {
    return false;
  }

  const asideText = state.src.slice(matchStart, matchEnd).trim();

  if (!asideText.length) {
    return false;
  }

  if (silent) { return true; }

  state.line = startLine + 1;

  state.tokens.push({
    type: 'code_block_open',
  });

  state.tokens.push({
    type: 'inline',
    content: asideText,
    level: state.level + 1,
    children: [],
    lines: [startLine, state.line]
  });

  state.tokens.push({
    type: 'code_block_close',
  });

  return true;
}

export function aside(md) {
  md.asideHeader = md.asideHeader || {};
  md.block.ruler.after('code', 'codeBlockHeader', codeBlockHeader);
  md.renderer.rules.code_block_open = () => '<span>';
  md.renderer.rules.code_block_close = () => '</span>\n';
}

/**
 * Modified version from: https://github.com/jonschlinkert/remarkable/blob/master/lib/rules_block/heading.js
 */
function genAnchorHeading(md) {
  md.anchorLinks = new Map();
  let slugCounter = 0;
  return function heading(state, startLine, endLine, silent) {
    var ch, level, tmp,
          pos = state.bMarks[startLine] + state.tShift[startLine],
          max = state.eMarks[startLine];

      if (pos >= max) { return false; }

      ch  = state.src.charCodeAt(pos);

      if (ch !== 0x23/* # */ || pos >= max) { return false; }

      // count heading level
      level = 1;
      ch = state.src.charCodeAt(++pos);
      while (ch === 0x23/* # */ && pos < max && level <= 6) {
        level++;
        ch = state.src.charCodeAt(++pos);
      }

      if (level > 6 || (pos < max && ch !== 0x20/* space */)) { return false; }

      if (silent) { return true; }

      // Let's cut tails like '    ###  ' from the end of string

      max = state.skipCharsBack(max, 0x20, pos); // space
      tmp = state.skipCharsBack(max, 0x23, pos); // #
      if (tmp > pos && state.src.charCodeAt(tmp - 1) === 0x20/* space */) {
        max = tmp;
      }

      state.line = startLine + 1;

      state.tokens.push({ type: 'heading_open',
        hLevel: level,
        lines: [ startLine, state.line ],
        level: state.level
      });

      const content = state.src.slice(pos, max).trim();
      let sluggedContent = slug(content);
      if (md.anchorLinks.has(sluggedContent)) {
        sluggedContent += '-' + ++slugCounter;
      }
      md.anchorLinks.set(sluggedContent, {
        level,
        headerContent: content
      });

      state.tokens.push({
        type: 'heading_anchor_open',
        name: sluggedContent
      });
      state.tokens.push({ type: 'heading_anchor_close' });

      // only if header is not empty
      if (pos < max) {
        state.tokens.push({
          type: 'inline',
          content,
          level: state.level + 1,
          lines: [ startLine, state.line ],
          children: []
        });
      }
      state.tokens.push({ type: 'heading_close', hLevel: level, level: state.level });

      return true;
  };
}

export function anchorHeading(md) {
  md.anchorHeading = md.anchorHeading || {};
  md.block.ruler.at('heading', genAnchorHeading(md));
  md.renderer.rules.heading_anchor_open = (tokens, idx) => {
    const { name } = tokens[idx];
    return `<a name="${name}">`;
  };
  md.renderer.rules.heading_anchor_close = () => '</a>\n';
}
