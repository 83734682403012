export const config = {}; // expected to be populated by index.js

function genRegistry() {
  const callbacks = new Set();
  return {
    register(fn) {
      callbacks.add(fn);
    },
    unregister(fn) {
      callbacks.delete(fn);
    },
    call() {
      for (let c of callbacks) {
        c();
      }
    }
  };
}

export const markdownMountedRegistry = genRegistry();
export const markdownUnmountedRegistry = genRegistry();

export default {
  config
};
