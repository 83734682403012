import React from "react";
import AutoIntl from "@au/core/lib/components/elements/AutoIntl";
import AuDropDown from "@au/core/lib/components/elements/AuDropDown";

import styles from "../css/components/dataSampler/device_type_selector.module.scss";

export default function DeviceTypeSelector({name, deviceTypes, deviceType, setDeviceType, disabled}) {
  return <div className={styles.device_type_dropdown}>
    <AutoIntl className={styles.device_type} displayId="au.dataSampler.deviceTypeSelector.deviceType"/>
    <AuDropDown
      className={styles.device_types}
      options={deviceTypes}
      selection={deviceType}
      selectOption={(value) => {
        window.history.replaceState(null, null, `?deviceType=${value}&entityName=${name}`);
        setDeviceType(value);
      }}
      disableSort
      disabled={disabled}
    />
  </div>
}