import React from 'react';
import TMC from "@autonomic/browser-sdk";
import * as T from 'prop-types';
import AuDropDown from "@au/core/lib/components/elements/AuDropDown";
import MultiDropDown from "@au/core/lib/components/elements/MultiDropDown";
import AutoIntl from "@au/core/lib/components/elements/AutoIntl";
import AuButton from "@au/core/lib/components/elements/AuButton";
import debounce from "lodash.debounce";
import {Link} from "react-router-dom";
import {BUTTON_TYPE_PRIMARY} from "@au/core/lib/components/elements/AuButton";
import { LOOKUP_FEEDBACK_LINK,FTCP_LOOKUP_PATH, FTCP_SIGNAL_INFORMATION_PATH,
  SIGNAL_BUNDLE_PATH, TMC_EVENT_PATH, TMC_SIGNAL_PATH,
  TMC_CONFIGURATION_PATH, TMC_INDICATOR_PATH, MIN_CHARACTERS_TO_SEARCH } from "../../constants";
import LookupPageLayout from "../LookupPageLayout";

import styles from "../../css/components/lookup_page.module.scss";

const FTCP_ENTITIES = ["ftcpEvent", "ftcpSignal", "ftcpBundle"];
const TMC_ENTITIES = ["wellKnownEvents", "wellKnownSignals", "wellKnownIndicators", "wellKnownConfigurations"];
function formatEntityOptions(entityNames, availableOptions, textToBold) {
  return entityNames.map((entityName) => {
    const formattedOptions = availableOptions?.[entityName]?.map(option => {
      const replaceRegex = new RegExp(`(${textToBold})`, "gi");
      const displayString = option
        .replace(replaceRegex, "<b>$1</b>")
        .split(/<b>|<\/b>/)
        .map((fragment, index) => index % 2 === 1 ? <b key={index}>{fragment}</b> : fragment);
      return ({val: option, displayString: displayString});
    });
    return {entityName, displayId: `au.tmcLookup.${entityName}`, options: formattedOptions || []};
  });
}

const LOOKUP_ENTITIES = {
  ftcpEvent: {sdkName: "events", pathname: FTCP_LOOKUP_PATH, queryProp: "ftcpAlert"},
  ftcpSignal: {sdkName: "signals", pathname: FTCP_SIGNAL_INFORMATION_PATH, queryProp: "canSignal"},
  ftcpBundle: {sdkName: "bundle", pathname: SIGNAL_BUNDLE_PATH, queryProp: "signalBundle"},
  wellKnownEvents: {pathname: TMC_EVENT_PATH, queryProp: "tmcEvent"},
  wellKnownSignals: {pathname: TMC_SIGNAL_PATH, queryProp: "tmcSignal"},
  wellKnownIndicators: {pathname: TMC_INDICATOR_PATH, queryProp: "tmcIndicator"},
  wellKnownConfigurations: {pathname: TMC_CONFIGURATION_PATH, queryProp: "tmcConfiguration"},
};

export class LookupSearch extends React.Component {
  static propTypes = {
    locationSearch: T.string,
    entityNames: T.arrayOf(T.string),
    showVersion: T.bool,
    entityProvider: T.string,
  }

  static defaultProps = {
    locationSearch: "",
    entityNames: [],
    showVersion: true,
    entityProvider: "ftcp",
  }

  FtcpOracleService = new TMC.services.FtcpOracle({apiVersion: '1-alpha'});

  constructor(props) {
    super(props);

    const {text, selectedEntityType, versionOptions, version, isTextValid} = this.getInitialValues(props);

    this.state = {
      text: text || "",
      selectedEntityType: selectedEntityType || "",
      entityOptions: formatEntityOptions(props.entityNames),
      versionOptions: versionOptions || [],
      version: version || "",
      isTextValid: Boolean(isTextValid),
      showTextError: false,
      rerenderKey: 0
    };
  }

  getInitialValues({locationSearch, entityNames, showVersion}) {
    const params = new URLSearchParams(locationSearch);
    const entityName = entityNames.find(name => params.has(LOOKUP_ENTITIES[name].queryProp));
    if (entityName) {
      const entityDef = LOOKUP_ENTITIES[entityName];
      const entityValue = params.get(entityDef.queryProp);

      if (!showVersion) {
        return {text: entityValue, selectedEntityType: entityName, isTextValid: true};
      }

      const version = params.get('version') ?? "";
      this.fetchVersions(entityName, entityValue).then(versionOptions => {
        const versionFound = versionOptions.some(item => item.val === version);
        this.setState({text: entityValue, selectedEntityType: entityName, versionOptions, version: versionFound ? version : versionOptions[0].val});
      });
    }
    return {};
  }

  onEntitySearch(value) {
    this.setState(prevState => ({text: value, version: "", versionOptions: [], showTextError: false, isTextValid: false, rerenderKey: prevState.rerenderKey + 1}));

    if (value.length >= MIN_CHARACTERS_TO_SEARCH) {
      this.debouncedPopulateSearchOptions(value);
    } else {
      this.setState({entityOptions: []});
    }
  }

  debouncedPopulateSearchOptions = debounce(this.populateSearchOptions, 200).bind(this);
  populateSearchOptions(name) {
    const {entityNames, entityProvider} = this.props;
    this.FtcpOracleService.converter.search({name}, {type: entityProvider})
      .then(result => {
        const options = formatEntityOptions(entityNames, result.data, name).filter(({options}) => options.length);
        this.setState({entityOptions: options});
      })
      .catch(() => this.setState({entityOptions: [], showTextError: true}));
  }

  onEntitySelect(value) {
    const {showVersion} = this.props;
    const {entityName} = this.state.entityOptions.find((entityDef) => entityDef.options.some(option => option.val === value));
    if(!entityName) return;

    this.setState({text: value, selectedEntityType: entityName, entityOptions: [], isTextValid: !showVersion});
    if (showVersion) {
      this.fetchVersions(entityName, value).then(versionOptions => this.setState({versionOptions, version: versionOptions[0].val, isTextValid: true}));
    }
  }

  async fetchVersions(entityName, value) {
    return this.FtcpOracleService[LOOKUP_ENTITIES[entityName].sdkName].getFtcpVersions({name: value})
      .then((result) => result.data.items.map((item) => ({val: item, displayString: item})));
  }

  textOnClear() {
    this.setState(prevState => ({text: "", entityName: "", entityOptions: [], version: "", versionOptions: [], isTextValid: false, showTextError: false, rerenderKey: prevState.rerenderKey + 1}));
  }

  render() {
    const {entityProvider, showVersion} = this.props;
    const {version, text, versionOptions, selectedEntityType, showTextError, entityOptions, rerenderKey, isTextValid} = this.state;

    return (
      <div data-testid="ftcp-input" className={styles.search_box}>
        <AutoIntl className={styles.search_box_text} displayId={`au.tmcLookup.${entityProvider}.lookupHeader`}/>
        <div className={styles.table}>
          <div className={styles.inputContainer}>
            <AutoIntl className={styles.label} displayId={`au.tmcLookup.${entityProvider}.lookupSearch`}/>
            <MultiDropDown
              selection={text}
              options={entityOptions}
              selectOption={(value) => this.onEntitySelect(value)}
              onChange={(value) => this.onEntitySearch(value)}
              onClear={() => this.textOnClear()}
              createMode={false}
              allowTyping={true}
              placeholderId="au.tmcLookup.searchPlaceholder"
              error={{errDisplayId: "au.tmcLookup.noResults", fieldDisplayId: 'au.noop'}}
              showError={showTextError}
              optionsClassName={styles.options}
            />
          </div>
          {showVersion && <div className={styles.inputContainer}>
            <AutoIntl className={styles.label} displayId="au.tmcLookup.version"/>
            <AuDropDown
              key={`${rerenderKey}`}
              className={styles.dropdown}
              selection={text !== "" ? version : ""}
              options={versionOptions}
              selectOption={(value) => this.setState({version: value})}
              disableSort={true}
            />
          </div>}
          <Link to={location => {
            if (isTextValid) {
              const {pathname, queryProp} = LOOKUP_ENTITIES[selectedEntityType] || {};
              const searchString = showVersion ? `?${queryProp}=${text}&version=${version}` : `?${queryProp}=${text}`;
              return {
                  ...location,
                  pathname: pathname,
                  search: searchString
                };
              }
          }}>
            <AuButton className={styles.search} displayId="au.tmcLookup.search" type={BUTTON_TYPE_PRIMARY}/>
          </Link>
        </div>
      </div>
    );
  }
}

export default class TmcLookup extends React.Component {

  FtcpOracleService = new TMC.services.FtcpOracle({apiVersion: '1-alpha'});

  constructor(props) {
    super(props);

    this.state = {
      date: null
    };

    this.FtcpOracleService.converter.getDate().then((res) => this.setState({date: res?.data.date}));
  }

  render() {

    return (
      <LookupPageLayout
        bannerSrc={`${process.env.PUBLIC_URL}/tmc_lookup_banner.svg`}
        bannerAlt={'TMC Lookup Banner'}
        feedbackLink={LOOKUP_FEEDBACK_LINK}
      >
        <AutoIntl className={styles.alertNotice} displayId="au.tmcLookup.alertNotice" values={{date: this.state.date}} tag="div"/>
        <AutoIntl className={styles.indicator_text} displayId='au.tmcLookup.indicatorNotAvailable' values={{email: <a href={"mailto:devhelp@autonomic.com"} className={styles.link}>devhelp@autonomic.com</a>}} tag="div"/>
        <div className={styles.search_box_container}>
          <LookupSearch locationSearch={this.props.location.search} entityNames={FTCP_ENTITIES}/>
          <AutoIntl className={styles.indicator_text_tablet} displayId='au.tmcLookup.indicatorNotAvailable' values={{email: <a href={"mailto:devhelp@autonomic.com"} className={styles.link}>devhelp@autonomic.com</a>}} tag="div"/>
          <LookupSearch locationSearch={this.props.location.search} entityNames={TMC_ENTITIES} entityProvider="tmc" showVersion={false}/>
        </div>
        <AutoIntl className={styles.help_text} displayId={'au.tmcLookup.help'} values={{linkText: <a href={`https://developer.beta.autonomic.ai/getting-started/tmc-data-dictionary/using-the-data-dictionary`}>Learn More</a>}}/>
      </LookupPageLayout>
    );
  }
}
