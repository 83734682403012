import React from 'react';
import {Link} from "react-router-dom";
import cn from "classnames";

import TMC from "@autonomic/browser-sdk";
import AutoIntl from "@au/core/lib/components/elements/AutoIntl";
import LoadingIndicator from "@au/core/lib/components/elements/LoadingIndicator";

import {TMC_LOOKUP_PATH, FTCP_SIGNAL_INFORMATION_PATH} from "../../constants";
import Breadcrumbs from "../Breadcrumbs";
import FeedbackButton from "./LookupFeedbackButton";
import CellWithCopy from "../CellWithCopy";
import SearchResult from "./SearchResult";
import CombineMappings from "./CombineMappings";

import styles from "../../css/components/ftcp/tmc_signal.module.scss";
import tmcLookupStyles from "../../css/components/ftcp/tmc_lookup.module.scss";

const ENUMS_TABLE = "enums";
export const TAGS_TABLE = "tags";
const INDICATOR = "indicator";
const SIGNAL = "signal";

export class EnumValuesTable extends React.Component {

  constructor(props) {
    super(props);
  }

  renderHeaders() {
    const {info, type} = this.props;

    if (type === ENUMS_TABLE) {
      return (
        <>
          <div className={styles.table_header}><AutoIntl displayId={'au.ftcp.dataType.ENUM_VALUE'}/></div>
          <div className={styles.table_header}><AutoIntl displayId={'au.tmcSignal.enumValues'}/></div>
        </>
      );
    }
    else {
      return (
        <>
          <div className={styles.table_header}><AutoIntl displayId={'au.tmcSignal.tagName'} /></div>
          {info.units && <div className={styles.table_header}><AutoIntl displayId={'au.tmcSignal.tagValue'} /></div> }
          <div className={styles.table_header}><AutoIntl displayId={'au.tmcSignal.enumValues'} /></div>
        </>
      );
    }
  }

  renderRows() {
    const {info, type} = this.props;

    if (type === ENUMS_TABLE) {
      return (
        <>
          <div className={styles.table_cell} style={{gridRow: `span ${info.enumMappings.length}`}}>{info.enumValue}</div>
          {info.enumMappings.map(val => <div key={val} className={cn(styles.table_cell, styles.hideRightBorder)}>{val}</div>)}
        </>
      );
    }
    else {
      return (
        <>
          {info.signalTags.map(({name, value, enumValues = ["au.tmcSignal.noAssociatedValues"]}) =>
            <React.Fragment key={name}>
              <div className={styles.table_cell} style={{gridRow: `span ${enumValues.length}`}}>{name}</div>
              {info.units && <div className={styles.table_cell} style={{gridRow: `span ${enumValues.length}`}}>{value}</div>}
              {enumValues.map(enumVal => {
                if (enumVal === "au.tmcSignal.noAssociatedValues") {
                  return <AutoIntl key={enumVal} className={cn(styles.table_cell, styles.hideRightBorder, tmcLookupStyles.no_matching_value)} displayId="au.tmcSignal.noAssociatedValues"/>;
                }
                return <div key={enumVal} className={cn(styles.table_cell, styles.hideRightBorder)}>{enumVal}</div>;
              })}
            </React.Fragment>
          )}
        </>
      );
    }
  }

  render() {
    const {info, type} = this.props;
    let gridTemplateColumns;

    if (info) {
      if (type === TAGS_TABLE && info.units) {
        gridTemplateColumns = "1fr 1fr 1fr";
      }
      else {
        gridTemplateColumns = "1fr 1fr";
      }

      return (
        <div className={styles.table} style={{gridTemplateColumns}}>
          {this.renderHeaders()}
          {this.renderRows()}
        </div>
      );
    }
    return null;
  }
}

export class VersionListItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    };
  }

  openAlertList() {
    this.setState(prevState => ({expanded: !prevState.expanded}));
  }

  render() {
    const { signal } = this.props;
    const { expanded } = this.state;
    const signalName = signal.canSignalName || signal.ftcpSignalName;

    return (
      <div key={signal} onClick={() => this.openAlertList()}>
        <span className={expanded ? styles.collapse : styles.expand}/>
        <div className={styles.signalListItem}>{signalName}</div>
        <div className={expanded ? styles.ftcpVersionList : styles.closedVersionList}>
          {signal.ftcpVersions?.map((item) => {
            const version = "FTCP_" + item.charAt(0) + item.charAt(2) + "x";
            return (
              <Link key={item} to={`${FTCP_SIGNAL_INFORMATION_PATH}?canSignal=${signalName}&version=${version}`} className={styles.link}>
                {version}
              </Link>
            );
          })}
        </div>
      </div>
    );
  }
}

export default class TmcSignal extends React.Component {

  FtcpOracleService = new TMC.services.FtcpOracle({apiVersion: '1-alpha'});

  constructor(props) {
    super(props);

    this.state = {
      signal: undefined,
      tmcSignal: "",
      converted: false,
      version: "",
      isPopupOpen: false,
      errorMessage: "",
      error: false,
      loading: true,
      canSignalMappings: [],
      nonCanSignalMappings: [],
      type: SIGNAL,
    };
  }

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const tmcSignal = params.get('tmcSignal');
    const tmcConfiguration = params.get('tmcConfiguration');
    const tmcIndicator = params.get('tmcIndicator');

    this.setState({tmcSignal: tmcSignal || tmcIndicator || tmcConfiguration});

    if (tmcIndicator) {
      this.FtcpOracleService.indicators.getIndicator({
        name: tmcIndicator
      }).then(res => {
        if (res.data) {
          if (res.data.canSignalMappings) {
            this.setState({canSignalMappings: res.data.canSignalMappings.reduce(CombineMappings, [])});
          }
          this.setState({error: false, errorMessage: "", type: INDICATOR, signal: res.data, loading: false});
        }
      }).catch(() => this.setState({error: true, loading: false, errorMessage: tmcIndicator}));
    }
    else {
      this.FtcpOracleService.signals.getWellKnownSignal({
        name: tmcSignal || tmcConfiguration
      }).then(res => {
        if (res.data) {
          if (res.data.canSignalMappings) {
            this.setState({canSignalMappings: res.data.canSignalMappings.reduce(CombineMappings, [])});
          }
          if (res.data.nonCanSignalMappings) {
            this.setState({nonCanSignalMappings: res.data.nonCanSignalMappings.reduce(CombineMappings, [])});
          }
          this.setState({signal: res.data, loading: false});
        }
      }).catch(() => this.setState({error: true, loading: false, errorMessage: tmcSignal || tmcConfiguration}));
    }
  }

  uriAndDescription() {
    const {signal, type} = this.state;

    if (type === INDICATOR) {
      return (
        <div className={tmcLookupStyles.result_prop_container}>
          <div className={tmcLookupStyles.result_prop}>
            <AutoIntl className={tmcLookupStyles.result_prop_label} displayId='au.tmcLookup.uri'/>
            <CellWithCopy className={tmcLookupStyles.result_prop_text} uri={signal?.wellKnownSignalInfo?.signalUri}/>
          </div>
        </div>
      );
    }
    else {
      return (
        <>
          <div className={tmcLookupStyles.result_prop_container}>
            {signal.wellKnownSignalInfo?.description &&
              <div className={tmcLookupStyles.result_prop}>
                <AutoIntl className={tmcLookupStyles.result_prop_label} displayId='au.ftcpSignalInformation.signalDescription'/>
                <AutoIntl className={tmcLookupStyles.result_prop_text} displayString={signal.wellKnownSignalInfo.description}/>
              </div>
            }
            <div className={tmcLookupStyles.result_prop}>
              <AutoIntl className={tmcLookupStyles.result_prop_label} displayId='au.tmcLookup.uri'/>
              <CellWithCopy className={tmcLookupStyles.result_prop_text} uri={signal.wellKnownSignalInfo?.signalUri}/>
            </div>
            <div className={tmcLookupStyles.result_prop}>
              <AutoIntl className={tmcLookupStyles.result_prop_label} displayId='au.ftcp.dataType'/>
              <AutoIntl className={tmcLookupStyles.result_prop_text} displayId={`au.ftcp.dataType.${signal.wellKnownSignalInfo?.valueType}`}/>
            </div>
            {signal && signal.wellKnownSignalInfo?.units && 
              <div className={tmcLookupStyles.result_prop}>
                <AutoIntl displayId="au.ftcpSignalInformation.unit" className={tmcLookupStyles.result_prop_label}/>
                <AutoIntl className={tmcLookupStyles.result_prop_text} displayString={signal.wellKnownSignalInfo.units} />
              </div>
            }
          </div>
        </>
      );
    }
  }

  renderSearchResultText() {
    const {signal, type} = this.state;
    if (signal) {
      if (type === INDICATOR) {
        return 'au.tmcSignal.searchResultIndicator';
      }
      if (signal.wellKnownSignalInfo.signalType === "CONFIGURATION") {
        return 'au.tmcSignal.searchResultConfiguration';
      }
      else if (signal.canSignalMappings) {
        return 'au.tmcSignal.searchResultConverted';
      }
      else {
        return 'au.tmcSignal.searchResultNotConverted';
      }
    }
    else {
      return 'au.tmcSignal.signalNotFound';
    }
  }

  renderBreadcrumbs() {
    const {tmcSignal, type, signal} = this.state;
    let lookupDestination = `${TMC_LOOKUP_PATH}?tmcSignal=${tmcSignal}`;

    let breadcrumb;
    if (signal) {
      if (type === INDICATOR) {
        breadcrumb = 'au.tmcIndicator.header';
        lookupDestination = `${TMC_LOOKUP_PATH}?tmcIndicator=${tmcSignal}`;
      }
      else if (signal.wellKnownSignalInfo.signalType === "CONFIGURATION") {
        breadcrumb = 'au.tmcConfiguration.header';
        lookupDestination = `${TMC_LOOKUP_PATH}?tmcConfiguration=${tmcSignal}`;
      }
      else {
        breadcrumb = 'au.tmcSignal.header';
      }
    }
    else {
      breadcrumb = 'au.tmcSignal.header';
    }

    return <Breadcrumbs
      crumbs={[
        {key: "tmc-lookup", displayId: "au.tmcLookup.header", destination: lookupDestination, tag: "tmc-lookup"},
        {key: "ftcp-lookup", displayId: breadcrumb, tag: "tmc-signal"},
      ]}
    />;
  }

  render() {
    const {signal, canSignalMappings, nonCanSignalMappings, loading, error, type} = this.state;
    const searchResultText = this.renderSearchResultText();
    const indicator = Boolean(type === INDICATOR);

    if (error) {
      return (
        <div className={tmcLookupStyles.container}>
          {this.renderBreadcrumbs()}
          <FeedbackButton />
          <SearchResult><AutoIntl displayId={'au.tmcSignal.signalError'} className={tmcLookupStyles.searchResultText} values={{name: <b>{this.state.errorMessage}</b>}}/></SearchResult>
        </div>
      );
    }

    if (loading){
      return (
        <div className={tmcLookupStyles.container}>
          {this.renderBreadcrumbs()}
          <FeedbackButton />
          <LoadingIndicator displayId="au.ftcpLookup.loadingIndicator"/>
        </div>
      );
    }

    const tagsTable = Boolean(signal?.wellKnownSignalInfo?.signalTags);

    return (
      <div className={styles.container}>
        <div className={styles.lookup_container}>
          {this.renderBreadcrumbs()}
          <FeedbackButton />
          {signal && <SearchResult>
            <AutoIntl className={tmcLookupStyles.searchResultText} displayId={searchResultText} values={{signalName: <b>{indicator ? signal.wellKnownIndicator.indicatorName : signal.wellKnownSignalInfo.signalName}</b>, type: <b>{indicator ? "INDICATOR_LIGHT" : signal.wellKnownSignalInfo.signalType}</b>}} />
            <div>
              <div>
                <div className={styles.signal_list}>
                  {canSignalMappings.map((signal) =>
                    <VersionListItem key={signal.canSignalName + signal.ftcpVersions} signal={signal}/>
                  )}
                  {nonCanSignalMappings.map((signal) =>
                    <VersionListItem key={signal.ftcpSignalName + signal.ftcpVersion} signal={signal}/>
                  )}
                </div>
              </div>
            </div>
            {this.uriAndDescription()}
          </SearchResult>}
          {signal && !indicator && signal.wellKnownSignalInfo?.enumMappings && signal.wellKnownSignalInfo?.enumMappings?.length !== 0 && <><AutoIntl className={styles.table_title} displayId={'au.tmcSignal.enumsTableTitle'}/><EnumValuesTable info={signal.wellKnownSignalInfo} type={ENUMS_TABLE}/></> }
          { !indicator && <AutoIntl className={styles.table_title} displayId={'au.tmcSignal.tagsTableTitle'}/>}
          {signal && !indicator && !tagsTable && <AutoIntl displayId={'au.tmcSignal.noAssociatedTags'} values={{signal: signal.wellKnownSignalInfo.signalName}} className={styles.no_tags_text}/>}
          {signal && tagsTable && <EnumValuesTable info={signal.wellKnownSignalInfo} type={TAGS_TABLE}/>}
        </div>
      </div>
    );
  }
}
