import React from "react";
import * as T from 'prop-types';

import TMC from "@autonomic/browser-sdk";
import AuButton from "@au/core/lib/components/elements/AuButton";
import { BUTTON_TYPE_PRIMARY } from "@au/core/lib/components/elements/AuButton";
import AutoIntl from "@au/core/lib/components/elements/AutoIntl";
import { JsonData } from "@au/core/lib/components/objects";

import styles from "../../css/components/dataSampler/sample_viewer.module.scss";

export default class SampleViewer extends React.Component {
  
  static propTypes = {
    uri: T.string,
    sampleId: T.string,
    drawerTitle: T.string.isRequired,
    onDataChange: T.func.isRequired,
  }
  
  DataSamplerService = new TMC.services.DataSampler({apiVersion: '1-alpha'});
  
  fetchEntitySample = this.fetchEntitySample.bind(this);
  fetchEntitySample() {
    const { onDataChange, drawerTitle, sampleId, uri } = this.props;
    let viewerData = [];
    if(uri) {
      viewerData = [
        {
          headerId: "au.resultsDrawer.uri",
          data: uri,
          children: <JsonData data={`URI: ${uri}`}/>
        },
      ];
    }

    onDataChange({isDrawerOpen: true, sampleLoading: true, drawerTitle, viewerData});

    this.DataSamplerService.entitySample.get(sampleId).then((resp) => {
      // The 2 second delay was a requirement for the story to add a loading spinner
      setTimeout(() => {
        viewerData = [...viewerData,  {headerId: "au.resultsDrawer.dataSample", data: resp.data.entitySample, defaultExpanded: true, rowContentsClassName: styles.collapseTableContents}];
        onDataChange({isDrawerOpen: true, sampleLoading: false, drawerTitle, viewerData});
      }, 2000);
    }).catch(() => {
      onDataChange({sampleLoading: false, drawerTitle, showError: true});
    });
  }

  render() {
    const { sampleId } = this.props;
    if(!sampleId){
      return <AutoIntl className={styles.no_sample} displayId="au.dataSampler.noSample" tag="div" />;
    }
  
    return <AuButton className={styles.sampleButton} type={BUTTON_TYPE_PRIMARY} onClick={this.fetchEntitySample}>
      <AutoIntl className={styles.sampleText} displayId="au.dataSampler.eventDetails.sampleButton" tag="div"/>
    </AuButton>;
  }
}
