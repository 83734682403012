import React from 'react';
import { Router } from 'react-router-dom';

import history from './history';
import IntlProvider from './containers/IntlProvider';
import Provider from './containers/Provider';
import App from './containers/App';

import GenericErrorBoundary from '@au/core/lib/components/elements/GenericErrorBoundary';
import ScreenWidthProvider from '@au/core/lib/components/elements/ScreenWidthProvider';


export default function Root() {
  return (
    <Provider>
      <IntlProvider>
        <Router history={history}>
          <GenericErrorBoundary>
            <ScreenWidthProvider>
              <App />
            </ScreenWidthProvider>
          </GenericErrorBoundary>
        </Router>
      </IntlProvider>
    </Provider>
  );
}
