import React, { Component } from 'react';
import { OrderedMap, Map as imMap } from 'immutable';

import Section from './Section';
import FileDrop from './FileDrop';
import { NOOP } from '../constants';

export default class DevPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doc: false
    };
  }

  componentDidMount() {
    window.addEventListener('dragover', this.handleDragOver, false);
    window.addEventListener('drop', this.handleDrop, false);
  }

  componentWillUnmount() {
    window.removeEventListener('dragover', this.handleDragOver, false);
    window.removeEventListener('drop', this.handleDrop, false);
  }

  handleDragOver = this.handleDragOver.bind(this);
  handleDragOver(ev) {
    ev.stopPropagation();
    ev.preventDefault();
  }

  handleDrop = this.handleDrop.bind(this);
  handleDrop(ev) {
    ev.stopPropagation();
    ev.preventDefault();

    let file = ev.dataTransfer.files[0];
    var reader = new FileReader();
    reader.onload = (e) => {
      this.setState({ doc: e.target.result });
    };

    reader.readAsText(file);
    return false;
  }

  render() {
    const { doc } = this.state;

    if (!doc) {
      return (
        <FileDrop />
      );
    }

    const section = OrderedMap({
      Doc: imMap({
        _doc: doc,
        _path: '/dev-preview.md'
      })
    });

    return (
      <Section section={section} match={{}} downloadDoc={NOOP} />
    );
  }
}
