import React from "react";

import AutoIntl from "@au/core/lib/components/elements/AutoIntl";

import ftcpStyles from "../../css/components/ftcp/tmc_lookup.module.scss";

export default function SearchResult({children}) {
  return <div className={ftcpStyles.searchResults}>
    <AutoIntl className={ftcpStyles.searchResult} displayId="au.ftcp.searchResult" tag="div"/>
    {children}
  </div>;
}
