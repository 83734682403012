import { immutableSelectorCreator, genKeyedSelector } from '@au/core/lib/selectors/general';

import { parseMarkdown } from '../markdown';
import { language as chosenLang } from './intl';
import { buildOverlayLanguageToc, getFilePathToTocPath, Toc } from './toc';
import { TOC_MAPPINGS_KEY, PATH_TOC_KEY, DOC_CONTENT_KEY } from '../constants';

// TODO: get toc mappings from the Toc class instance rather than embedding the
// mappings in each doc
function genParseMarkdownSelector() {
  return immutableSelectorCreator(
    [entry => entry.get(DOC_CONTENT_KEY), entry => entry.get(PATH_TOC_KEY), entry => entry.get(TOC_MAPPINGS_KEY)],
    (doc, path, tocLocalLinkMappings) => {
      if (doc && path) {
        return parseMarkdown(doc, path, tocLocalLinkMappings);
      }
      return {};
    }
  );
}

export const getLocaleToc = immutableSelectorCreator(
  [ toc => toc, () => chosenLang ],
  (toc, chosenLang) => buildOverlayLanguageToc(toc, chosenLang)
);

export const getFilePathMap = immutableSelectorCreator(
  [ localeToc => localeToc ],
  localeToc => getFilePathToTocPath(localeToc)
);

export const keyedParseMarkdownSelector = genKeyedSelector(genParseMarkdownSelector, entry => entry.get(PATH_TOC_KEY));

class MemoizedToc extends Toc {
  static overlayLanguage = getLocaleToc;
  static annotatePaths = immutableSelectorCreator(
    [ toc => toc ],
    toc => Toc.annotatePaths(toc)
  );
  static sortToc = immutableSelectorCreator(
    [ toc => toc ],
    toc => Toc.sortToc(toc)
  );
  static buildLookups = immutableSelectorCreator(
    [ toc => toc ],
    toc => Toc.buildLookups(toc)
  );
}

export const getToc = immutableSelectorCreator(
  [ state => state.get('rawToc'), () => chosenLang, state => state.get('docs') ],
  (rawToc, chosenLang, docs) => rawToc && new MemoizedToc(rawToc, chosenLang, docs)
);
