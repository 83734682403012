import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as T from 'prop-types';
import IPT from 'react-immutable-proptypes';
import moment from 'moment-timezone';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import slug from '../utils/slug';
import cn from 'classnames';
import throttle from 'lodash/throttle';
import shared from '../shared';

import AuAnalytics from '@au/core/lib/utils/AuAnalytics';
import { fetchBlob } from '@au/core/lib/network/fetch';
import IconButton from '@au/core/lib/components/elements/IconButton';

import {
  SWAGGER_PATH, TITLE_DOC_KEY, DOC_DATA_ID, DOC_LAST_MODIFIED_KEY
} from '../constants';
import { cleanUrl } from '../utils/url';
import { keyedParseMarkdownSelector } from '../utils/selectors';
import MarkdownRenderer from './MarkdownRenderer';
import SubscriptionButton from './SubscriptionButton';

import styles from '../css/components/doc.module.scss';

const tz = moment.tz.guess();

const mobileBreakpoint = 418;
const tabletBreakpoint = 925;
class Doc extends Component {
  static propTypes = {
    entry: IPT.map.isRequired,
    downloadPrefix: T.string.isRequired,
    swaggerRef: T.string
  }

  state = { mobile: false, tablet: false }

  componentDidMount() {
    window.addEventListener('resize', this.checkWidth, false);
  }

  componentDidUpdate() {
    this.checkWidth();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkWidth, false);
  }

  checkWidth = throttle(this.checkWidth, 200).bind(this);
  checkWidth() {
    if (this.docRef) {
      let currentWidth = this.docRef.getBoundingClientRect().width;
      const { mobile, tablet } = this.state;

      if (currentWidth <= mobileBreakpoint && !mobile) {
        this.setState({ mobile: true  });
      } if (currentWidth > mobileBreakpoint && mobile) {
        this.setState({ mobile: false });
      } if (currentWidth > mobileBreakpoint && currentWidth <= tabletBreakpoint && !tablet) {
        this.setState({ tablet: true });
      } else if ( currentWidth > tabletBreakpoint && tablet) {
        this.setState({ tablet: false });
      }
    }
  }

  downloadMarkdownZip = this.downloadMarkdownZip.bind(this)
  async downloadMarkdownZip(entry) {
    const { downloadPrefix } = this.props;
    const parsed = keyedParseMarkdownSelector.run(entry);
    const mdFilename = slug(entry.get(TITLE_DOC_KEY));
    const filename = `${downloadPrefix}_${mdFilename}`;

    await parsed.ready;

    const blobPromises = [];
    for (let img of Array.from(parsed.node.querySelectorAll('img'))) {
      const prom = fetchBlob(img.src)
        .then(blob => ({
          blob,
          filename: img.originalSrc.split('/').pop()
        }));
      blobPromises.push(prom);
    }

    AuAnalytics.trackEvent({
      category: 'Document',
      action: 'Download - MD',
      label: entry.get('_path')
    });

    if (blobPromises.length) {
      const zip = new JSZip();
      zip.file(mdFilename + '.md', parsed.markdown);

      Promise.all(blobPromises)
        .then(imgBlobs => {
          for (let imgBlob of imgBlobs) {
            zip.file(imgBlob.filename, imgBlob.blob);
          }
          return zip.generateAsync({ type: 'blob' });
        })
        .then(zipBlob => saveAs(zipBlob, filename + '.zip'));
    }
    else {
      const mdFile = new Blob([parsed.markdown], { type: 'text/plain;charset=utf-8' });
      saveAs(mdFile, filename + '.md');
    }
  }

  goToSwaggerUI = this.goToSwaggerUI.bind(this);
  goToSwaggerUI() {
    const { swaggerRef } = this.props;
    window.open(cleanUrl(`${SWAGGER_PATH}/${swaggerRef}`), '_blank');
  }

  renderLastModified(entry) {
    const lastModified = entry.get(DOC_LAST_MODIFIED_KEY);
    const lastModifiedFormat = this.props.intl.formatMessage({ id: 'au.doc.lastModifiedFormat' });

    return (
      <div className={styles.lastmodified}>
        <FormattedMessage id="au.doc.lastModified" />
        <span className={styles.delimiter}>{" : "}</span>
        {moment.tz(new Date(lastModified), tz).format(lastModifiedFormat)}
      </div>
    );
  }

  renderDownloadButton(entry) {
    return (
      <div data-tid="Doc-hatDownload">
        <IconButton className={styles.markdown} labelClassName={styles.label} displayId='au.doc.downloadAs.markdown' onClick={() => this.downloadMarkdownZip(entry)}/>
      </div>
    );
  }

  renderSubscriptionButton(shared) {
    if (shared.config.hubspotAccountId) {
      return (
        <div className={styles.subscription}>
          <SubscriptionButton {...this.state}/>
        </div>
      );
    }
    return false;
  }

  renderSwaggerButton() {

    if (!this.props.swaggerRef) {
      return false;
    }
    return (
      <IconButton displayId="au.doc.swagger" className={styles.swagger} labelClassName={styles.label} onClick={this.goToSwaggerUI}/>
    );
  }

  setRef = this.setRef.bind(this);
  setRef(ref) {
    if (ref) {
      this.docRef = ref;
    }
  }

  renderHat(entry) {
    const { mobile, tablet } = this.state;
    const parsed = keyedParseMarkdownSelector.run(entry);

    if (!parsed.isReady) {
      return <div className={styles.skeleton_container}>
        <div className={styles.skeleton_buttons}>
          <div className={styles.skeleton_one}></div>
          <div className={styles.skeleton_two}></div>
        </div>
        <div className={styles.skeleton_three}></div>
      </div>;
    }

    return (
      <div className={cn(styles.hat, {[styles.mobile]: mobile, [styles.tablet]: tablet})}>
        <div className={styles.buttons}>
          {this.renderDownloadButton(entry)}
          {this.renderSwaggerButton()}
          {this.renderSubscriptionButton(shared)}
        </div>
        {this.renderLastModified(entry)}
      </div>
    );
  }

  render() {
    const { entry } = this.props;

    return (
      <section data-id={DOC_DATA_ID} className={styles.doc} ref={this.setRef}>
        {this.renderHat(entry)}
        <MarkdownRenderer parsed={keyedParseMarkdownSelector.run(entry)} {...this.state}/>
      </section>
    );
  }
}

export default injectIntl(Doc);
