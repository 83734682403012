let [responses, entries] = [{}, {}];

if (process.env.NODE_ENV === 'development') {
  // use `require()` in this conditional block to ensure these are not added to
  // production builds
  const accountsAdmin = require('./accountsAdmin.json');
  const members = require('./members.json');

  responses = {
    'Members': members,
    'Accounts Admin': accountsAdmin,
    'IAM V2': transporter => transporter.get('/v2/iam/api-docs')
  };
}

Object.keys(responses).forEach(k => entries[k] = k);

export { responses, entries };
