import React from 'react';

import AuPlate from '@au/core/lib/components/elements/AuPlate';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';

import styles from '../css/components/notfound.module.scss';

const NotFound = () => (
  <div className={styles.container}>
    <AuPlate
      className={styles.plate}
      titleDisplayId="au.notFound.pageNotFound"
      contentDisplay="404"
    />
    <AutoIntl
      className={styles.msg}
      displayId="au.notFound.couldNotFindPage"
      tag="div"
    />
  </div>
);

export default NotFound;
