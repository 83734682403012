import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import AuAnalytics from '@au/core/lib/utils/AuAnalytics';

import { zoomToHash } from '../utils/dom';

import styles from '../css/components/toc.module.scss';

export default class TocLink extends PureComponent {

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate(prevProps) {
    //locates the specific nav that we are using to navigate the Toc scrolling feature
    if (this.mounted && !prevProps.viewing && this.props.viewing && this.el) {
      this.el.dispatchEvent(new CustomEvent('startViewing', { bubbles:true }));
    }
  }

  render() {
    const { level, name, viewing, url, children, onClick: parentOnClick } = this.props;

    const levelClass = styles['lvl-'+level];

    const viewingAnchor = {
      [styles.viewinganchor]: viewing
    };

    const onClick = () => {
      AuAnalytics.trackEvent({
        category: 'TocLink',
        action: 'click',
        label: `${url}#${name}`
      });
      zoomToHash('#'+name);
      if(parentOnClick){
        parentOnClick(name);
      }
    };

    return (
      <Link
        innerRef={el=>this.el=el}
        key={name}
        className={cn(styles.anchorLink, levelClass, viewingAnchor)}
        to={`${url}#${name}`}
        onClick={onClick}
      >
        {children}
      </Link>
    );
  }
}
