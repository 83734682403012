import React from 'react';
import * as T from 'prop-types';
import { connect } from 'react-redux';

import { Filter } from "@au/core/lib/components/elements/filters/Filter";
import { SelectableListProps } from "@au/core/lib/components/elements/SelectableList";
import FilterSelectableList from "@au/core/lib/components/elements/filters/FilterSelectableList";

import {NOOP} from '../../../constants';

class DeviceType extends React.Component {

  static propTypes = {
    defaultValue: SelectableListProps.defaultValue,
    containerRef: SelectableListProps.containerRef,
    selection: T.arrayOf(Filter),
    items: T.arrayOf(T.object),
    sort: SelectableListProps.sort,
    multiSelect: T.bool,
    onChange: T.func,
    onInit: T.func
  };

  static defaultProps = {
    onChange: NOOP,
    onInit: NOOP,
    selection: []
  };

  render() {
    return (
      <React.Fragment>
        {this.props.items && <FilterSelectableList {...this.props} />}
      </React.Fragment>
    );
  }
}

export default connect(
  ({ app: state }) => {
    const { deviceTypes } = state.get('dataSampler');
    return {
      items: deviceTypes.map((device) => ({value: device.value, displayString: device.displayName})),
    };
  })(DeviceType);
