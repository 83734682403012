import React from "react";
import cn from "classnames";
import {Link} from "react-router-dom";

import AutoIntl from "@au/core/lib/components/elements/AutoIntl";

import CellWithCopy from "../CellWithCopy";
import {FTCP_SIGNAL_INFORMATION_PATH, TMC_SIGNAL_PATH} from "../../constants";

import styles from "../../css/components/ftcp/signal_mapping_table.module.scss";

export const currentlyNotSupportedOnTMC = "au.signalMappingTable.notSupportedOnTMC";
const DEFAULT_HEADERS = [{displayId: "au.signalMappingTable.messageName"}, {displayId: "au.signalMappingTable.canSignal"}, {displayId: "au.signalMappingTable.tmcSignal", showInfoDot: true}, {displayId: "au.signalMappingTable.description"}];
const DEFAULT_VALUE_HEADER = {displayId: "au.signalMappingTable.defaultValue"};
const EVENT_SIGNAL_HEADERS = [{displayId: "au.signalMappingTable.tmcSignals"}, {displayId: "au.signalMappingTable.ftcpSignal"}, {displayId: "au.signalMappingTable.description"}, {displayId: "au.signalMappingTable.ftcpVersions"}];
export const EVENT_SIGNAL_TABLE = "event_signal";

export function SignalMappingTable({messages, version, defaultValueDropdown, openPopup, entityType}) {
  let gridTemplateColumns = defaultValueDropdown ? "1fr 1fr 1fr 1fr 1fr" : "1fr 1fr 1fr 1fr";

  if (entityType === EVENT_SIGNAL_TABLE) {

    const rows = messages.map((message, index) => <TmcEventRow key={index} entityType={entityType} uri={message.wellKnownSignalInfo.signalUri} name={message.wellKnownSignalInfo.signalName} signals={message.canSignalMappings} version={message.wellKnownSignalInfo.version} defaultValueDropdown={defaultValueDropdown}/>);

    return (
      <div className={styles.grid_body} style={{gridTemplateColumns}}>
        <Headers entityType={entityType} openPopup={openPopup} showDefaultValue={false}/>
        {rows}
      </div>
    );
  }

  return (
    <div className={styles.grid_body} style={{gridTemplateColumns}}>
      <Headers entityType={entityType} openPopup={openPopup} showDefaultValue={Boolean(defaultValueDropdown)}/>
      {Array.from(messages).map(([name, signals]) => <Row
        key={name}
        name={name}
        signals={signals}
        version={version}
        defaultValueDropdown={defaultValueDropdown}
      />
      )}
    </div>
  );
}

function Headers({openPopup, showDefaultValue, entityType}) {
  let headers;
  if (entityType === EVENT_SIGNAL_TABLE) {
    headers = [...EVENT_SIGNAL_HEADERS];
  }
  else {
    headers = [...DEFAULT_HEADERS];
  }
  if (showDefaultValue) {
    headers.splice(3, 0, DEFAULT_VALUE_HEADER);
  }

  return headers.map(header => (
    <div key={header.displayId} className={styles.grid_header}>
      <AutoIntl displayId={header.displayId}/>
      {header.showInfoDot && openPopup && <span className={styles.info_dot} data-testid="tmc-signal-info" onClick={openPopup}/>}
    </div>
  ));
}

function Row({name, signals, version, defaultValueDropdown}) {
  const textWrappedMessageName = name.split(".").map((nameFragment, index, {length}) => <div
    key={index} className={styles.message_name}
  >{nameFragment + (index !== length - 1 ? "." : "")}</div>);
  const canSignalsCount = signals.flatMap(signal => signal.canSignals).length;


  return (
    <>
      <div className={cn(styles.grid_cell)} style={{gridRow: `span ${canSignalsCount}`}}>
        <CellWithCopy className={styles.sticky} data={name}>
          {textWrappedMessageName}
        </CellWithCopy>
      </div>
      {signals.map(signal => <SubRow key={signal.tmcSignal} signal={signal} version={version} defaultValueDropdown={defaultValueDropdown}/>)}
    </>
  );
}

function TmcEventRow({name, signals, uri}) {
  const textWrappedMessageName = name.split(".").map((nameFragment, index, {length}) => <div
    key={index} className={styles.message_name}
  >{nameFragment + (index !== length - 1 ? "." : "")}</div>);
  const canSignalsCount = signals?.flatMap(signal => signal.canSignals).length || 0;

  return (
    <>
      <div className={cn(styles.grid_cell)} style={{gridRow: `span ${canSignalsCount}`}}>
        <div className={styles.sticky}>
          <Link to={`${TMC_SIGNAL_PATH}?tmcSignal=${name}`} className={styles.link}>
            {textWrappedMessageName}
          </Link>
          <CellWithCopy className={styles.uri} uri={uri} uriCopyDisplayId="au.copyUri" />
        </div>
      </div>
      {signals && signals.map(signal => <TmcEventSubRow key={signal.ftcpVersion + signal.canSignalName} signal={signal} />)}
      {canSignalsCount === 0 &&
        <>
          <div className={cn(styles.grid_cell, styles.not_supported)}><AutoIntl displayId={'au.tmcEvent.notConverted'}/></div>
          <div className={styles.grid_cell}>{"-"}</div>
          <div className={cn(styles.grid_cell, styles.hideRightBorder)}></div>
        </>
      }
    </>
  );
}

function TmcEventSubRow({signal}) {
  return (
    <React.Fragment>
      <div className={styles.grid_cell}>
        <div>{signal.canSignalName}</div>
      </div>
      <div className={cn(styles.grid_cell, styles.description)}>
        {signal.canSignalDescription ?? "-"}
      </div>
      <div className={cn(styles.grid_cell, styles.hideRightBorder)}>
        {signal.ftcpVersions.map(versionRaw => {
          const version = "FTCP_" + versionRaw.charAt(0) + versionRaw.charAt(2) + "x";
          return (
            <Link key={versionRaw} to={`${FTCP_SIGNAL_INFORMATION_PATH}?canSignal=${signal.canSignalName}&version=${version}`} className={styles.link}>
              {versionRaw}
            </Link>
          );
        })}
      </div>
    </React.Fragment>
  );
}

function SubRow({signal, version, defaultValueDropdown}) {

  return signal.canSignals.map((canSignal, index) => <React.Fragment key={signal.tmcSignal + canSignal.name}>
    <CanSignalLink canSignal={canSignal} version={version}/>
    <TmcSignalCell signal={signal} index={index}/>
    {defaultValueDropdown && <div className={styles.grid_cell}>
      {defaultValueDropdown(canSignal, signal)}
    </div>}
    <div className={cn(styles.grid_cell, styles.description, styles.hideRightBorder)}>
      {canSignal.description ?? "-"}
    </div>
  </React.Fragment>);
}

function TmcSignalCell({signal, index}){
  if(index === 0){
    const isNotSupported = signal?.tmcSignal === currentlyNotSupportedOnTMC;
    if(signal?.tmcSignal == null || isNotSupported){
      return <div className={cn(styles.grid_cell, styles.no_overflow, styles.not_supported)} style={{gridRow: `span ${signal.canSignals.length}`}}>
        <AutoIntl displayString={signal?.tmcSignal ?? "-"} displayId={isNotSupported ? signal.tmcSignal : undefined}/>
      </div>;
    }

    return <div
      className={cn(styles.grid_cell, styles.no_overflow)}
      style={{gridRow: `span ${signal.canSignals.length}`}}
    >
      <CellWithCopy data={signal.tmcSignal} uri={signal.tmcSignalUri} uriCopyDisplayId="au.copyUri">
        <Link to={`${TMC_SIGNAL_PATH}?tmcSignal=${signal.tmcSignal}`} className={styles.link}>
          {signal.tmcSignal}
        </Link>
      </CellWithCopy>
    </div>;
  }

  return <React.Fragment/>;
}

function CanSignalLink({canSignal, version}) {
  if (canSignal?.name === undefined) {
    return <div className={styles.grid_cell}>-</div>;
  }

  return (
    <CellWithCopy className={cn(styles.grid_cell, styles.no_overflow)} data={canSignal.name}>
      <Link to={`${FTCP_SIGNAL_INFORMATION_PATH}?canSignal=${canSignal.name}&version=${version}`} className={styles.link}>
        {canSignal.name}
      </Link>
    </CellWithCopy>
  );
}
