import React from 'react';
import cn from "classnames";
import * as T from "prop-types";

import TMC from "@autonomic/browser-sdk";
import AutoIntl from "@au/core/lib/components/elements/AutoIntl";
import LoadingIndicator from "@au/core/lib/components/elements/LoadingIndicator";

import { TMC_LOOKUP_PATH } from "../../constants";
import Breadcrumbs from "../Breadcrumbs";
import FeedbackButton from "./LookupFeedbackButton";
import SearchResult from "./SearchResult";
import { currentlyNotSupportedOnTMC, SignalMappingTable } from "./SignalMappingTable";
import { UNAVAILABLE_ID } from "../CellWithCopy";

import styles from "../../css/components/ftcp/ftcp_lookup.module.scss";
import tmcLookupStyles from "../../css/components/ftcp/tmc_lookup.module.scss";

export default class SignalBundle extends React.Component {

  FtcpOracleService = new TMC.services.FtcpOracle({apiVersion: '1-alpha'});

  constructor(props) {
    super(props);

    this.state = {
      bundle: undefined,
      signalBundle: "",
      version: "",
      error: false,
      loading: true
    };
  }

  static propTypes = {
    location: T.object.isRequired,
  }

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const signalBundle = params.get('signalBundle');
    const version = params.get('version');

    this.setState({signalBundle: signalBundle, version: version});

    this.FtcpOracleService.bundle.getCanSignalBundle({
      name: signalBundle,
      ftcpVersion: version
    }).then(res => {
      if (res.data) {
        this.setState({bundle: this.processFtcpEvent(res.data), version, loading: false});
      }
    }).catch(() => this.setState({error: true, loading: false}));
  }

  processFtcpEvent(event) {
    const messages = new Map();
    const allMappings = (event.canSignalMappings ?? []).concat(event.nonCanSignalMappings ?? []);

    allMappings.forEach(mapping => {
      const canSignals = [{name: mapping.canSignalName || mapping.ftcpSignalName, description: mapping.canSignalDescription, defaultValue: mapping.defaultValue, enumMappings: mapping.enumMappings}];
      const tmcSignal = mapping.wellKnownSignalInfos?.length ? mapping.wellKnownSignalInfos[0].signalName : currentlyNotSupportedOnTMC;
      const tmcSignalUri = mapping.wellKnownSignalInfos?.length ? mapping.wellKnownSignalInfos[0].signalUri : UNAVAILABLE_ID;
      const message = messages.get(mapping.containingMessage) ?? [];

      const signalIdx = message.findIndex(signal => signal.tmcSignal === tmcSignal);
      const newIdx = signalIdx === -1 ? message.length : signalIdx;
      message[newIdx] = {canSignals: (message[newIdx]?.canSignals ?? []).concat(canSignals), tmcSignal, tmcSignalUri};
      messages.set(mapping.containingMessage, message);
    });

    return {messages};
  }

  renderBreadcrumbs() {
    const {version, signalBundle} = this.state;
    const lookupDestination = `${TMC_LOOKUP_PATH}?signalBundle=${signalBundle}&version=${version}`;

    return <Breadcrumbs
      crumbs={[
        {key: "tmc-lookup", displayId: "au.tmcLookup.header", destination: lookupDestination, tag: "tmc-lookup"},
        {key: "signal-bundle", displayId: "au.signalBundle.header", tag: "signal-bundle"},
      ]}
    />;
  }

  render() {
    const {
      version,
      signalBundle,
      bundle,
      error,
      loading,
    } = this.state;

    if (error) {
      return (
        <div className={tmcLookupStyles.container}>
          {this.renderBreadcrumbs()}
          <FeedbackButton />
          <SearchResult />
        </div>
      );
    }

    if(loading){
      return (
        <div className={tmcLookupStyles.container}>
          {this.renderBreadcrumbs()}
          <FeedbackButton />
          <LoadingIndicator displayId="au.ftcpLookup.loadingIndicator"/>
        </div>
      );
    }

    return <div className={styles.container}>
      <div className={cn(styles.lookup_container)}>
        {this.renderBreadcrumbs()}
        <FeedbackButton />
        {bundle && <SearchResult>
          <AutoIntl displayId="au.signalBundle.searchResult" values={{signalBundle, b: chunks => <b>{chunks}</b>}}/>
        </SearchResult>}
        <AutoIntl className={styles.title} displayId="au.signalBundle.tableHeader" tag="h6"/>
        {bundle?.messages && <SignalMappingTable messages={bundle.messages} version={version}/>}
      </div>
    </div>;
  }
}