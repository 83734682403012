import React from "react";
import cn from "classnames";

import AutoIntl from "@au/core/lib/components/elements/AutoIntl";

import SampleViewer from "./SampleViewer";
import CellWithCopy, {UNAVAILABLE_ID} from "../CellWithCopy";

import styles from "../../css/components/dataSampler/entity_result.module.scss";

export default function EntityResult({entity, showUri=true, showDataTypeUnit, handleSampleDataChange}){
  return <div className={styles.searchResults}>
    <div className={styles.result}>
      <div className={cn(styles.name, {[styles.no_uri]: !showUri})}>{entity.entityName}</div>
      <SampleViewer
        uri={entity.uri}
        drawerTitle={entity.entityName}
        sampleId={entity.sampleId}
        onDataChange={handleSampleDataChange}
      />
    </div>
    {showUri && (
      <div className={styles.container}>
        <div className={styles.labels}>
          {entity.description &&
            <AutoIntl className={styles.label} displayId="au.dataSampler.entityResult.description"/>
          }
          <AutoIntl className={styles.label} displayId="au.dataSampler.entityResult.uri"/>
        </div>
        <div className={styles.values}>
          {entity.description && <div>{entity.description}</div>}
          <CellWithCopy uri={entity.uri ?? UNAVAILABLE_ID}/>
        </div>
      </div>
    )}
    {showDataTypeUnit && (
      <div className={styles.container}>
        <div className={styles.labels}>
          <AutoIntl className={styles.label} displayId="au.dataSampler.entityResult.dataType"/>
          {entity.unit && <AutoIntl className={styles.label} displayId="au.dataSampler.entityResult.unit"/>}
        </div>
        <div className={styles.values}>
          <div>{entity.dataType}</div>
          {entity.unit && <div>{entity.unit}</div>}
        </div>
      </div>
    )}
  </div>;
}