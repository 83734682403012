import React from "react";
import TMC from "@autonomic/browser-sdk";

import AutoIntl from "@au/core/lib/components/elements/AutoIntl";
import ResultsDrawer from "@au/core/lib/components/elements/ResultsDrawer";
import LoadingIndicator from "@au/core/lib/components/elements/LoadingIndicator";
import { createResponseAlertMessage } from '@au/core/lib/components/objects/AlertMessage';

import EntityResult from "./EntityResult";
import SamplerBreadCrumbs from "./SamplerBreadCrumbs";
import DeviceTypeSelector from "../DeviceTypeSelector";
import {dataSamplerConnect} from "../../reducers";
import {DATA_SAMPLER_ENTITY_TYPES} from "../../constants";

import styles from "../../css/components/dataSampler/entity_details.module.scss";

export class EntityDetails extends React.Component {

  entityEndpoint = new TMC.services.DataSampler({apiVersion: '1-alpha'}).entity

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      expanded: true,

      isDrawerOpen: false,
      sampleLoading: false,
      viewerData: null,
      drawerTitle: '',
    };
  }

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const deviceType = params.get('deviceType') ?? this.props.deviceTypes[0].val;
    const entityName = params.get('entityName') ?? undefined;

    this.setState({deviceType, entityName}, () => this.getEntity());
  }

  getEntity() {
    const {entityType} = this.props;
    const {deviceType, entityName} = this.state;

    this.setState({loading: true});
    this.entityEndpoint.get({deviceType, entityName, entityType})
      .then((res) => {
        this.setState({entity: res.data, error: false});
      })
      .catch((res) => {
        if(res?.data?.error === 'not found') {
          this.setState({entity: {entityName}, error: false});
        } else {
          this.setState({error: true});
          createResponseAlertMessage(res);
        }
      })
      .finally(() => this.setState({loading: false}));
  }

  closeSampleViewer = this.closeSampleViewer.bind(this);
  closeSampleViewer() {
    this.setState({isDrawerOpen: false})
  }

  handleSampleDataChange = this.handleSampleDataChange.bind(this);
  handleSampleDataChange({isDrawerOpen, drawerTitle, viewerData, sampleLoading, showError}) {
    this.setState({isDrawerOpen, drawerTitle, viewerData, sampleLoading, error:showError });
  }

  DeviceTypeSelector() {
    return <DeviceTypeSelector
      name={this.state.entityName}
      deviceType={this.state.deviceType}
      deviceTypes={this.props.deviceTypes}
      setDeviceType={(value) => this.setState({deviceType: value}, () => this.getEntity())}
      disabled={this.state.isDrawerOpen}
    />;
  }

  EntityResult() {
    const {entity} = this.state;
    const {entityType} = this.props;

    return <EntityResult
      entity={entity}
      showDataTypeUnit={entityType === DATA_SAMPLER_ENTITY_TYPES.CONFIGURATION}
      handleSampleDataChange={this.handleSampleDataChange}
    />
  }

  AdditionalDetails() {
    return null;
  }

  render() {
    const {loading, error, drawerTitle, isDrawerOpen, viewerData, sampleLoading, deviceType} = this.state;
    const {entityType} = this.props;

    return <div className={styles.container}>
      <div className={styles.details_container}>
        <SamplerBreadCrumbs deviceType={deviceType} parentEntityType={entityType} entityType={entityType} />
        {this.DeviceTypeSelector()}
        {loading && <LoadingIndicator displayId="au.loadingIndicator" className={styles.loading_indicator}/>}
        {error && <AutoIntl displayId="au.apiRequest.error" tag="div"/>}
        {!loading && !error &&
          <React.Fragment>
            {this.EntityResult()}
            {this.AdditionalDetails()}
          </React.Fragment>
        }
      </div>
      <ResultsDrawer
        className={styles.results_drawer_container}
        isOpen={isDrawerOpen}
        onClose={this.closeSampleViewer}
        results={viewerData}
        loading={sampleLoading}
        drawerTitle={drawerTitle}
      />
    </div>
  }
}

export default dataSamplerConnect(EntityDetails);