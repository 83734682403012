import React from "react";
import cn from 'classnames';
import * as T from "prop-types";
import {Link} from "react-router-dom";
import TMC from "@autonomic/browser-sdk";
import AutoIntl from "@au/core/lib/components/elements/AutoIntl";
import AuButton, {BUTTON_SIZE_SMALL, BUTTON_TYPE_TERTIARY} from "@au/core/lib/components/elements/AuButton";
import SearchBox from "@au/core/lib/components/elements/SearchBox";
import SidebarFilters from "@au/core/lib/components/elements/filters/SidebarFilters";
import FiltersCounterButton from "@au/core/lib/components/elements/filters/FiltersCounterButton";
import LoadingIndicator from "@au/core/lib/components/elements/LoadingIndicator";
import { createResponseAlertMessage } from '@au/core/lib/components/objects/AlertMessage';

import * as knownFilters from './filters';
import { getUrlValue } from './DataSamplerRouter';
import Breadcrumbs from "../Breadcrumbs";
import browserHistory from '../../history';
import { DATA_SAMPLER_PATH, NOOP, LIST_PATH} from "../../constants";

import styles from '../../css/components/dataSampler/entity_list.module.scss';

const filtersDef = [
  {
    DeviceType: {
      name: "deviceType",
      iconClassName: "icon-calendar",
      multiSelect: false,
      displayId: "au.filter.deviceType",
      hideClearBtn: true,
      showRemoveBtn: false
    }
  },
  {
    SampleStatus: {
      name: "sampleStatus",
      iconClassName: "icon-doc_3",
      multiSelect: false,
      displayId: "au.filter.sampleStatus"
    }
  },
  {
    EntityType: {
      name: "entityType",
      iconClassName: "icon-event",
      multiSelect: false,
      displayId: "au.filter.dataType",
      hideClearBtn: true,
      showRemoveBtn: false
    }
  }
];

const MINIMUM_SEARCH_STRING_LENGTH = 3;

const ALPHABET = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split('');

class AlphabetSearch extends React.Component {

  static propTypes = {
    alphabetSet: T.object,
    onChange: T.func,
    disableSearch: T.bool
  }

  scrollToHeader(letter) {
    const element = document.getElementById(`header-${letter.toUpperCase()}`);
    element?.parentElement.scrollIntoView({behavior: "smooth", block: "start"});
  }

  render() {
    return <div className={styles.alphabet_search}>
      {ALPHABET.map(letter => {
        if (!this.props.alphabetSet.has(letter.toUpperCase())) {
          return <div key={letter} className={cn(styles.letter, styles.disabled)}>{letter}</div>;
        }
        return <a key={letter} className={styles.letter} onClick={() => this.scrollToHeader(letter)}>{letter}</a>;
      })}
      <SearchBox
        className={styles.search_box}
        placeholderId={"au.dataSampler.entities.search"}
        onChange={this.props.onChange}
        disabled={this.props.disableSearch}
      />
    </div>;
  }
}

export default class EntityList extends React.Component {

  entityEndpoint = new TMC.services.DataSampler({apiVersion: '1-alpha'}).entity

  constructor(props) {
    super(props);

    const params = new URLSearchParams(props.location.search);
    const deviceType = params.get('deviceType') ?? undefined;
    const sampleStatus = params.get('sampleStatus') ?? undefined;
    const entityType = params.get('entityType') ?? undefined;

    // filtersDef defaultValue needs to be defined before first render because they get defined and never
    // updated in the SidebarFilters constructor
    const def = [...filtersDef];
    def[0]["DeviceType"].defaultValue = deviceType;
    def[2]["EntityType"].defaultValue = entityType;

    this.state = {
      filterCount: 0,
      showFilters: true,
      clientSearchString: "",
      loading: true,
      error: !(deviceType && entityType),
      filtersDef: def,
      filters: {deviceType, sampleStatus, entityType}
    };
  }

  componentDidMount() {
    if(!this.state.error){
      this.getEntities();
    }
  }

  updateFilters(filters, postUpdateCallback){
    let filterCount = 0;
    const newFilters = {};

    //This logic only works if multiSelect: false
    for (const [name, filterArr] of Object.entries(filters)) {
      if(filterArr.length > 0){
        newFilters[name] = filterArr[0].urlValue;
        filterCount++;
      }
    }

    this.setState( {filters: newFilters, filterCount}, postUpdateCallback);
  }

  getEntities() {
    this.setState({loading: true});
    this.entityEndpoint.list({...this.state.filters,})
      .then(res => {
        if (res.data) {
           this.setState({entities: res.data.items, error: false});
        }
      })
      .catch((res) => {
        this.setState({error: true});
        createResponseAlertMessage(res);
      })
      .finally(() => this.setState({loading: false}));
  }

  getBreadCrumbs() {
    return (
      <Breadcrumbs
        crumbs={[
          {key: "data-sampler", displayId: "au.dataSampler.header", destination: DATA_SAMPLER_PATH, tag: "data-sampler"},
          {key: "data-sampler-entities", displayId: "au.dataSampler.entities.header", tag: "data-sampler-entities"},
        ]}
      />
    )
  }

  render() {
    if(this.state.error){
      return <div className={styles.container}>
        {this.getBreadCrumbs()}
        <AutoIntl className={styles.error} displayId="au.apiRequest.error" tag="div"/>
      </div>
    }

    const filteredEntities = this.state.clientSearchString.length >= MINIMUM_SEARCH_STRING_LENGTH && this.state.entities ?
      this.state.entities.filter(entity => entity.entityName?.toUpperCase().includes(this.state.clientSearchString.toUpperCase())) :
      this.state.entities ?? [];
    const setOfFirstCharsInEntities = filteredEntities.reduce((set, e) => set.add(e?.entityName[0]?.toUpperCase()), new Set())

    return <div className={styles.container}>
      <div className={styles.breadcrumb_flex}>
        {this.getBreadCrumbs()}
        {!this.state.showFilters && <FiltersCounterButton
          className={styles.filters_collapse}
          count={this.state.filterCount}
          onClick={() => this.setState({showFilters: true})}
        />}
      </div>
      <div className={styles.list_container}>
        <div className={styles.left_column}>
          <AlphabetSearch
            alphabetSet={setOfFirstCharsInEntities}
            onChange={(str) => this.setState({clientSearchString: str})}
            hasData={filteredEntities.length === 0 && this.state.clientSearchString.length === 0}
          />
          <div className={styles.list_body}>
            {this.state.loading ?
              <LoadingIndicator displayId="au.ftcpLookup.loadingIndicator" className={styles.loading_indicator}/> :
              <AlphabeticalList entities={filteredEntities} sortProperty="entityName" deviceType={this.state.filters.deviceType}/>
            }
          </div>
        </div>
        {this.state.showFilters &&
        <SidebarFilters
          className={styles.sidebar}
          filtersDef={this.state.filtersDef}
          knownFilters={knownFilters}
          browserHistory={browserHistory}
          actions={{saveUserData: NOOP}}
          onInit={(filters) => this.updateFilters(filters)}
          onChange={(filters) => this.updateFilters(filters, () => this.getEntities())}
          onClose={() => this.setState({showFilters: false})}
        />}
      </div>
    </div>;
  }
}

function AlphabeticalList({entities, sortProperty, deviceType}) {
  function compare(a, b) {
    if (a[sortProperty] < b[sortProperty]) {
      return -1;
    }
    if (a[sortProperty] > b[sortProperty]) {
      return 1;
    }
    return 0;
  }

  if(entities.length === 0){
    return <AutoIntl className={styles.list_error} displayId="au.dataSampler.error" tag="div"/>;
  }

  const sortedEntities = entities.sort(compare);

  let entityMap = new Map();
  sortedEntities.forEach(entity => {
    const firstCharUppercase = entity[sortProperty][0]?.toUpperCase();
    let entityArr = entityMap.get(firstCharUppercase);
    if (entityArr) {
      entityMap.set(firstCharUppercase, [...entityArr, entity]);
    } else {
      entityMap.set(firstCharUppercase, [entity]);
    }
  });

  return <div>
    {ALPHABET.map(letter => {
      let newVar = entityMap.get(letter);
      if (newVar) {
        return <div key={letter}>
          <div className={styles.letter_header} id={`header-${letter}`}>{letter}</div>
          <div className={styles.letter_header_divider}>
            {newVar.map(entity => {
              const entityName = entity[sortProperty];
              const entityType = entity.entityType;
              return <div key={entityName} className={cn(styles.item, {[styles.sample_captured]: entity.hasSample})}>
                <div className={styles.text}>{entityName}</div>
                <Link to={{pathname: getUrlValue(entityType), search:`?deviceType=${deviceType}&entityName=${entityName}`, state:{from: LIST_PATH}}} >
                  <AuButton
                    displayId="au.dataSampler.entities.viewDetails"
                    size={BUTTON_SIZE_SMALL}
                    type={BUTTON_TYPE_TERTIARY}
                    className={styles.view_button}
                  />
                </Link>
              </div>;
            })}
          </div>
        </div>;
      }

      return <React.Fragment key={letter}/>;
    })}
  </div>;
}
