import React from "react";
import cn from "classnames";

import AutoIntl from "@au/core/lib/components/elements/AutoIntl";

import CellWithCopy from "../CellWithCopy";

import styles from "../../css/components/ftcp/signal_mapping_table.module.scss";

export const currentlyNotSupportedOnTMC = "au.signalMappingTable.notSupportedOnTMC";
const EVENT_COLLAPSIBLE_HEADER = [{displayId: "au.signalMappingTable.ftcpElements"}, {displayId: "au.signalMappingTable.triggerConditions"}, {displayId: "au.signalMappingTable.requiredSignals"}];
const NOT_SUPPORTED_HEADER = [{displayId: "au.signalMappingTable.triggerConditions"}, {displayId: "au.signalMappingTable.requiredSignals"}];

export function CollapsibleConditionsTable({conditions}) {
  const rows = [];
  const ftcpSupported = Boolean(conditions[0].ftcpAlias);
  const gridTemplateColumns = ftcpSupported ? "1fr 1fr 1fr" : "1fr 1fr";

  if (ftcpSupported) {
    for (var cond in conditions) {
      rows.push(<Row key={cond} name={conditions[cond].ftcpAlias} conditionsInfo={conditions[cond].info} ftcpSupported={ftcpSupported}/>);
    }
  }

  else {
    for (var condition in conditions[0].info) {
      rows.push(<Row key={condition} name={conditions[0].info[condition].triggerConditionName} conditionsInfo={conditions[0].info[condition]} ftcpSupported={ftcpSupported}/>);
    }
  }

  return (
    <div className={styles.grid_body} style={{gridTemplateColumns}}>
      <Headers ftcpSupported={ftcpSupported}/>
      {rows}
    </div>
  );
}

function Headers({ftcpSupported}) {
  const headers = ftcpSupported ? [...EVENT_COLLAPSIBLE_HEADER] : [...NOT_SUPPORTED_HEADER];

  return headers.map(header => (
    <div key={header.displayId} className={styles.collapsible}>
      <AutoIntl displayId={header.displayId}/>
    </div>
  ));
}

function Row({name, conditionsInfo, ftcpSupported}) {
  const textWrappedMessageName = name.split(".").map((nameFragment, index, {length}) => <div
    key={index} className={styles.message_name}
  >{nameFragment + (index !== length - 1 ? "." : "")}</div>);
  const signalsCount = ftcpSupported ? conditionsInfo.flatMap(each => each.wellKnownMetrics).length : 1;


  return (
    <>
      <div className={cn(styles.grid_cell)} style={{gridRow: `span ${signalsCount}`}}>
        <CellWithCopy className={cn(styles.sticky, styles.collapsible_text)} data={name}>
          {textWrappedMessageName}
        </CellWithCopy>
      </div>
      {ftcpSupported &&
        conditionsInfo.map(
          condition =>
            <React.Fragment key={condition.triggerConditionName + condition.wellKnownMetrics}>
              <div className={cn(styles.grid_cell, styles.collapsible_text)} style={{gridRow: `span ${condition.wellKnownMetrics?.length || 1}`}}>
                {condition.triggerConditionName}
              </div>
              {condition.wellKnownMetrics && condition.wellKnownMetrics.map(
                metric => <SubRow key={metric} metric={metric}/>
              )}
              {!condition.wellKnownMetrics &&
                <div className={cn(styles.grid_cell, styles.collapsible_text, styles.hideRightBorder)}>
                  <AutoIntl displayId={'au.tmcEvent.noMetrics'}/>
                </div>
              }
            </React.Fragment>
        )
      }
      {!ftcpSupported &&
        <NotSupportedRow condition={conditionsInfo}/>
      }
    </>
  );
}

function SubRow({metric}) {

  return (
    <React.Fragment>
      <div className={cn(styles.grid_cell, styles.collapsible_text, styles.hideRightBorder)}>
        {metric}
      </div>
    </React.Fragment>
  );
}

function NotSupportedRow({condition}) {
  if (condition.wellKnownMetrics) {
    return (
      <React.Fragment>
        {condition.wellKnownMetrics.map(
          metric => <SubRow key={metric} metric={metric}/>
        )}
      </React.Fragment>
    );
  }
  else {
    return (
      <div className={cn(styles.grid_cell, styles.hideRightBorder)}><AutoIntl className={styles.no_signals} displayId={'au.tmcEvent.noSignalsRequired'}/></div>
    );
  }
}
