import React from "react";
import cn from "classnames";
import {Link} from "react-router-dom";

import TMC from "@autonomic/browser-sdk";
import AutoIntl from "@au/core/lib/components/elements/AutoIntl";
import LoadingIndicator from "@au/core/lib/components/elements/LoadingIndicator";
import ResultsDrawer from "@au/core/lib/components/elements/ResultsDrawer";
import { createResponseAlertMessage } from '@au/core/lib/components/objects/AlertMessage';

import SamplerBreadCrumbs from "./SamplerBreadCrumbs";
import CollapsibleTable from "./CollapsibleTable";
import {EnumValuesTable, TAGS_TABLE} from "../tmcLookup/TmcSignal";
import SampleViewer from './SampleViewer';
import DeviceTypeSelector from "../DeviceTypeSelector";
import EntityResult from "./EntityResult";
import {dataSamplerConnect} from "../../reducers";
import {DATA_SAMPLER_ENTITY_TYPES, DATA_SAMPLER_SIGNAL_PATH} from "../../constants";

import styles from "../../css/components/dataSampler/entity_details.module.scss";
import eventStyles from "../../css/components/dataSampler/event_details.module.scss";

const headers = [
  {displayId: "au.dataSampler.events.triggerConditions"},
  {displayId: "au.dataSampler.events.requiredSignals"}
];

const additionalSignalHeaders = [
  {displayId: "au.dataSampler.events.additionalSignal.rowHeader"}
];

export class EventDetails extends React.Component {

  entityEndpoint = new TMC.services.DataSampler({apiVersion: '1-alpha'}).entity

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      expanded: true,

      isDrawerOpen: false,
      sampleLoading: false,
      viewerData: null,
      drawerTitle: '',
    };
  }

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const deviceType = params.get('deviceType') ?? this.props.deviceTypes[0].val;
    const entityName = params.get('entityName') ?? undefined;

    this.setState({deviceType, entityName}, () => this.getEvent());
  }

  getEvent() {
    const {deviceType, entityName} = this.state;

    this.setState({loading: true});
    this.entityEndpoint.get({deviceType, entityName, entityType: DATA_SAMPLER_ENTITY_TYPES.EVENT})
      .then((res) => {
        if (res.data?.entityType === "wellKnownEvent") {
          const triggerConditionsRows = this.generateTriggerConditionTableRows(res.data.triggerCondition);
          const additionalSignalsRows = this.generateTableFromArray(res.data.additionalSignals);
          const tagsInfo = {signalTags: res.data.tags.map(tag => ({...tag, enumValues: tag.enums !== null ? tag.enums : undefined}))}; //To match data type needed for EnumValuesTable

          this.setState({event: res.data, triggerConditionsRows, additionalSignalsRows, tagsInfo, isCustomEvent: false, error: false});
        }
        else if(res.data){
          this.setState({event: res.data, triggerConditionsRows: undefined, additionalSignalsRows: undefined, tagsInfo: undefined, isCustomEvent: true, error: false});
        }
      })
      .catch((res) => {
        if(res?.data?.error === 'not found') {
          this.setState({event: {entityName}, isCustomEvent: true, error: false});
        } else {
          this.setState({error: true});
          createResponseAlertMessage(res);
        }
      })
      .finally(() => this.setState({loading: false}));
  }

  closeSampleViewer = this.closeSampleViewer.bind(this);
  closeSampleViewer() {
    this.setState({isDrawerOpen: false})
  }

  handleSampleDataChange = this.handleSampleDataChange.bind(this);
  handleSampleDataChange({isDrawerOpen, drawerTitle, viewerData, sampleLoading, showError}) {
    this.setState({isDrawerOpen, drawerTitle, viewerData, sampleLoading, error:showError });
  }

  generateTriggerConditionTableRows(triggerCondition) {
    return triggerCondition.map(condition => {
      return {
        key: condition.name,
        elements: [
          {
            key: condition.name,
            value: <div className={cn(eventStyles.condition_cell, styles.cell)}>
              <div className={eventStyles.condition_name} title={condition.name}>
                {condition.name}
              </div>
              <SampleViewer drawerTitle={condition.name} sampleId={condition.sampleId} onDataChange={this.handleSampleDataChange} />
            </div>
          },
          {
            key: condition.requiredSignals.join(),
            lastInRow: true,
            values: condition.requiredSignals.map(signal => {
              return <div key={signal} className={styles.cell}>
                <Link to={`${DATA_SAMPLER_SIGNAL_PATH}?deviceType=${this.state.deviceType}&entityName=${signal}`} className={eventStyles.link}>
                  {signal}
                </Link>
              </div>;
            })
          }
        ]
      }
    });
  }

  generateTableFromArray(array) {
    return array.map(element => {
      return {
        key: element,
        elements: [
          {
            key: element,
            lastInRow: true,
            value: element
          }
        ]
      }
    });
  }

  DeviceTypeSelector() {
    return <DeviceTypeSelector
      name={this.state.entityName}
      deviceType={this.state.deviceType}
      deviceTypes={this.props.deviceTypes}
      setDeviceType={(value) => this.setState({deviceType: value}, () => this.getEvent())}
      disabled={this.state.isDrawerOpen}
    />;
  }

  EntityResult() {
    const {event} = this.state;

    return <EntityResult
      entity={event}
      handleSampleDataChange={this.handleSampleDataChange}
    />
  }

  TriggerConditionsTable() {
    const {expanded, triggerConditionsRows} = this.state;

    if(!triggerConditionsRows) return null;

    return <div className={eventStyles.conditions_table_container}>
      <span
        className={expanded ? eventStyles.collapse : eventStyles.expand}
        onClick={() => this.setState(prevState => ({expanded: !prevState.expanded}))}
      />
      <AutoIntl
        className={cn(styles.basic_table_header, eventStyles.conditions_table_header)}
        displayId="au.dataSampler.events.triggerConditions.tableHeader"
      />
      <div className={cn({[eventStyles.closed]: !expanded})}>
        <AutoIntl
          className={eventStyles.conditions_table_subheader}
          displayId="au.dataSampler.events.conditionsTableSubheader"
        />
        <CollapsibleTable
          headers={headers}
          rows={triggerConditionsRows}
          columnNumber={2}
          className={eventStyles.ct_margin_top}
          backgroundColor={"#F5F5F5"}
        />
      </div>
    </div>
  }

  AdditionalSignalsTable() {
    const {event, additionalSignalsRows} = this.state;

    if(!additionalSignalsRows) return null;

    return <div className={styles.basic_table}>
      <AutoIntl
        className={styles.basic_table_header}
        displayId="au.dataSampler.events.additionalSignals.header"
      />
      {event?.additionalSignals.length > 0 ?
        <CollapsibleTable headers={additionalSignalHeaders} rows={additionalSignalsRows} columnNumber={1} className={eventStyles.ct_margin_top}/> :
        <AutoIntl className={styles.no_table} displayId="au.dataSampler.events.additionalSignal.noSignals" tag="div"/>
      }
    </div>
  }

  TagsTable() {
    const {event, tagsInfo, entityName} = this.state;

    if(!tagsInfo) return null;

    return <div className={styles.basic_table}>
      <AutoIntl className={styles.basic_table_header} displayId="au.dataSampler.events.tags.header"/>
      {event?.tags.length > 0 ?
        <EnumValuesTable info={tagsInfo} type={TAGS_TABLE} /> :
        <AutoIntl
          className={styles.no_table}
          displayId="au.dataSampler.events.tags.noTags"
          values={{event: entityName}} tag="div"
        />
      }
    </div>
  }

  render() {
    const {loading, error, drawerTitle, isDrawerOpen, viewerData, sampleLoading, isCustomEvent, deviceType} = this.state;

    return <div className={styles.container}>
      <div className={styles.details_container}>
        <SamplerBreadCrumbs deviceType={deviceType} parentEntityType="event" entityType={isCustomEvent ? "customEvent" : "wellKnownEvent"} />
        {this.DeviceTypeSelector()}
        {loading && <LoadingIndicator displayId="au.loadingIndicator" className={styles.loading_indicator}/>}
        {error && <AutoIntl displayId="au.apiRequest.error" tag="div"/>}
        {!loading && !error &&
          <React.Fragment>
            {this.EntityResult()}
            {this.TriggerConditionsTable()}
            {this.AdditionalSignalsTable()}
            {this.TagsTable()}
          </React.Fragment>
        }
      </div>
      <ResultsDrawer
        className={styles.results_drawer_container}
        isOpen={isDrawerOpen}
        onClose={this.closeSampleViewer}
        results={viewerData}
        loading={sampleLoading}
        drawerTitle={drawerTitle}
      />
    </div>
  }
}

export default dataSamplerConnect(EventDetails);
