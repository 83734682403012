import React from 'react';
import {Route, Switch} from "react-router-dom";

import SecurityPage from '@au/core/lib/components/objects/SecurityPage';

import DataSampler from './DataSampler';
import NotFound from "../NotFound";

import {
  DATA_SAMPLER_CONFIGURATION_PATH,
  DATA_SAMPLER_ENTITY_LIST_PATH,
  DATA_SAMPLER_ENTITY_TYPES,
  DATA_SAMPLER_EVENT_PATH,
  DATA_SAMPLER_INDICATOR_PATH,
  DATA_SAMPLER_PATH,
  DATA_SAMPLER_SIGNAL_PATH,
  DATA_SAMPLER_STATEMACHINE_PATH
} from "../../constants";
import EntityList from "./EntityList";
import EventDetails from "./EventDetails";
import SignalDetails from "./SignalDetails";
import StateMachineDetails from "./StateMachineDetails";
import EntityDetails from "./EntityDetails";


export function getUrlValue(entityType){
  if(entityType === DATA_SAMPLER_ENTITY_TYPES.EVENT){
    return DATA_SAMPLER_EVENT_PATH;
  }
  else if(entityType === DATA_SAMPLER_ENTITY_TYPES.SIGNAL){
    return DATA_SAMPLER_SIGNAL_PATH;
  }
  else if(entityType === DATA_SAMPLER_ENTITY_TYPES.INDICATOR){
    return DATA_SAMPLER_INDICATOR_PATH;
  }
  else if(entityType === DATA_SAMPLER_ENTITY_TYPES.CONFIGURATION){
    return DATA_SAMPLER_CONFIGURATION_PATH;
  }
  else if(entityType === DATA_SAMPLER_ENTITY_TYPES.STATE_MACHINE){
    return DATA_SAMPLER_STATEMACHINE_PATH;
  }
  return DATA_SAMPLER_EVENT_PATH;
}

export default class DataSamplerRouter extends React.Component {
  render() {
    return (
      <Switch>
        <Route path={DATA_SAMPLER_PATH} component={DataSampler} exact/>
        <Route path={DATA_SAMPLER_ENTITY_LIST_PATH} component={EntityList} exact/>
        <Route path={DATA_SAMPLER_EVENT_PATH} component={EventDetails} exact/>
        <Route path={DATA_SAMPLER_SIGNAL_PATH} component={SignalDetails} exact/>
        <Route
          path={DATA_SAMPLER_CONFIGURATION_PATH}
          render={(props) => <EntityDetails {...props} entityType={DATA_SAMPLER_ENTITY_TYPES.CONFIGURATION}/>}
          exact
        />
        <Route
          path={DATA_SAMPLER_INDICATOR_PATH}
          render={(props) => <EntityDetails {...props} entityType={DATA_SAMPLER_ENTITY_TYPES.INDICATOR}/>}
          exact
        />
        <Route
          path={DATA_SAMPLER_STATEMACHINE_PATH}
          render={(props) => <StateMachineDetails {...props} entityType={DATA_SAMPLER_ENTITY_TYPES.STATE_MACHINE}/>}
          exact
        />
        <Route path={'/security'}                                 component={SecurityPage}/>
        <Route component={NotFound}/>
      </Switch>
    );
  }
}