import { saveAs } from 'file-saver';

import AuAnalytics from '@au/core/lib/utils/AuAnalytics';

import { zoomToHash } from '../utils/dom';
import { LOCAL_DOCS, LOCAL_DOCS_FOLDER } from '../constants';
import history from '../history';
import { isMsie, isSafari } from '../utils/userAgent';
import Network from '../network';

const badDownloadLinkBooleanAttr = 'data-badLink';

function fixPath(basePath, filename) {
  let path;
  if (LOCAL_DOCS) {
    path = `${LOCAL_DOCS_FOLDER}/${basePath}/${filename}`;
  }
  else {
    path = `${basePath}/${filename}`;
  }
  return new URL(path, window.location.origin).pathname;
}

function primeImages(auRemarkable, basePath) {
  let promises = [];
  const images = auRemarkable.node.querySelectorAll('img');
  for (let img of Array.from(images)) {
    const filename = img.attributes.src.value;
    const fixedPath = fixPath(basePath, filename);

    let enhancedImagePromise = new Network(fixedPath).blobUrl();

    enhancedImagePromise = enhancedImagePromise
      .then(src => {
        img.originalSrc = img.src;
        img.src = src;
      })
      .catch(() =>
        console.error('unable to prime image %s', fixedPath) // eslint-disable-line no-console
      );

    promises.push(enhancedImagePromise);
  }
  return Promise.all(promises);
}

function primeDownloadLinks(auRemarkable, basePath) {
  const promises = [];
  for (let a of Array.from(auRemarkable.getLinks('file'))) {
    const isLocal = a.hostname === window.location.hostname;
    if (!isLocal) continue;

    const fixedPath = fixPath(basePath, a.attributes.href.value);
    const filename = fixedPath.split('/').pop();

    let blobPromise = new Network(fixedPath).blob();

    const gaClick = () => AuAnalytics.trackEvent({
        category: 'Document',
        action: 'Download File',
        label: fixedPath
    });

    let enhancedLinkPromise;
    if (isMsie || isSafari) {
      enhancedLinkPromise = blobPromise
        .then(blob => a.addEventListener('click', () => saveAs(blob, filename)))
        .then(() =>
          a.addEventListener('click', e => {
            gaClick();
            e.preventDefault();
            e.stopPropagation();
            e.cancelBubble = true;
            return false;
          })
        );
    } else {
      enhancedLinkPromise = blobPromise
        .then(blob => URL.createObjectURL(blob))
        .then(blobUrl => {
          a.originalHref = a.href;
          a.href = blobUrl;
          a.target = '_blank';
          a.setAttribute('download', filename);
        })
        .then(() => a.addEventListener('click', gaClick));
    }

    enhancedLinkPromise = enhancedLinkPromise
      .catch(errorMsg => {
        console.error(errorMsg); // eslint-disable-line no-console
        a.className += ' bad-download-link';
        a.setAttribute(badDownloadLinkBooleanAttr, '');
      });

    promises.push(enhancedLinkPromise);
  }
  return Promise.all(promises);
}

function primeLocalLinks(a){
  a.addEventListener('click', e => {
    e.preventDefault();
    history.push(a.pathname + a.hash);
    const isOpen = a.pathname === window.location.pathname;
    if (isOpen) {
      // assuming there is a hash)
      zoomToHash(a.hash);
    }
    return false;
  });
}

function primeExternalLinks(a) {
  a.target = '_blank';
  a.rel = 'noopener';
  a.addEventListener('click', () => AuAnalytics.trackEvent({
    category: 'Document',
    action: 'Outbound Link',
    label: a.href
  }));
}
// open external links in new tab and local simply push a new browser location
function primeLinks(auRemarkable) {
  for (let a of Array.from(auRemarkable.getLinks('url'))) {
    const isLocal = a.hostname === window.location.hostname;
    if (isLocal) {
      primeLocalLinks(a);
    }
    else {
      primeExternalLinks(a);
    }
  }
}

export default function loadExternals(auRemarkable, basePath){
  primeLinks(auRemarkable);
  return Promise.all([
    primeImages(auRemarkable, basePath),
    primeDownloadLinks(auRemarkable, basePath)
  ]);
}
