import { createBrowserHistory } from 'history';
import { zoomToHash, zoomToTop } from './utils/dom';

import AuAnalytics, { connectAuAnalyticsToHistory } from '@au/core/lib/utils/AuAnalytics';

const history = connectAuAnalyticsToHistory(createBrowserHistory());

// When going back, zoom to hash to top of doc
history.listen((location, action) => {
  if (action === 'POP') {
    if (location.hash) {
      AuAnalytics.trackPage(location.pathname + location.hash);
      zoomToHash(location.hash);
    }
    else {
      zoomToTop();
    }
  }
});

export default history;
