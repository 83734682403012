import React from 'react';
import { Provider as OriginalProvider } from 'react-redux';

import store from '../store';

const Provider = ({ children }) =>
  <OriginalProvider store={store}>
    {children}
  </OriginalProvider>;
export default Provider;
