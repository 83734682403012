import React from 'react';
import { injectIntl } from 'react-intl';

/**
 * This file provides a way to use intl wrapper outside of react component
 */

class CurrentLocale extends React.PureComponent {

  componentDidMount() {
    if (!CurrentLocale.instance) {
      CurrentLocale.instance = this;
    }
  }

  render() {
    return false;
  }
}

export default injectIntl(CurrentLocale);

function intl() {
  return CurrentLocale.instance.props.intl;
}

export function formatMessage(...args) {
  return intl().formatMessage(...args);
}
