import React from "react";
import cn from "classnames";

import AutoIntl from "@au/core/lib/components/elements/AutoIntl";

import EntityResult from "./EntityResult";
import SampleViewer from "./SampleViewer";
import CollapsibleTable from "./CollapsibleTable";
import { EntityDetails } from "./EntityDetails";
import CellWithCopy, {UNAVAILABLE_ID} from "../CellWithCopy";

import { dataSamplerConnect } from "../../reducers";

import styles from "../../css/components/dataSampler/entity_details.module.scss";
import stateTransitionsStyles from "../../css/components/dataSampler/state_transitions.module.scss";

const stateTransitionHeaders = [
  {displayId: "au.dataSampler.stateTransitions.state.rowHeader"}
];

function StateTransitionsTable({stateTransitions, handleSampleDataChange}){
  if (!stateTransitions) return null;

  const stateTransitionRows = stateTransitions.map((transition, index) => {
      return {
        key: `${transition.name}-${index}`,
        elements: [
          {
            key: transition.name,
            value: <div className={cn(styles.cell, stateTransitionsStyles.row)}>
              <div className={stateTransitionsStyles.nameUri_wrapper}>
                <div className={stateTransitionsStyles.name}>{transition.name}</div>
                <div className={stateTransitionsStyles.uri}>
                  <CellWithCopy uri={transition.uri ?? UNAVAILABLE_ID} uriCopyDisplayId="au.dataSampler.stateTransitions.state.copy" />
                </div>
              </div>
              <SampleViewer drawerTitle={transition.name} sampleId={transition.sampleId} onDataChange={handleSampleDataChange} />
            </div>
          },
        ]
      }
    }
  );

  return <div className={styles.basic_table}>
    <AutoIntl className={styles.basic_table_header} displayId="au.dataSampler.stateMachine.stateTransitions.header" tag="div" />
    {stateTransitions.length > 0
      ? <CollapsibleTable headers={stateTransitionHeaders} rows={stateTransitionRows} columnNumber={1} className={stateTransitionsStyles.ct_margin_top} />
      : <AutoIntl className={styles.no_table} displayId="au.dataSampler.signals.enums.noSignals" tag="div" />
    }
  </div>
}

class StateMachineDetails extends EntityDetails {
  constructor(props) {
    super(props);
  }

  EntityResult() {
    const {entity} = this.state;

    return <EntityResult
      entity={entity}
      handleSampleDataChange={this.handleSampleDataChange}
      showUri={false}
    />
  }

  AdditionalDetails() {
    const {entity} = this.state;
    return <StateTransitionsTable stateTransitions={entity.stateTransitions} handleSampleDataChange={this.handleSampleDataChange} />
  }
}

export default dataSamplerConnect(StateMachineDetails);