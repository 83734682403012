/* global apigClientFactory */

import shared from '../shared';
import { language } from '../utils/intl';
import { FALLBACK_LANG } from '../constants';


export function buildFilterQuery(language) {
  if (language === FALLBACK_LANG) {
    return `languages:'${language}'`;
  }
  else {
    // NOTE: order matters
    // assumes languages literal will never be no more than two languages
    return `languages:['${FALLBACK_LANG}','${language}']`;
  }
}

const fq = buildFilterQuery(language);

// NOTE: this assumes AWS credentials have been initialized
export default {
  getApigClient() {
    return new Promise(res => shared.awsConfigObj.credentials.get(res))
      .then(() => ({
        url:          shared.cloudSearchUrl,
        accessKey:    shared.awsConfigObj.credentials.accessKeyId, // TODO: test if this is necessary
        secretKey:    shared.awsConfigObj.credentials.secretAccessKey,
        sessionToken: shared.awsConfigObj.credentials.sessionToken,
        region:       shared.awsConfigObj.region
      }))
      .then(config => apigClientFactory.newClient(config));
  },

  noHitsReponse: { hits: { found: 0, hit: [] } },

  enhanceSimpleSearch(query) {
    // escape all "(", ")" and "~"
    query = query.replace(/[()~]/g, match => {
      return `\\${match}`;
    });

    // escape word-surrounded "+", "-" and "|" and quote them
    query = query.replace(/(\S+?)([-+|]+)(\S*)/g, (_, c1, c2, c3) => {
      const escaped = '\\' + Array.from(c2).join('\\');
      return `"${c1}${escaped}${c3}"`;
    });

    // always do a prefix search
    return query + '*';
  },

  search(query, size=10) {
    return this.getApigClient()
      .then(client => client.opGet({
        op: 'search',
        q: this.enhanceSimpleSearch(query),
        fq,
        suggester: '',
        'highlight.content': "{}",
        'highlight.title': "{}",
        size
      }), () => this.noHitsReponse)
      .then(resp => {
        if (!resp.data || !resp.data.hits) {
          // CloudSearch returns a 404 when searching for "/" and potentially other things
          // API Gateway already returns a 200
          resp.data = this.noHitsReponse;
        }
        return resp;
      });
  },

  suggest(query, size=10) {
    return this.getApigClient()
      .then(client => client.opGet({
        op: 'suggest',
        q: query,
        suggester: 'content',
        'highlight.content': '',
        size
      }));
  }
};
