import React from 'react';
import { IntlProvider as OriginalIntlProvider } from 'react-intl';

import { messages, language } from '../utils/intl';

const IntlProvider = ({ children }) =>
  <OriginalIntlProvider locale={language} messages={messages}>
    {children}
  </OriginalIntlProvider>;
export default IntlProvider;
