/* eslint-disable no-console */
import AuAnalytics from '@au/core/lib/utils/AuAnalytics';

/**
 * Global singleton logger -- WIP
 * level can be:
 *   'mute' - no logs
 *   'dev' - only dev(), warn() and error() log only in development environment -- default
 *   'warn' - 'dev' plus warn() and error() in production environment
 */
class Logger {
  constructor(level='dev') {
    this.level = level;
  }

  set level(level) {
    this._level = level;
    if (level === 'mute') {
      // no logging at all
      this._dev = this._info = this._warn = this._error = false;
      return;
    }
    if (process.env.NODE_ENV === 'production') {
      this._dev = false;
      this._warn = level === 'warn';
      this._error = level === 'warn';
    }
    else {
      // NODE_ENV === 'development'
      this._dev = true;
      this._warn = true;
      this._error = true;
    }
  }

  dev() {
    // Only on dev ever
    if (this._dev) {
      console.log(...arguments);
    }
  }
  /* not sure if info is needed...
  info() {
    if (this._info) {
      console.info(...arguments);
    }
  }
  */
  warn() {
    if (this._warn) {
      console.warn(...arguments);
    }
  }
  error() {
    if (this._error) {
      AuAnalytics.trackException(`logger:error: ${arguments}`);
      console.warn(...arguments);
    }
  }
}

export default new Logger(); // TODO: add test that checks what type of logger is created -- failes on anything but 'dev'
