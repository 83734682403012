import React, { Component } from 'react';
import * as T from 'prop-types';
import slug from '../utils/slug';
import browserHistory from '../history';

import injectRouterParams from '@au/core/lib/utils/injectRouterParams';
import { ManagedAuSidebarCollapser } from '@au/core/lib/components/elements/AuSidebarCollapser';
import AuScrollbar from '@au/core/lib/components/elements/AuScrollbar';
import { injectScreenWidth } from '@au/core/lib/components/elements/ScreenWidthProvider';
import AuAnalytics from '@au/core/lib/utils/AuAnalytics';
import { eachDoc } from '@au/dev-docs/toc/common';

import {
  MARKDOWN_INJECTED,
  NOOP,
  TOC_STATES,
  DESKTOP_SCREENWIDTH,
  SUB_DOCS_TOC_KEY,
  DOC_CONTENT_KEY,
  DOC_APP_PATH_KEY
} from '../constants';
import { isSubUrl } from '../utils/url';
import TocBar from './TocBar';
import TocEntry from './TocEntry';

import styles from '../css/components/toc.module.scss';

class Toc extends Component {
  static propTypes = {
    section: T.object.isRequired,
    match: T.object.isRequired,
    location: T.object.isRequired,
    onToggle: T.func,
    setTocCollapsed: T.func,
    screenWidth: T.string.isRequired
  }

  static defaultProps = {
    onToggle: NOOP,
    setTocCollapsed: NOOP
  }

  state = {
    expanded: {}, /* for folders */
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
    if (this.nav) {
      this.nav.removeEventListener('startViewing', this.handleStartViewing);
    }
  }

  componentDidUpdate() {
    const { tocCollapsed, screenWidth } = this.props;
    const isMobile = !DESKTOP_SCREENWIDTH.includes(screenWidth);

    if (!isMobile && tocCollapsed === TOC_STATES.initial) {
      window.dispatchEvent(new CustomEvent(MARKDOWN_INJECTED));
    } else if (tocCollapsed) {
      window.dispatchEvent(new CustomEvent(MARKDOWN_INJECTED));
    }
  }

  onCollapserClick = this.onCollapserClick.bind(this);
  onCollapserClick() {
    const { setTocCollapsed, tocCollapsed, screenWidth } = this.props;
    const isMobile = !DESKTOP_SCREENWIDTH.includes(screenWidth);


    if (tocCollapsed === TOC_STATES.initial) {
      if (isMobile) {
        setTocCollapsed(TOC_STATES.collapsed);
      } else {
        setTocCollapsed(TOC_STATES.expanded);
      }
    } else if (tocCollapsed === TOC_STATES.collapsed) {
      setTocCollapsed(TOC_STATES.expanded);
    } else if (tocCollapsed === TOC_STATES.expanded) {
      setTocCollapsed(TOC_STATES.collapsed);
    }

    /* trigger to recalculate Doc component to be responsive */
    window.dispatchEvent(new Event('resize'));
  }

  onEntryClick = this.onEntryClick.bind(this);
  onEntryClick() {
    const { screenWidth, setTocCollapsed } = this.props;
    if (!DESKTOP_SCREENWIDTH.includes(screenWidth)) {
      setTocCollapsed(TOC_STATES.expanded);
    }
  }

  addBars(entries, url, level) {
    const curUrl = this.props.location.pathname;
    const linkLevelClass = styles['link-lvl-' + level];

    const bars = [];
    for (let [name, entry] of entries.entries()) {
      const nextUrl = `${url}/${slug(name)}`;
      const defaultExpanded = isSubUrl(curUrl, nextUrl);
      if (entry.has(SUB_DOCS_TOC_KEY)) {
        //It's a folder
        bars.push(
          <TocBar key={nextUrl} url={nextUrl} isFolder={true} name={name} level={level} defaultExpanded={defaultExpanded}>
            {this.addBars(entry.get(SUB_DOCS_TOC_KEY), nextUrl, level+1)}
          </TocBar>
        );
      }
      else {
        const active = curUrl === nextUrl;
        //It's a document
        bars.push(
          <TocBar key={`${level}+${name}`} name={name} url={nextUrl} level={level} defaultExpanded={defaultExpanded}>
            <TocEntry onLinkClick={this.onEntryClick} entry={entry} url={nextUrl} active={active} linkLevelClassName={linkLevelClass}/>
          </TocBar>
        );
      }
    }
    return bars;
  }

  handleDocClick = this.handleDocClick.bind(this);
  handleDocClick(displayName, path) {
    this.setState({ displayName });
    browserHistory.push(path);
  }

  handleFolderClick = this.handleFolderClick.bind(this);
  handleFolderClick(path, val) {
    this.setState(prevState => ({
      expanded: {
        ...prevState.expanded,
        [path]: !val
      }
    }));
    // return "True" - action was handled, skip default handler
    return true;
  }

  setNavRef = this.setNavRef.bind(this);
  setNavRef(el) {
    if (this.mounted && el && !this.el) {
      this.nav = el;
      this.nav.addEventListener('startViewing', this.handleStartViewing);
    }
  }

  handleStartViewing = this.handleStartViewing.bind(this);
  handleStartViewing(e) {
    const link = e.target;
    if (this.mounted && link && this.nav && typeof link.scrollIntoView === 'function') { // checks are needed for rapid browser tests
      link.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center'
      });
    }
  }

  handleOnExpand = this.handleOnExpand.bind(this);
  handleOnExpand() {
    window.dispatchEvent(new CustomEvent(MARKDOWN_INJECTED));
    AuAnalytics.trackEvent({
      category: 'TOC',
      action: 'Expanded',
      label: window.location.pathname
    });
    this.props.onToggle({ expand: true });
  }

  handleOnCollapsed = this.handleOnCollapsed.bind(this);
  handleOnCollapsed() {
    AuAnalytics.trackEvent({
      category: 'TOC',
      action: 'Collapsed',
      label: window.location.pathname
    });
    this.props.onToggle({ expand: false });
  }

  render() {
    const { section, match, tocCollapsed, screenWidth, location } = this.props;
    const isMobile = !DESKTOP_SCREENWIDTH.includes(screenWidth);

    const bars = this.addBars(section, match.url, 1);
    let tocState = tocCollapsed === TOC_STATES.initial ? !isMobile : tocCollapsed;

    // check that the doc matching the app path is loaded before showing TOC nav
    // otherwise show TOC skeleton
    let docIsLoaded = false;
    eachDoc(section, entry => {
      if (entry.get(DOC_APP_PATH_KEY) === location.pathname && entry.has(DOC_CONTENT_KEY)) {
        docIsLoaded = true;
      }
    });

    return (
      <ManagedAuSidebarCollapser className={styles.custom_collapser} onClick={this.onCollapserClick} isOpen={tocState}>
        <div className={styles.wrapper}>
          <section className={styles.container} data-tid="Toc">
            { docIsLoaded &&
              <AuScrollbar className={styles.nav}>
                <nav ref={this.setNavRef}>
                  {bars}
                </nav>
              </AuScrollbar>
            }
            { !docIsLoaded &&
              <div className={styles.skeleton_container}>
                <div className={styles.skeleton_one}></div>
                <div className={styles.skeleton_two}></div>
                <div className={styles.skeleton_two}></div>
              </div>
            }
          </section>
        </div>
      </ManagedAuSidebarCollapser>
    );
  }
}

export default injectScreenWidth(injectRouterParams(Toc));
