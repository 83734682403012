import React, { Component } from 'react';
import Aux from '../Auxiliary/Auxiliary';
import BrowserModal from '../../components/BrowserModal';

const checkBrowser = (WrappedComponent) => {
  return class extends Component {
    render() {
      return (
        <Aux>
          <BrowserModal/>
          <WrappedComponent/>
        </Aux>
      );
    }
  };
};

export default checkBrowser;
