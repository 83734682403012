import React, { PureComponent } from 'react';
import cn from 'classnames';
import * as T from 'prop-types';
import { Link } from 'react-router-dom';

import AutoIntl from '@au/core/lib/components/elements/AutoIntl';

import styles from '../css/components/section_tile.module.scss';

function Indicator(props){
  const { indicator } = props;
  if (!indicator) {
    return false;
  }
  return <div className={styles.indicator} >{indicator}</div>;
}

export default class SectionTile extends PureComponent {
  static propTypes = {
    title: T.string.isRequired,
    description: T.string,
    indicator: T.string,
    to: T.string.isRequired,
    style: T.oneOf(['services', 'getting-started']).isRequired,
    color: T.string
  }

  static defaultProps = {
    description: '',
    indicator: '',
    color: '#1b6fbf'
  }

  render() {
    const { title, description, to, color, style, indicator } = this.props;
    const containerStyle = color ? { borderTop: `3px solid ${color}` } :  {};

    return (
      <div className={cn(styles.container, styles[style])} data-tcl="SectionTile" style={containerStyle}>
        <Link className={styles.tile} to={to}>
          <Indicator indicator={indicator} />
          <div className={styles.header}>
            <div className={styles.title} title={title}>{title}</div>
          </div>
          <div className={styles.description}>{description}</div>
          <AutoIntl displayId="au.docs.view" className={styles.view} />
        </Link>
      </div>
    );
  }
}
