import React, { Component } from 'react';
import * as T from 'prop-types';
import IPT from 'react-immutable-proptypes';
import { Route } from 'react-router-dom';

import Toc from '../containers/Toc';
import DocRouter from './DocRouter';
import { eachDoc } from '@au/dev-docs/toc/common';
import NotFound from './NotFound';
import { DOC_CONTENT_KEY } from '../constants';

import styles from '../css/components/section.module.scss';

export default class Section extends Component {
  static propTypes = {
    match: T.object.isRequired,
    section: IPT.orderedMap,
    swaggerRef: T.string,
    downloadDoc: T.func.isRequired
  }

  state = {
    /* force update state when AuSidebarCollapser button is clicked */
    tocToggle: true
  }

  handleTocToggle = this.handleTocToggle.bind(this);
  handleTocToggle() {
    this.setState({ tocToggle: true });
  }

  primeDocs(section) {
    const downloadDoc = entry => !entry.has(DOC_CONTENT_KEY) && this.props.downloadDoc(entry);
    eachDoc(section, downloadDoc);
  }

  renderContent() {
    const { section, swaggerRef, match } = this.props;

    if (section) {
      this.primeDocs(section);
      return (
        <React.Fragment>
          <Route path={match.path} render={props =>
            <Toc {...props} section={section} onToggle={this.handleTocToggle}/>
          } />
          <Route path={match.path} render={props =>
            <DocRouter {...props} section={section} swaggerRef={swaggerRef} />
          } />
        </React.Fragment>
      );
    }
    else {
      return <NotFound />;
    }
  }

  render() {
    return (
      <div className={styles.container}>
        { this.renderContent() }
      </div>
    );
  }
}
