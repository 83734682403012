import React from 'react';

import SecurityPage from '@au/core/lib/components/objects/SecurityPage';
import PrivacyPolicy from '@au/core/lib/components/elements/PrivacyPolicy';

import styles from '../css/components/security_page.module.scss';

export default class Security extends React.Component {

  render() {
    return (
      <div>
        <SecurityPage />
        <PrivacyPolicy className={styles.footer}/>
      </div>
    )
  }
}