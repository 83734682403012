import React from 'react';
import * as T from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import { NETWORK_STATES } from '../constants';
import { getToc } from '../utils/selectors';
import NotFound from '../components/NotFound';
import Section from './Section';
import Zone from './Zone';


// TODO: UX for going to the closest valid path
// NOTE: TOC here refers to the toc.json not the document's TOC
class TocRouter extends React.Component {
  static propTypes = {
    loading: T.bool.isRequired,
    hasAppPath: T.bool, // can be `undefined`
    path: T.string.isRequired,
  }

  render() {
    const { loading, hasAppPath, path } = this.props;
    if (loading) {
      return false; // TODO: handle this with skeleton designs or pass this down
    }
    if (hasAppPath) {
      return (
        <Switch>
          <Route path={path + '/:sectionName'} component={Section} />
          <Route path={path}                   component={Zone} />
        </Switch>
      );
    }
    else {
      return <NotFound />;
    }
  }
}

export default connect(
  ({ app: state }, { location, match }) => {
    const toc = getToc(state);

    let loading, hasAppPath;

    loading = !toc && state.getIn(['network', 'toc']) === NETWORK_STATES.fetching;

    if (toc && !loading) {
      hasAppPath = toc.hasResolvableAppPath(location.pathname);
    }

    return {
      loading,
      hasAppPath,
      path: match.path
    };
  },
  null,
  props => props // avoid merging passed in props from <Route>
)(TocRouter);
