import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import * as T from "prop-types";

import TMC from "@autonomic/browser-sdk";
import { JsonData } from "@au/core/lib/components/objects";
import AuDropDown from "@au/core/lib/components/elements/AuDropDown";
import AuInput from "@au/core/lib/components/elements/AuInput";
import AutoIntl from "@au/core/lib/components/elements/AutoIntl";
import LoadingIndicator from "@au/core/lib/components/elements/LoadingIndicator";
import ResultsDrawer from "@au/core/lib/components/elements/ResultsDrawer";
import AuButton, { BUTTON_TYPE_PRIMARY } from "@au/core/lib/components/elements/AuButton";

import Breadcrumbs from "../Breadcrumbs";
import { currentlyNotSupportedOnTMC, SignalMappingTable } from "./SignalMappingTable";
import FeedbackButton from "./LookupFeedbackButton";
import CellWithCopy, { UNAVAILABLE_ID } from "../CellWithCopy";
import { TMC_LOOKUP_PATH, TMC_EVENT_PATH } from "../../constants";
import SearchResult from "./SearchResult";
import styles from "../../css/components/ftcp/ftcp_lookup.module.scss";
import tmcLookupStyles from "../../css/components/ftcp/tmc_lookup.module.scss";

export default class FtcpLookup extends React.Component {

  FtcpOracleService = new TMC.services.FtcpOracle({apiVersion: '1-alpha'});

  constructor(props) {
    super(props);

    this.state = {
      event: undefined,
      ftcpAlert: "",
      version: "",
      isPopupOpen: false,
      error: false,
      loading: true
    };
  }

  static propTypes = {
    event: T.object,
    location: T.object,
  }

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const ftcpAlert = params.get('ftcpAlert');
    const version = params.get('version');

    this.setState({ftcpAlert: ftcpAlert, version: version});

    this.FtcpOracleService.events.getFtcpEvent({
      name: ftcpAlert,
      ftcpVersion: version
    }).then(res => {
      if (res.data) {
        this.setState({event: this.processFtcpEvent(res.data), version, loading: false});
      }
    }).catch(() => this.setState({error: true, loading: false}));
  }

  processFtcpEvent(event) {
    const messages = new Map();
    const canSignalValues = {};
    const allMappings = (event.canSignalMappings ?? []).concat(event.nonCanSignalMappings ?? []);

    allMappings.forEach(mapping => {
      const canSignal = {name: mapping.canSignalName || mapping.ftcpSignalName, description: mapping.canSignalDescription, defaultValue: mapping.defaultValue, enumMappings: mapping.enumMappings};
      const tmcSignal = mapping.wellKnownSignalInfos?.length ? mapping.wellKnownSignalInfos[0].signalName : currentlyNotSupportedOnTMC;
      const tmcSignalUri = mapping.wellKnownSignalInfos?.length ? mapping.wellKnownSignalInfos[0].signalUri : UNAVAILABLE_ID;
      const message = messages.get(mapping.containingMessage) ?? [];

      const signalIdx = message.findIndex(signal => signal.tmcSignal === tmcSignal);
      const newIdx = signalIdx === -1 ? message.length : signalIdx;
      message[newIdx] = {canSignals: (message[newIdx]?.canSignals ?? []).concat(canSignal), tmcSignal, tmcSignalUri};
      messages.set(mapping.containingMessage, message);
      canSignalValues[mapping.canSignalName || mapping.ftcpSignalName] = mapping.defaultValue;
    });

    const tmcName = Array.isArray(event.wellKnownEventMappings) && event.wellKnownEventMappings.length > 0 ? event.wellKnownEventMappings[0]?.name : undefined;
    const uri = Array.isArray(event.wellKnownEventMappings) && event.wellKnownEventMappings.length > 0 ? event.wellKnownEventMappings[0].uri : undefined;
    return {
      name: event.ftcpMessageName,
      messages,
      tmcName,
      canSignalValues,
      uri
    };
  }

  fetchEventConversion = this.fetchEventConversion.bind(this);
  fetchEventConversion() {
    this.setState({isDrawerOpen: true, eventConversionLoading: true, eventConversionResult: [{headerId: "au.resultsDrawer.uri", data: this.state.event?.uri ?? UNAVAILABLE_ID, children: <JsonData data={`URI: ${(this.state.event?.uri ?? UNAVAILABLE_ID)}`}/>}]});
    this.FtcpOracleService.events.getModifiedEventConversion({
      name: this.state.event.name,
      ftcpVersion: this.state.version,
      canSignalValues: this.state.event.canSignalValues
    }).then((resp) => {
      // The 2 second delay was a requirement for the story to add a loading spinner
      setTimeout(() => this.setState({
        eventConversionResult: this.processEventConversion(resp.data),
        eventConversionCount: {eventCount: resp.data.conversionOutput.countOfEvents, metricCount: resp.data.conversionOutput.countOfMetrics},
        eventConversionLoading: false
      }), 2000);
    }).catch(() => this.setState({eventConversionLoading: false}));
  }

  processEventConversion(eventConversion) {
    return [
      {headerId: "au.resultsDrawer.uri", data: this.state.event?.uri ?? UNAVAILABLE_ID, children: <JsonData data={`URI: ${(this.state.event?.uri ?? UNAVAILABLE_ID)}`}/>},
      {headerId: "au.resultsDrawer.ftcpMessage", data: eventConversion.ftcpMessage},
      {headerId: "au.resultsDrawer.events", data: eventConversion.conversionOutput.events},
      {headerId: "au.resultsDrawer.metrics", data: eventConversion.conversionOutput.metrics}
    ];
  }

  defaultValueDropdown = this.defaultValueDropdown.bind(this);
  defaultValueDropdown(canSignal, signal) {
    const name = canSignal.name;
    const selection = this.state.event.canSignalValues[name];

    return <div key={name} >
      {signal.tmcSignal !== currentlyNotSupportedOnTMC && canSignal?.enumMappings ?
        <AuDropDown
          className={styles.default_value}
          options={canSignal.enumMappings.map(enumValue => ({displayString: `${enumValue.intValue} = ${enumValue.canValue}`, val: enumValue.intValue}))}
          selection={selection}
          selectOption={(value) => {
            this.setState(prevState => ({event: {...prevState.event, canSignalValues: {...prevState.event.canSignalValues, [name]: value}}}));
          }}
          allowTyping={false}
          createMode={true}
        />
        :
        <AuInput className={styles.default_value} value={canSignal.defaultValue} disabled={true}/>
      }
    </div>;
  }

  renderBreadcrumbs() {
    const {version, ftcpAlert} = this.state;
    const lookupDestination = `${TMC_LOOKUP_PATH}?ftcpAlert=${ftcpAlert}&version=${version}`;

    return <Breadcrumbs
      crumbs={[
        {key: "tmc-lookup", displayId: "au.tmcLookup.header", destination: lookupDestination, tag: "tmc-lookup"},
        {key: "ftcp-lookup", displayId: "au.ftcpLookup.header", tag: "ftcp-lookup"},
      ]}
    />;
  }

  render() {
    const {
      version,
      ftcpAlert,
      eventConversionResult,
      eventConversionCount,
      event,
      error,
      loading,
      isPopupOpen,
      isDrawerOpen,
      eventConversionLoading
    } = this.state;

    if (error) {
      return (
        <div className={tmcLookupStyles.container}>
          {this.renderBreadcrumbs()}
          <FeedbackButton />
          <SearchResult>
            <AutoIntl className={tmcLookupStyles.searchResultText} displayId='au.ftcpLookup.alertError' values={{name: ftcpAlert, version: version}} />
          </SearchResult>
        </div>
      );
    }

    if(loading){
      return (
        <div className={tmcLookupStyles.container}>
          {this.renderBreadcrumbs()}
          <FeedbackButton />
          <LoadingIndicator displayId="au.ftcpLookup.loadingIndicator"/>
        </div>
      );
    }

    return (
      <div className={styles.container}>
        <div className={cn(styles.lookup_container, {[styles.isDrawerOpen]: isDrawerOpen})}>
          {this.renderBreadcrumbs()}
          <FeedbackButton />
          {event && <SearchResult>
            <AutoIntl className={tmcLookupStyles.searchResultText} displayId={event.tmcName ? "au.ftcpLookup.alertFound" : "au.ftcpLookup.alertFoundNotConverted"} values={{alertName: <b>{event.name}</b>, tmcEventName: <Link to={`${TMC_EVENT_PATH}?tmcEvent=${event.tmcName}`} className={styles.link}>{event.tmcName}</Link>}} />
            <div className={tmcLookupStyles.uri_container}>
              <div className={tmcLookupStyles.uri_labels}>
                <AutoIntl className={tmcLookupStyles.uri_result} displayId='au.tmcLookup.uri'/>
              </div>
              <div className={tmcLookupStyles.uri_values}>
                <CellWithCopy className={tmcLookupStyles.uri_text} uri={event?.uri ?? UNAVAILABLE_ID}/>
              </div>
            </div>
          </SearchResult>}
          <div className={tmcLookupStyles.table_heading}>
            <AutoIntl className={styles.title} displayId="au.ftcpLookup.header" tag="h6"/>
            <AuButton displayId="au.ftcpLookup.simulate" type={BUTTON_TYPE_PRIMARY} onClick={this.fetchEventConversion}/>
          </div>
          {event?.messages?.size ?
            <SignalMappingTable
              messages={event.messages}
              version={version}
              defaultValueDropdown={this.defaultValueDropdown}
              openPopup={() => this.setState({isPopupOpen: true})}
            /> :
            <AutoIntl className={tmcLookupStyles.no_signals} displayId="au.ftcpLookup.noSignals" tag="div"/>
          }
          <InfoPopup
            isOpen={isPopupOpen}
            titleDisplayId={"au.signalMappingTable.tmcSignal"}
            textDisplayId={"au.ftcpLookup.popupText"}
            onClose={() => this.setState({isPopupOpen: false})}
          />
        </div>
        <ResultsDrawer
          isOpen={isDrawerOpen}
          onClose={() => this.setState({isDrawerOpen: false})}
          results={eventConversionResult}
          loading={eventConversionLoading}
          loadingDisplayId="au.resultsDrawer.ftcpLookup.loadingIndicator"
          contentBefore={eventConversionCount && <div className={styles.drawer_content_before}><span className={styles.drawer_content_icon}/><AutoIntl displayId="au.ftcpLookup.resultsCount" values={{...eventConversionCount, alertName: <b>{event.name}</b>}}/></div>}
        />
      </div>
    );
  }
}

function InfoPopup({isOpen, titleDisplayId, textDisplayId, onClose}){
  if (!isOpen) {
    return null;
  }

  return <div className={styles.popup}>
    <AutoIntl className={styles.title} displayId={titleDisplayId}/>
    <div className={styles.close} onClick={onClose}/>
    <AutoIntl className={styles.text} displayId={textDisplayId} tag="div"/>
  </div>;
}
