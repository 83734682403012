import React from 'react';
import * as T from 'prop-types';
import {NOOP} from '../../../constants';
import {SelectableListProps} from "@au/core/lib/components/elements/SelectableList";
import {Filter} from "@au/core/lib/components/elements/filters/Filter";
import FilterSelectableList from "@au/core/lib/components/elements/filters/FilterSelectableList";

export default class SampleStatus extends React.Component {

  static propTypes = {
    defaultValue: SelectableListProps.defaultValue,
    containerRef: SelectableListProps.containerRef,
    selection: T.arrayOf(Filter),
    sort: SelectableListProps.sort,
    multiSelect: T.bool,
    onChange: T.func,
    onInit: T.func
  };

  static defaultProps = {
    onChange: NOOP,
    onInit: NOOP,
    selection: []
  };

  sampleStatusItems = [
    {displayId: "au.dataSampler.sampleStatus.captured", value: "true"},
    {displayId: "au.dataSampler.sampleStatus.notCaptured", value: "false"}
  ]

  render() {
    return (
      <FilterSelectableList
        {...this.props}
        items={this.sampleStatusItems}
      />
    );
  }
}
