import { DOC, DIR, ENTRY, FALLBACK_LANGUAGE } from '@au/dev-docs/constants';
import {  CONSOLE_LINK, HELP_LINK, TMC_METRICS_LINK, STATUS_PAGE_LINK } from '@au/core/lib/constants';

// TODO: remove tutorial and reference types
export const TUT_TYPE = 'tut';
export const TUT_DIR = 'tutorial'; // this should maybe be passed via toc.json
export const REF_TYPE = 'ref';
export const REF_DIR = 'reference'; // this should maybe be passed via toc.json


export const LOCAL_DOCS = process.env.REACT_APP_USE_LOCAL_DOCS === 'true';
export const LOCAL_DOCS_FOLDER = process.env.REACT_APP_LOCAL_DOCS_FOLDER;
export const MARKDOWN_INJECTED = 'Markdown Injected';
export const MARKDOWN_CLEARED = 'Markdown Cleared';
export const NOOP = () => {};
export const EMAIL_REGEX = /^(\w.+@){0,1}((?:[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]{0,61}\.)+[a-zA-Z]{2,})$/;

export const LOOKUP_FEEDBACK_LINK = 'https://forms.gle/NnPZgRbpi8CKPn9K7';
export const DATASAMPLER_FEEDBACK_LINK = '';

// V2
export const {
  REDIRECT_KEY: REDIRECT_DOC_KEY
} = DOC.METADATA;
export const {
  CHILDREN_KEY: SUB_DOCS_TOC_KEY,
  DESCRIPTION_KEY,
  INDICATOR_KEY,
  COLOR_KEY,
  STYLE_KEY: ZONE_STYLE_KEY,
  SPLASH_KEY: ZONE_SPLASH_KEY,
  SWAGGER_URL_KEY,
  SWAGGER_TITLE_KEY,
} = DIR.METADATA;
export const {
  SORT_KEY: META_SORT_KEY,
  TITLE_KEY: TITLE_DOC_KEY,
  HASH_KEY: HASH_DOC_KEY,
  PERMALINK_KEY: PERMALINKS_DOC_KEY,
  LAST_MODIFIED_KEY: DOC_LAST_MODIFIED_KEY,
  PATH_KEY: PATH_TOC_KEY
} = ENTRY.METADATA;

export const DOC_CONTENT_KEY = '_doc';
export const DOC_APP_PATH_KEY = '_appPath';
export const DOC_TOC_PATH_KEY = '_tocPath';
export const IS_CHOSEN_LANG = 'isChosenLang';
export const FILE_LINK_APP = 'appFilePath';
export const FALLBACK_LANG = FALLBACK_LANGUAGE;
export const TOC_MAPPINGS_KEY = '_tocMappings';
export const SWAGGER_PATH = '/swagger-ui';
export const TMC_LOOKUP_PATH = '/tmc-lookup';
export const FTCP_LOOKUP_PATH = `${TMC_LOOKUP_PATH}/ftcp-lookup`;
export const FTCP_SIGNAL_INFORMATION_PATH = `${TMC_LOOKUP_PATH}/ftcp-signal-information`;
export const SIGNAL_BUNDLE_PATH = `${TMC_LOOKUP_PATH}/signal-bundle`;
export const TMC_EVENT_PATH = `${TMC_LOOKUP_PATH}/tmc-events`;
export const TMC_SIGNAL_PATH = `${TMC_LOOKUP_PATH}/tmc-signals`;
export const TMC_INDICATOR_PATH = `${TMC_LOOKUP_PATH}/tmc-indicators`;
export const TMC_CONFIGURATION_PATH = `${TMC_LOOKUP_PATH}/tmc-configurations`;
export const DATA_SAMPLER_PATH = '/data-sampler';
export const DATA_SAMPLER_ENTITY_LIST_PATH = `${DATA_SAMPLER_PATH}/entities`;
export const DATA_SAMPLER_EVENT_PATH = `${DATA_SAMPLER_PATH}/event`;
export const DATA_SAMPLER_SIGNAL_PATH = `${DATA_SAMPLER_PATH}/signal`;
export const DATA_SAMPLER_CONFIGURATION_PATH = `${DATA_SAMPLER_PATH}/configuration`;
export const DATA_SAMPLER_INDICATOR_PATH = `${DATA_SAMPLER_PATH}/indicator`;
export const DATA_SAMPLER_STATEMACHINE_PATH = `${DATA_SAMPLER_PATH}/state-machine`;
export const DEV_PREVIEW_PATH = '/dev-preview';

export const RELEASE_NOTES_SUBSCRIPTION_LINK = 'https://autonomic-6374686.hs-sites.com/en/tmc-email-subscriptions';

export const LIST_PATH = 'list';

export const DOC_DATA_ID = 'docContainer';

export const DESKTOP_SCREENWIDTH = ['desktop', 'desktopLarge', 'desktopExtraLarge'];

export const MIN_CHARACTERS_TO_SEARCH = 3;

// Network states
export const NETWORK_STATES = Object.freeze({
  initial: null,
  fetching: 'FETCHING',
  done: 'DONE',
  unreachable: 'UNREACHABLE'
  // or a HTTP status code
});

export const TOC_STATES = Object.freeze({
  initial: null,
  collapsed: true,
  expanded: false,
});

export const UNSET_SCHEMA_REFS = Object.freeze({
  'command-service': {
    'components.schemas': [
      'BulkCommandStatus',
      'MetricsResponse',
      'PaginatedResponseCommandStatus',
      'StateTransitionData'
    ]
  },
  'telemetry-service': {
    'components.schemas': [
      'EventConditions',
      'EventsResponse',
      'MetricsResponse',
      'StatesResponse',
      'StateTrigger'
    ]
  },
  'vehicle-service': {
    'definitions': [
      'Events',
      'Metrics'
    ]
  }
});

//This is for the ordered set of links to ensure that link order stays the same.
export const tmcLinkTypes = Object.freeze([
  CONSOLE_LINK,
  HELP_LINK,
  TMC_METRICS_LINK,
  STATUS_PAGE_LINK
]);

export const BASE_DOMAIN_NA   = 'autonomic.ai';
export const BASE_DOMAIN_CNP0 = 'p0.tmc79.cn';
export const BASE_DOMAIN_CNP1 = 'p1.tmc79.cn';

export const ENV_CNP0 = 'cnp0';
export const ENV_CNP1 = 'cnp1';

export const AUTONOMIC_AND_FORD_DOMAINS = [
    BASE_DOMAIN_NA,
    "ford.com"
];

export const DATA_SAMPLER_ENTITY_TYPES = {
  EVENT: "event",
  SIGNAL: "signal",
  INDICATOR: "indicator",
  CONFIGURATION: "configuration",
  STATE_MACHINE: 'stateMachine',
};