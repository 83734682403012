import React, { PureComponent } from 'react';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import {LOOKUP_FEEDBACK_LINK} from "../constants.js";

import styles from "../css/components/sticky_feedback_button.module.scss";

export default class StickyFeedbackButton extends PureComponent {
  render() {
    return (
      <a
        href={LOOKUP_FEEDBACK_LINK}
        target="_blank"
        rel="noopener noreferrer"
        >
        <div className={styles.feedback_button}>
          <span className={styles.icon}/>
          <AutoIntl displayId="au.feedback" className={styles.text}/>
        </div>
      </a>
    );
  }
}
