import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setTocCollapsed } from '../actionCreators';

import Toc from '../components/Toc';

export default connect(
  ({ app: state }) => {
    const tocCollapsed = state.get('tocCollapsed');
    return { tocCollapsed };
  },
  dispatch => ({ setTocCollapsed: bindActionCreators(setTocCollapsed, dispatch) })
)(Toc);
