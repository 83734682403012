import React from "react";
import cn from "classnames";

import AutoIntl from "@au/core/lib/components/elements/AutoIntl";

import styles from "../../css/components/dataSampler/collapsible_table.module.scss";

export default function CollapsibleTable({headers, rows, columnNumber, className, backgroundColor="#FFFFFF"}) {
  const gridTemplateColumns = "1fr ".repeat(columnNumber).trim();

  return (
    <div className={cn(styles.grid_body, className)} style={{gridTemplateColumns}}>
      {
        headers.map(header => {
          return <div key={header.displayId} className={styles.collapsible} style={{backgroundColor}}>
            <AutoIntl displayId={header.displayId}/>
          </div>
        })
      }
      {
        rows.map((row) => {
          return <Row key={row.key} elements={row.elements}/>;
        })
      }
    </div>
  );
}

function Row({elements}){
  return <React.Fragment>
    {elements.map(element => {
      return <div key={element.key} className={cn(styles.grid_cell, styles.collapsible_text, {[styles.grid_cell_multi]: element.values, [styles.hide_right_border]: element.lastInRow})}>
        {element.values || element.value}
      </div>
      })
    }
  </React.Fragment>
}
