import { createStore, applyMiddleware, compose } from 'redux';
import { fromJS } from 'immutable';
import thunk from 'redux-thunk';

import rootReducer from '../modules';
import { auPersist } from '@au/core/lib/utils/stateDelegators';
import { NETWORK_STATES, TOC_STATES } from '../constants';


const initialState = {
  app: fromJS({
    docs: {},
    objectUrls: {},
    network: {
      toc: NETWORK_STATES.initial
    },
    tocCollapsed: TOC_STATES.initial
  })
};
try {
  initialState.app = initialState.app.mergeDeep(auPersist.read());
} catch (e) {
  console.error(e); // eslint-disable-line no-console
  auPersist.clear();
}

const enhancers = [];
const middleware = [
  thunk,
];

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
);

const store = createStore(
  rootReducer,
  initialState,
  composedEnhancers
);

export default store;
