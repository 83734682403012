import { LOCAL_DOCS } from '../constants';
import S3 from './s3';
import LocalDocs from './localDocs';

// LocalDocs and S3 should expose the same methods

export default function Network(path) {
  if (LOCAL_DOCS) {
    return new LocalDocs(path);
  } else {
    return new S3(path);
  }
}
