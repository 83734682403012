import React, { PureComponent } from 'react';
import * as T from 'prop-types';
import cn from 'classnames';

import PrivacyPolicy from '@au/core/lib/components/elements/PrivacyPolicy';

import { zoomToHash, zoomToTop, registerMdContainer } from '../utils/dom';
import { TUT_TYPE, REF_TYPE, MARKDOWN_INJECTED, MARKDOWN_CLEARED } from '../constants';

import styles from '../css/components/markdown_renderer.module.scss';


// should only render if the derived markdown document has changed
export default class MarkdownRenderer extends PureComponent {
  static propTypes = {
    type: T.oneOf([REF_TYPE, TUT_TYPE]).isRequired,
    parsed: T.object.isRequired // can be {}
  }

  static defaultProps = {
    type: REF_TYPE
  }

  injected = false;

  componentWillUnmount() {
    this.injected = false;
    window.dispatchEvent(new CustomEvent(MARKDOWN_CLEARED));
  }

  componentDidUpdate({ parsed: prevParsed }) {
    if (prevParsed !== this.props.parsed) {
      // something has changed
      if (Object.keys(this.props.parsed).length && this.injected) {
        window.dispatchEvent(new CustomEvent(MARKDOWN_INJECTED));
      }
      else {
        window.dispatchEvent(new CustomEvent(MARKDOWN_CLEARED));
      }
    }
  }

  setContainerRef = this.setContainerRef.bind(this);
  setContainerRef(ref) {
    this.containerRef = ref;
    registerMdContainer(ref);
  }

  setMarkContainer = this.setMarkContainer.bind(this);
  setMarkContainer(ref) {
    this.markContainerRef = ref;
    this.injectMarkdown();
    window.dispatchEvent(new CustomEvent(MARKDOWN_INJECTED)); // this is OK since it will not happen during a render
  }

  injectMarkdown() {
    const { parsed } = this.props;
    this.injected = false;
    if (this.markContainerRef && parsed.node) {
      while (this.markContainerRef.hasChildNodes()) {
        this.markContainerRef.removeChild(this.markContainerRef.lastChild);
      }
      this.markContainerRef.appendChild(parsed.node);
      this.injected = true;

      if (window.location.hash) {
        zoomToHash();
      }
      else {
        // assume any new document should be at the top (needed for local links)
        zoomToTop();
      }
    }
  }

  renderContainerSections() {
    const { isReady } = this.props.parsed;
    const { tablet, mobile } = this.props;

    if (!isReady) {
      return <div className={styles.skeleton_container}>
        <div className={styles.skeleton_one}></div>
        <div className={styles.skeleton_two}></div>
        <div className={styles.skeleton_three}></div>
        <div className={styles.skeleton_four}></div>
        <div className={styles.skeleton_five}></div>
        <div className={styles.skeleton_four}></div>
      </div>;
    }

    return [
      <section
        key="documentation"
        ref={this.setMarkContainer}
        data-tcl="MarkdownRenderer-content"
        className={cn(styles.documentation, {[styles.tablet]: tablet, [styles.mobile]: mobile})}
      />,
      <section
        key="code"
        className={styles.code}
      />
    ];
  }

  render() {
    const { type, parsed } = this.props;

    if (!parsed.isReady && parsed.ready) {
      parsed.ready.then(() => this.forceUpdate());
    }

    // necessay for changing documents but components are already mounted
    this.injectMarkdown();

    return (
      <article className={cn(styles[type], styles.container)} ref={this.setContainerRef}>
        {this.renderContainerSections()}
        <PrivacyPolicy className={styles.policy} />
      </article>

    );
  }
}
