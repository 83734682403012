import { connect } from 'react-redux';
import { downloadDoc } from '../actionCreators';
import { bindActionCreators } from 'redux';
import slug from '../utils/slug';
import { getToc } from '../utils/selectors';

import { SWAGGER_URL_KEY, SWAGGER_TITLE_KEY, SUB_DOCS_TOC_KEY } from '../constants';
import Section from '../components/Section';
import injectRouterParams from '@au/core/lib/utils/injectRouterParams';

export default injectRouterParams(connect(
  ({ app: state }, { zoneName, sectionName='' }) => {
    const toc = getToc(state);

    // only get the section, not entire URL since DocRouter handles the
    // recursive routing
    const section = toc.getIn(`/${zoneName}/${sectionName}`);

    let swaggerRef;
    if (section.has(SWAGGER_URL_KEY)) {
      swaggerRef = section.has(SWAGGER_TITLE_KEY)
                   ? slug(section.get(SWAGGER_TITLE_KEY))
                   : sectionName;
    }

    return {
      section: section.get(SUB_DOCS_TOC_KEY),
      swaggerRef
    };
  },
  dispatch => ({ downloadDoc: bindActionCreators(downloadDoc, dispatch) })
)(Section));
