import React from "react";
import * as T from 'prop-types';
import debounce from "lodash.debounce";
import { Link } from "react-router-dom";

import TMC from "@autonomic/browser-sdk";
import AuDropDown from "@au/core/lib/components/elements/AuDropDown";
import MultiDropDown from "@au/core/lib/components/elements/MultiDropDown";
import AutoIntl from "@au/core/lib/components/elements/AutoIntl";
import AuButton from "@au/core/lib/components/elements/AuButton";
import { BUTTON_TYPE_PRIMARY } from "@au/core/lib/components/elements/AuButton";

import { getUrlValue } from './DataSamplerRouter';
import LookupPageLayout from "../LookupPageLayout";
import {
  DATASAMPLER_FEEDBACK_LINK,
  MIN_CHARACTERS_TO_SEARCH,
  DATA_SAMPLER_ENTITY_LIST_PATH,
} from "../../constants.js";
import {dataSamplerConnect} from "../../reducers";

import styles from "../../css/components/lookup_page.module.scss";
import data_sampler_styles from "../../css/components/dataSampler/data_sampler.module.scss";

export class DataSamplerFilter extends React.Component {
  
  static propTypes = {
    deviceTypes: T.arrayOf(T.object),
    entityTypes: T.arrayOf(T.object),
  }

  constructor(props) {
    super(props);

    this.state = {
      deviceType: '',
      entityType: '',
    };
  }

  render() {
    const { deviceTypes, entityTypes } = this.props;
    const { deviceType, entityType } = this.state;
    const searchString = `?deviceType=${deviceType}&entityType=${entityType}` ;
    
    return (
      <div data-testid="data-sampler-filter" className={styles.search_box}>
        <AutoIntl className={styles.search_box_text} displayId={`au.dataSampler.filterByHeader`}/>
        <div className={styles.table}>
          <div className={styles.inputContainer}>
            <AutoIntl className={styles.label} displayId={`au.dataSampler.deviceTypeSelect`}/>
            <AuDropDown
              className={styles.dropdown}
              options={deviceTypes}
              placeholderId="au.datasampler.select.placeholder"
              selectOption={(value) => {
                this.setState({deviceType: value});
              }}
              disableSort
            />
          </div>
          <div className={styles.inputContainer}>
            <AutoIntl className={styles.label} displayId="au.dataSampler.entityTypeSelect"/>
            <AuDropDown
              className={styles.dropdown}
              options={entityTypes}
              placeholderId="au.datasampler.select.placeholder"
              selectOption={(value) => {
                this.setState({entityType: value});
              }}
              disableSort={true}
            />
          </div>
          <Link to={`${DATA_SAMPLER_ENTITY_LIST_PATH}${searchString}`}>
            <AuButton
              className={styles.search}
              displayId="au.dataSampler.filterByViewButton"
              type={BUTTON_TYPE_PRIMARY}
              disabled={!(deviceType&&entityType)}
            />
          </Link>
        </div>
      </div>
    );
  }
}

export class DataSamplerLookup extends React.Component {
  DataSamplerService = new TMC.services.DataSampler({apiVersion: '1-alpha'});
  static propTypes = {
    entityTypes: T.arrayOf(T.object),
  }

  constructor(props) {
    super(props);

    this.state = {
      searchKey: '',
      isSearchKeyValid: false,
      showSearchKeyError: false,
      entityOptions: this.formatEntityOptions(props.entityTypes),
      entityPath: '',
    };
  }

  formatEntityOptions(entities, availableOptions, textToBold) {
    return entities.map((entity) => {
      // api responds with plural entity key
      const entityType = `${entity.val}s`;
      const formattedOptions = availableOptions?.[entityType]?.map(option => {
        const replaceRegex = new RegExp(`(${textToBold})`, "gi");
        const displayString = option.entityName
          .replace(replaceRegex, "<b>$1</b>")
          .split(/<b>|<\/b>/)
          .map((fragment, index) => index % 2 === 1 ? <b key={index}>{fragment}</b> : fragment);
        return ({val: option.entityName, displayString: displayString, devices: option.devices});
      });
      return {entityName: entityType, displayId: `au.dataSampler.lookup.${entityType}`, options: formattedOptions || [], entityPath: entity.val};
    });
  }

  onEntitySearch(value) {
    this.setState({ searchKey: value });

    if (value.length >= MIN_CHARACTERS_TO_SEARCH) {
      this.debouncedPopulateSearchOptions(value);
    } else {
      this.setState({entityOptions: []});
    }
  }

  debouncedPopulateSearchOptions = debounce(this.populateSearchOptions, 200).bind(this);
  populateSearchOptions(key) {
    const { entityTypes } = this.props;
    this.DataSamplerService.entity.search({query: key})
      .then(result => {
        const options = this.formatEntityOptions(entityTypes, result.data, key).filter(({options}) => options.length);
        this.setState({entityOptions: options})
      })
      .catch(() => this.setState({entityOptions: [], showSearchKeyError: true}));
  }

  onEntitySelect(value, entityGroup) {
    const {entityName} = this.state.entityOptions.find((entityDef) => entityDef.options.some(option => option.val === value));
    if(!entityName) return;
    
    let entityPath = entityGroup.entityPath;
    
    // get the first suported device for the entity
    const selectedEntity = entityGroup.options.find((entity) => entity.val === value);

    this.setState({searchKey: value, entityPath, selectedEntityType: entityName, entityOptions: [], isSearchKeyValid: true, selectedEntityDevice: selectedEntity.devices[0]});
  }

  textOnClear() {
    this.setState({ searchKey: "", entityPath: undefined, selectedEntityType: undefined, entityOptions: [], isSearchKeyValid: false, selectedEntityDevice: undefined });
  }

  render() {
    const { entityPath } = this.state;

    return (
      <div data-testid="data-sampler-lookup" className={styles.search_box}>
        <AutoIntl className={styles.search_box_text} displayId={`au.dataSampler.lookupHeader`}/>
        <div className={styles.table}>
          <div className={styles.inputContainer}>
            <AutoIntl className={styles.label} displayId={`au.dataSampler.lookup.label`}/>
            <MultiDropDown
              selection={this.state.searchKey}
              options={this.state.entityOptions}
              selectOption={(value, groupName) => this.onEntitySelect(value, groupName)}
              onChange={(value) => this.onEntitySearch(value)}
              onClear={() => this.textOnClear()}
              createMode={false}
              allowTyping={true}
              placeholderId="au.datasampler.search.placeholder"
              error={{errDisplayId: "au.dataSampler.lookup.noResults", fieldDisplayId: 'au.noop'}}
              showError={this.state.showSearchKeyError}
              optionsClassName={styles.options}
            />
          </div>
          <Link to={`${getUrlValue(entityPath)}?entityName=${this.state.searchKey}&deviceType=${this.state.selectedEntityDevice}`}>
            <AuButton
              className={styles.search}
              displayId="au.dataSampler.filterByViewButton"
              type={BUTTON_TYPE_PRIMARY}
              disabled={!this.state.isSearchKeyValid}
            />
          </Link>
        </div>
      </div>
    );
  }
}

class DataSampler extends React.Component {
  static propTypes = {
    collectionStartTime: T.string,
    deviceTypes: T.arrayOf(T.object),
    entityTypes: T.arrayOf(T.object),
  }

  getBlurbChild() {
    return (
      <div className={data_sampler_styles.info_blurb}>
        <AutoIntl displayId='au.dataSampler.info_blurb' className={data_sampler_styles.info_blurb_message} tag="div" />
      </div>
    )
  }

  render() {
    const { collectionStartTime, deviceTypes, entityTypes } = this.props;

    return (
      <LookupPageLayout
        bannerSrc={`${process.env.PUBLIC_URL}/data_sampler_banner.svg`}
        bannerAlt={'TMC Data Sampler Banner'}
        feedbackLink={DATASAMPLER_FEEDBACK_LINK}
        blurbChild={this.getBlurbChild()}
      >
        <AutoIntl className={styles.alertNotice} displayId="au.dataSampler.alertNotice" values={{date: collectionStartTime}} tag="div"/>
        <div className={styles.search_box_container}>
          <DataSamplerFilter deviceTypes={deviceTypes} entityTypes={entityTypes} />
          <DataSamplerLookup entityTypes={entityTypes} />
        </div>
        <AutoIntl className={styles.help_text} displayId={'au.dataSampler.help'} values={{linkText: <a href={`https://developer.beta.autonomic.ai/getting-started/tmc-data-dictionary/using-the-data-dictionary`}>Learn More</a>}}/>
      </LookupPageLayout>
    );
  }
}

export default dataSamplerConnect(DataSampler);
