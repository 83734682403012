import React from "react";
import cn from "classnames";
import {Link} from "react-router-dom";

import TMC from "@autonomic/browser-sdk";
import AutoIntl from "@au/core/lib/components/elements/AutoIntl";
import ResultsDrawer from "@au/core/lib/components/elements/ResultsDrawer";
import AuButton, { BUTTON_TYPE_PRIMARY } from "@au/core/lib/components/elements/AuButton";
import { JsonData } from "@au/core/lib/components/objects";

import Breadcrumbs from "../Breadcrumbs";
import SearchResult from "./SearchResult";
import { SimpleTable } from "../SimpleTable";
import FeedbackButton from "./LookupFeedbackButton";
import { TMC_LOOKUP_PATH, TMC_SIGNAL_PATH } from "../../constants";
import CellWithCopy, { UNAVAILABLE_ID } from "../CellWithCopy";

import tmcLookupStyles from "../../css/components/ftcp/tmc_lookup.module.scss";
import styles from "../../css/components/ftcp/ftcp_signal_information.module.scss";

export const NUMERIC_TYPE = "NUMERIC";
export const ENUM_TYPE = "SED";
export const RANGE_TYPE = "RANGE";
const NON_CAN_ENUM = "NON_CAN_ENUM";
const NON_CAN_NUMERIC = "NON_CAN_NUMERIC";
const ENUM_VALUE = "ENUM_VALUE";

const UNMAPPED_VALUE = "UNMAPPED";
const INDICATOR_LIGHT_URI = "aui:signal:au:well_known:indicator_light";

export default class FtcpSignalInformation extends React.Component {

  FtcpOracleService = new TMC.services.FtcpOracle({apiVersion: '1-alpha'});

  constructor(props) {
    super(props);

    this.state = {
      isDrawerOpen: false,
      signalConversionResult: undefined,
      signalConversionLoading: false
    };
  }

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const canSignal = params.get('canSignal');
    const version = params.get('version');

    this.FtcpOracleService.signals.getFtcpSignal({
      name: canSignal,
      ftcpVersion: version
    }).then(res => {
      if (res.data) {
        this.setState({signal: this.processFtcpSignal(res.data)});
      }
    }).catch(() => {
      this.setState({hasSignalError: true, event: {name: canSignal, version}});
    });
  }

  processFtcpSignal(signalData) {
    const {canSignalMappingInfo, nonCanSignalMappingInfo, ftcpSignalName} = signalData;
    const mappingInfo = canSignalMappingInfo ?? nonCanSignalMappingInfo;

    return {
      name: ftcpSignalName,
      description: canSignalMappingInfo?.canSignalDescription,
      data: this.processData(nonCanSignalMappingInfo, canSignalMappingInfo),
      tmcName: mappingInfo?.wellKnownSignalInfos?.[0]?.signalName,
      tmcUnit: mappingInfo?.wellKnownSignalInfos?.[0]?.units,
      tmcRangeMappings: canSignalMappingInfo?.rangeMappings,
      tmcEnumMappings: mappingInfo?.enumMappings ?? [],
      info: mappingInfo,
      uri: mappingInfo?.wellKnownSignalInfos?.[0]?.signalUri ?? UNAVAILABLE_ID,
    };
  }

  processData(nonCanSignalInfo, canSignalInfo) {
    if(nonCanSignalInfo) {
      if (nonCanSignalInfo.wellKnownSignalInfos[0]?.valueType === ENUM_VALUE) {
        return {...nonCanSignalInfo, dataType: NON_CAN_ENUM};
      }
      return {...nonCanSignalInfo, dataType: NON_CAN_NUMERIC, unit: nonCanSignalInfo.customFtcpSignalConfigInfo?.rawUnit};
    }

    const {gsdbInfo, vddInfo, rangeMappings} = canSignalInfo;
    const processedData = gsdbInfo || vddInfo;
    processedData.dataType = rangeMappings !== undefined ? RANGE_TYPE : processedData.dataType;
    return processedData;
  }

  getValuesTable() {
    switch (this.state.signal.data.dataType) {
      case RANGE_TYPE:
        return this.getRangeTable();
      case NUMERIC_TYPE:
        return this.getNumericTable();
      case ENUM_TYPE:
        return this.getEnumTable();
      case NON_CAN_ENUM:
        return this.getNonCanTable();
      case NON_CAN_NUMERIC:
        return this.getNumericTable();
      default:
        return <AutoIntl displayId="au.ftcpSignalInformation.unknownDataType" />;
    }
  }

  getNumericTable() {
    const {data} = this.state.signal;
    if (data.dataType === NON_CAN_NUMERIC) {
      const configInfo = data.customFtcpSignalConfigInfo;
      return <>
        <SimpleTable
          showHeader={false}
          headers={[
            {width: "2fr"},
            {width: "7fr"},
          ]}
          rows={["minValue", "maxValue", "valueOffset", "resolution"].map(field => [
            {displayId: "au.ftcpSignalInformation." + field}, {displayString: String(configInfo[field]), className: tmcLookupStyles.numeric_value}
          ])}
        />
      </>;
    }
    return <>
      <SimpleTable
        showHeader={false}
        headers={[
          {width: "2fr"},
          {width: "7fr"},
        ]}
        rows={["minValue", "maxValue", "valueOffset", "resolution"].map(field => [
          {displayId: "au.ftcpSignalInformation." + field}, {displayString: String(data[field]), className: tmcLookupStyles.numeric_value}
        ])}
      />
    </>;
  }

  getEnumTable() {
    const {tmcEnumMappings} = this.state.signal;
    return <SimpleTable
      headers={[
        {displayId: "au.ftcpSignalInformation.ftcpValue", width: "3fr"},
        {displayId: "au.ftcpSignalInformation.enum", className: styles.enum_value, width: "2fr"},
        {displayId: "au.ftcpSignalInformation.tmc", width: "6fr"},
      ]}
      rows={tmcEnumMappings.map(value => {
        return [
          {displayString: value.canValue},
          {displayString: value.intValue, className: styles.enum_value},
          {displayString: value.tmcValue, className: cn({[tmcLookupStyles.no_matching_value]: value.tmcValue === UNMAPPED_VALUE})},
        ];
      })}
    />;
  }

  getNonCanTable() {
    return <SimpleTable
      headers={[
        {displayId: "au.ftcpSignalInformation.ftcpValue", width: "1fr"},
        {displayId: "au.ftcpSignalInformation.tmc", width: "2fr"},
      ]}
      rows={this.state.signal.tmcEnumMappings.map((value) => {
        return [
          {displayString: value.canValue},
          {displayString: value.tmcValue},
        ];
      })}
    />;
  }

  getRangeTable() {
    const {tmcRangeMappings} = this.state.signal;

    return <SimpleTable
      headers={[
        {displayId: "au.ftcpSignalInformation.ftcpValue", width: "3fr"},
        {displayId: "au.ftcpSignalInformation.intValueHeader", className: tmcLookupStyles.enum_value},
        {displayId: "au.ftcpSignalInformation.tmcLowerBound", className: tmcLookupStyles.enum_value},
        {displayId: "au.ftcpSignalInformation.tmcUpperBound", className: tmcLookupStyles.enum_value}
      ]}
      rows={Object.entries(tmcRangeMappings).map(([idx]) => {
        const tmcMapping = tmcRangeMappings[idx];
        return [
          {displayString: tmcMapping?.canValue},
          {displayString: tmcMapping?.intValue, className: tmcLookupStyles.range_value},
          {displayString: tmcMapping?.tmcValue.lowerBound, className: tmcLookupStyles.range_value},
          {displayString: tmcMapping?.tmcValue.upperBound, className: tmcLookupStyles.range_value}
        ];
      })}
    />;
    }


  handleViewSignalClick = this.handleViewSignalClick.bind(this);
  handleViewSignalClick() {
    const params = new URLSearchParams(this.props.location.search);
    const name = params.get('canSignal');
    const ftcpVersion = params.get('version');

    this.setState({signalConversionResult: this.preProcessSignalConversion(), isDrawerOpen: true, signalConversionLoading: true});

    this.FtcpOracleService.signals.getFeedOutput({name, ftcpVersion})
      .then((resp) => {
        // The 2 second delay was a requirement for the story to add a loading spinner
        setTimeout(() => this.setState({
          signalConversionResult: this.processSignalConversion(resp.data),
          signalConversionLoading: false
        }), 2000);
      })
      .catch(() => this.setState({signalConversionLoading: false}));
  }

  preProcessSignalConversion() {
    const params = new URLSearchParams(this.props.location.search);
    const canSignal = params.get('canSignal');
    const version = params.get('version');
    const uri = this.state.signal?.uri ?? UNAVAILABLE_ID;

    return [
      {headerId: "au.resultsDrawer.uri", data: uri, children: <JsonData data={`URI: ${uri}`}/>},
      {headerId: "au.resultsDrawer.ftcpMessage", data: {name: canSignal, ftcpVersion: version}, hideCopyBtn: true}
    ];
  }

  processSignalConversion(signalConversion) {
    return [
      ...this.preProcessSignalConversion(),
      {headerId: "au.resultsDrawer.metrics", data: signalConversion.conversion.standaloneMetrics || signalConversion.conversion}
    ];
  }

  renderSearchResultText() {
    const { signal } = this.state;
    if (signal ) {
      const signalInfo = signal.info.wellKnownSignalInfos;
      if (signal.info?.wellKnownIndicators) {
        return ['au.ftcpSignalInformation.signalConvertedIndicator', 'INDICATOR_LIGHT', true];
      }
      else if (signalInfo) {
        if (signalInfo[0].signalType === "CONFIGURATION") {
          return ['au.ftcpSignalInformation.signalConvertedConfiguration', signalInfo[0].signalType, true];
        }
        else {
          return ['au.ftcpSignalInformation.signalConverted', null, true];
        }
      }
      else {
        return ['au.ftcpSignalInformation.signalFoundNotConverted', null, false];
      }
    }
  }

  render() {
    const params = new URLSearchParams(this.props.location.search);
    const canSignal = params.get('canSignal');
    const version = params.get('version');

    const {
      signalConversionResult,
      signalConversionLoading,
      isDrawerOpen,
    } = this.state;

    if(this.state?.hasSignalError) {
      return <div className={tmcLookupStyles.container}>
        <Breadcrumbs
          crumbs={[
            {key: "tmc-lookup", displayId: "au.tmcLookup.header", destination: `${TMC_LOOKUP_PATH}?canSignal=${canSignal}&version=${version}`, tag: "tmc-lookup"},
            {key: "ftcp-signal-information", displayId: "au.ftcpSignalInformation.header", tag: "ftcp-signal-information"},
          ]}
        />
        <SearchResult>
          <AutoIntl key='au.ftcpSignalInformation.signalError' className={tmcLookupStyles.searchResultText} displayId='au.ftcpSignalInformation.signalError' values={this.state.event} />
        </SearchResult>
      </div>;
    }
    if (!this.state?.signal) {
      return null;
    }

    const {name, tmcName, tmcUnit, description, data, uri, info} = this.state.signal;
    const indicator = Boolean(info.wellKnownIndicators);

    const [searchResultText, signalType, converted] = this.renderSearchResultText();

    const tmcEventNameOrLink = converted? (<Link to={`${TMC_SIGNAL_PATH}?tmcSignal=${tmcName}`} className={styles.link}>{tmcName}</Link>) : tmcName;

    return (
      <div className={styles.container}>
        <div className={cn(styles.signal_container, {[styles.isDrawerOpen]: isDrawerOpen})}>
          <Breadcrumbs
            crumbs={[
              {key: "tmc-lookup", displayId: "au.tmcLookup.header", destination: `${TMC_LOOKUP_PATH}?canSignal=${canSignal}&version=${version}`, tag: "tmc-lookup"},
              {key: "ftcp-signal-information", displayId: "au.ftcpSignalInformation.header", tag: "ftcp-signal-information"},
            ]}
          />
          <FeedbackButton />
          <SearchResult>
            <AutoIntl className={tmcLookupStyles.searchResultText} displayId={searchResultText} values={{signalName: <b>{name}</b>, tmcSignalName: <b>{indicator ? info.wellKnownIndicators.indicatorName : tmcEventNameOrLink}</b>, type: <b>{signalType}</b>}} />
            <div className={tmcLookupStyles.result_prop_container}>
              {description && 
                <div className={tmcLookupStyles.result_prop}>
                  <AutoIntl className={tmcLookupStyles.result_prop_label} displayId='au.ftcpSignalInformation.signalDescription'/>
                  <AutoIntl className={tmcLookupStyles.result_prop_text} displayString={description}/>
                </div>
              }
              <div className={tmcLookupStyles.result_prop}>
                <AutoIntl className={tmcLookupStyles.result_prop_label} displayId='au.tmcLookup.uri'/>
                <CellWithCopy className={tmcLookupStyles.result_prop_text} uri={indicator ? INDICATOR_LIGHT_URI : uri}/>
              </div>
              <div className={tmcLookupStyles.result_prop}>
                <AutoIntl className={tmcLookupStyles.result_prop_label} displayId='au.ftcpSignalInformation.version'/>
                <AutoIntl className={tmcLookupStyles.result_prop_text} displayString={info.ftcpVersion}/>
              </div>
              {!indicator &&
                <div className={tmcLookupStyles.result_prop}>
                  <AutoIntl className={tmcLookupStyles.result_prop_label} displayId='au.ftcp.dataType'/>
                  <AutoIntl className={tmcLookupStyles.result_prop_text} displayId={`au.ftcp.dataType.${data.dataType}`}/>
                </div>
              }
              {(data.dataType === NUMERIC_TYPE || data.dataType === NON_CAN_NUMERIC) && 
                <div className={tmcLookupStyles.result_prop}>
                  <AutoIntl displayId="au.ftcpSignalInformation.unit" className={tmcLookupStyles.result_prop_label}/>
                  <div className={tmcLookupStyles.result_prop_text}>
                    <AutoIntl displayString={data.unit} />
                    {tmcUnit && <AutoIntl displayId="au.ftcpSignalInformation.tmcUnit" values={{tmcUnit}} className={tmcLookupStyles.tmc_unit}/>}
                  </div>
                </div>
              }
            </div>
          </SearchResult>
          {!indicator &&
            <>
              <div className={styles.header}>
                <AutoIntl displayId={`au.ftcpSignalInformation.tableHeader.${data.dataType}`} tag="h6"/>
                {!data?.signalNotMappedReason && <AuButton displayId="au.ftcpLookup.simulate" type={BUTTON_TYPE_PRIMARY} onClick={this.handleViewSignalClick}/>}
              </div>
              {this.getValuesTable()}
            </>
          }
        </div>
        {!indicator &&
          <ResultsDrawer
            isOpen={isDrawerOpen}
            onClose={() => this.setState({isDrawerOpen: false})}
            results={signalConversionResult}
            loading={signalConversionLoading}
          />
        }
      </div>
    );
  }
}
