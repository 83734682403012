import React, { PureComponent } from 'react';
import browserHistory from '../history';
import cn from 'classnames';
import * as T from 'prop-types';

import { zoomToTop } from '../utils/dom.js';

import styles from '../css/components/toc_bar.module.scss';

export default class BarItem extends PureComponent {
  static propTypes = {
    defaultExpanded: T.bool,
    url: T.string.isRequired,
    name: T.string.isRequired,
    level: T.number.isRequired,
    children: T.any
  }

  static defaultProps = {
    defaultExpanded: false
  }

  // will only expand if user has not clicked
  static getDerivedStateFromProps({ defaultExpanded }, state) {
    const { userActivated, expanded } = state;
    if (!userActivated && defaultExpanded !== expanded && defaultExpanded) {
      return {
        expanded: defaultExpanded
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      expanded: props.defaultExpanded,
      userActivated: false
    };
  }

  onLinkClick = this.onLinkClick.bind(this)
  onLinkClick() {
    zoomToTop();
    let topFile = { ...this.props };
    while (topFile.isFolder) {
      try {
        topFile = topFile.children[0].props;
      } catch (e) {
        console.error('Folder does not have any files'); // eslint-disable-line no-console
        break;
      }
    }
    this.setState(prevState => ({ expanded: !prevState.expanded, userActivated: true }));
    browserHistory.push(topFile.url);
  }

  render() {
    const { name, level, url, isFolder } = this.props;
    const { expanded } = this.state;

    const levelClass = styles['lvl-' + level];
    const expandedClass = { [styles.expanded]: expanded };

    const viewing = url === window.location.pathname;

    return (
      <div className={styles.container}>
        <div className={cn(styles.head, levelClass, { [styles.viewing]: viewing })} onClick={this.onLinkClick} >
          <div data-testid={`TocBar-icon-${name}`} className={cn(expanded ? styles['icon_container_expanded'] : styles['icon_container'], levelClass)}>
            <div className={cn(styles.icon, expandedClass, { [styles.folder]: isFolder, [styles.doc]: !isFolder})} />
          </div>
          <div data-testid={`TocBar-name-${name}`} className={cn(styles.name, styles.link, levelClass)}>{name}</div>
        </div>
        <div className={cn(styles.children, expandedClass)}>
          {this.props.children}
        </div>
      </div>
    );
  }
}
