import React from 'react';
import * as T from 'prop-types';
import scrollMonitor from 'scrollmonitor';

import AuAnalytics from '@au/core/lib/utils/AuAnalytics';
import AuComponent from '@au/core/lib/components/elements/AuComponent';
import { injectScreenWidth } from '@au/core/lib/components/elements/ScreenWidthProvider';

import TocLink from './TocLink';
import { MARKDOWN_INJECTED, MARKDOWN_CLEARED, DOC_DATA_ID, NOOP } from '../constants';
import { keyedParseMarkdownSelector } from '../utils/selectors';
import { loaded } from '../utils/dom';

function getViewableAnchorLinks(anchorLinks) {
  const viewableAnchorLinks = new Map();
  for (let [name, linkMeta] of Array.from(anchorLinks)) {
    if (linkMeta.level === 1) {
      //We are not displaying H1 elements in the TOC. The expectation is that
      //there is only a single H1 tag, and that is the title of the doc. This
      //change addresses some printing issues, as well duplicate TOC value.
      continue;
    }
    viewableAnchorLinks.set(name, linkMeta);
  }
  return viewableAnchorLinks;
}

class TocEntry extends AuComponent {
  static propTypes = {
    entry: T.object.isRequired,
    url: T.string.isRequired,
    active: T.bool.isRequired
  }

  static defaultProps = {
    onLinkClick: NOOP
  }

  scrollMonitorDisarmed = false;
  state = { viewingAnchor: '', docMounted: false };
  anchorsViewable = new Map();
  elementWatchers = new Set();

  updateViewingAnchor() {
    if (!this._mounted) return;
    // ordered by element positions on page; returns first true
    let viewingAnchor;
    for (let [name, viewing] of this.anchorsViewable) {
      if (viewing) {
        viewingAnchor = name;
        break;
      }
    }
    if (!this.scrollMonitorDisarmed && viewingAnchor && this.state.viewingAnchor !== viewingAnchor) {
      this.setState({ viewingAnchor });
    }
  }

  enterViewport(name) {
    return () => {
      this.anchorsViewable.set(name, true);
      this.updateViewingAnchor();
    };
  }

  exitViewport(name) {
    return () => {
      this.anchorsViewable.set(name, false);
      this.updateViewingAnchor();
    };
  }

  markdownDocInjected = this.markdownDocInjected.bind(this);
  markdownDocInjected() {
    loaded.then(() => this._mounted && this.setState({ docMounted: true }, () => this.monitorAnchors()));
  }

  markdownDocCleared = this.markdownDocCleared.bind(this);
  markdownDocCleared() {
    if (this._mounted) {
      this.setState({ docMounted: false });
    }
  }

  componentDidMount() {
    this._mounted = true;
    this.monitorAnchors();
    window.addEventListener(MARKDOWN_INJECTED, this.markdownDocInjected, false);
    window.addEventListener(MARKDOWN_CLEARED, this.markdownDocCleared, false);
  }

  componentWillUnmount() {
    for (let elementWatcher of this.elementWatchers.values()) {
      elementWatcher.destroy();
    }
    window.removeEventListener(MARKDOWN_INJECTED, this.markdownDocInjected);
    window.removeEventListener(MARKDOWN_CLEARED, this.markdownDocCleared);
    this._mounted = false;
  }

  monitorAnchors(entry=this.props.entry) {
    const { node: markdownNode, anchorLinks } = keyedParseMarkdownSelector.run(entry);

    const { screenWidth } = this.props;
    if (!markdownNode || !markdownNode.parentNode || !anchorLinks || !this.state.docMounted || !this.props.active) {
      return;
    }

    this.anchorsViewable.clear();
    this.elementWatchers.clear();

    const viewableAnchorLinks = getViewableAnchorLinks(anchorLinks);

    let scrollContainer;
    if (screenWidth.startsWith('mobile')) {
      /* this is so we can scroll the header (hat) with the content (so it doesn't stick) */
      scrollContainer = markdownNode.closest(`*[data-id=${DOC_DATA_ID}]`);
    } else {
      scrollContainer = markdownNode.closest('*[data-tcl=MarkdownRenderer-content]').parentNode;
    }
    if (!scrollContainer) {
      AuAnalytics.trackException('Unable to get scroll container');
      return;
    }

    const containerMonitor = scrollMonitor.createContainer(scrollContainer);

    for (let name of viewableAnchorLinks.keys()) {
      // if using a scroll container an entry is needed in anchorsViewable since
      // the enter/exit functions will be called immediately after being added
      // to the watcher
      this.anchorsViewable.set(name, false);

      const anchorEl = markdownNode.querySelector(`[name="${name}"]`);
      const elementWatcher = containerMonitor.create(anchorEl);
      elementWatcher.enterViewport(this.enterViewport(name));
      elementWatcher.exitViewport(this.exitViewport(name));
      this.elementWatchers.add(elementWatcher);
    }
  }

  highlightLink = this.highlightLink.bind(this);
  highlightLink(name) {
    this.scrollMonitorDisarmed = true;
    setTimeout(() => this.scrollMonitorDisarmed = false, 150);
    this.setState({ viewingAnchor: name });
    this.props.onLinkClick();
  }

  render() {
    const { entry, url, active, linkLevelClassName } = this.props;
    const { docMounted } = this.state;
    const { node, anchorLinks } = keyedParseMarkdownSelector.run(entry);

    if (!anchorLinks || !docMounted) {
      return false;
    }

    const viewableAnchorLinks = getViewableAnchorLinks(anchorLinks);

    // highlight the first link if no other link is highlighted
    let forceFirstViewing = false;
    const parent = node.parentNode;
    if (parent && parent.parentNode) {
      const top = parent.parentNode.scrollTop;
      if (top < 1) {
        forceFirstViewing = true;
        for (let viewing of this.anchorsViewable.values()) {
          if (viewing) {
            forceFirstViewing = false;
            break;
          }
        }
      }
    }

    const links = [];
    for (let [name, linkMeta] of viewableAnchorLinks) {
      links.push(
        <TocLink onClick={this.highlightLink} key={name} name={name} level={linkMeta.level} viewing={active && (name === this.state.viewingAnchor || forceFirstViewing)} url={url}>
          <div className={linkLevelClassName}>{linkMeta.headerContent}</div>
        </TocLink>
      );
      forceFirstViewing = false;
    }
    return links;
  }
}

export default injectScreenWidth(TocEntry);
