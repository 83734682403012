import React from "react";
import * as T from "prop-types";

import AuButton from "@au/core/lib/components/elements/AuButton";
import AutoIntl from "@au/core/lib/components/elements/AutoIntl";

import styles from "../css/components/simple_table.module.scss";
import cn from "classnames";

export class SimpleTable extends React.Component {
  static propTypes = {
    rows: T.arrayOf(T.arrayOf(T.shape(AutoIntl.propTypes))),
    headers: T.arrayOf(T.shape({...AutoIntl.propTypes, width: T.string})),
    showHeader: T.bool
  };

  static defaultProps = {
    showHeader: true
  };

  render() {
    const {showHeader} = this.props;
    const columnWidths = this.getColumnWidths();

    return (
      <div className={styles.container} style={{gridTemplateColumns: columnWidths}}>
        {showHeader && this.getHeaders()}
        {this.getBody()}
      </div>
    );
  }

  getColumnWidths() {
    return this.props.headers.map(h => h.width ?? "1fr").join(" ");
  }

  getHeaders() {
    return this.props.headers.map(({width, ...headerProps}) => ( // eslint-disable-line no-unused-vars
      <div key={headerProps.displayId + headerProps.displayString} className={styles.header_cell}>
        <AutoIntl {...headerProps}/>
      </div>
    ));
  }

  isCellEmpty(cell) {
    return cell.displayString === undefined && cell.displayId === undefined && cell.tag !== "button";
  }

  getBody() {
    return this.props.rows.map((row, rowIndex) =>
        this.props.headers.map((_, columnIndex) => {
          const cell = row[columnIndex];
          const Component = cell.tag === "button" ? AuButton : AutoIntl;
          const hasRightBorder = columnIndex < this.props.headers.length - 1;
          return (
            <div
              key={`${cell.displayId}:${cell.displayString}:${rowIndex}:${columnIndex}`}
              className={cn(styles.body_cell, {[styles.right_border]: hasRightBorder, [styles.empty_cell]: this.isCellEmpty(cell)})}
            >
              <Component {...cell} />
            </div>
          );
        })
    );
  }
}
