import slug from './slug';

const multipleSlashes = /\/\/+/gi;

export function matchSegment(segment, entries) {
  for (let [key, entry] of entries.entrySeq()) {
    if (slug(key) === segment) {
      return entry;
    }
  }
}

export function isSubUrl(url, subUrl) {
  const urlSegs = url.split('/');
  const subUrlSegs = subUrl.split('/');

  if (subUrlSegs.length > urlSegs.length) return false;

  for (let idx=0; idx<subUrlSegs.length; ++idx) {
    if (subUrlSegs[idx] !== urlSegs[idx]) return false;
  }
  return true;
}

export function cleanUrl(url) {
  return url.replace(multipleSlashes, '/');
}
