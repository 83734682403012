import locales from '../locales';
import { FALLBACK_LANG } from '../constants';

const locale = (process.env.REACT_APP_LANGUAGE || (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage).toLowerCase();
const languageWithoutRegionCode = locale.toLowerCase().split(/[_-]+/)[0];
let language;
if (locales[languageWithoutRegionCode]) {
  language = languageWithoutRegionCode;
} else {
  language = FALLBACK_LANG;
}

if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/dist/locale-data/en');
  require('@formatjs/intl-pluralrules/dist/locale-data/zh');
}

const messages = locales[language];

export { messages, language };
